import React, { useState } from 'react';
import CommonTable from '../../../components/CommonTable';

const ProjectSummaryTable = ({ data, loading }) => {
  const initialFilter = {
    skip: 0,
    limit: 5,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [filter, setFilter] = useState(initialFilter);

  const handleTableChange = (pagination, tableFilter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setFilter({ ...filter, skip: skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
  };

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: (a, b) => a?.projectName?.localeCompare(b?.projectName),
      sortOrder: sortedInfo?.columnKey === 'projectName' && sortedInfo?.order
    },
    {
      title: 'Total Inspection',
      dataIndex: 'totalCount',
      key: 'totalCount',
      sorter: (a, b) => a?.totalCount - b?.totalCount,
      sortOrder: sortedInfo?.columnKey === 'totalCount' && sortedInfo?.order
    },
    {
      title: 'Failed Inspection',
      dataIndex: 'failCount',
      key: 'failCount',
      sorter: (a, b) => a?.failCount - b?.failCount,
      sortOrder: sortedInfo?.columnKey === 'failCount' && sortedInfo?.order,
      render: (failCount, { totalCount }) =>
        `${failCount} (${parseInt((failCount / totalCount) * 100, 10) || 0}%)`
    }
  ];
  return (
    <>
      <CommonTable
        columns={columns}
        data={data || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
        loading={loading}
      />
    </>
  );
};

export default ProjectSummaryTable;
