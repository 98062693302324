import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { filter, map, trim } from 'lodash';
import React, { useState } from 'react';
import { AddButton, DeleteButton } from '../../../../../../assets/svg';
import {
  CREATE_PROJECT_NOMENCLATURE_LEVEL,
  UPDATE_PROJECT_NOMENCLATURE_LEVEL
} from '../../../../graphql/Mutation';

const AddEditLevelsModal = ({
  showModal,
  setShowModal,
  projectId,
  handleRefetch,
  selectedTree,
  setSelectedTree,
  setExpandedKeys
}) => {
  const [form] = Form.useForm();
  const [showMultipleLevelModal, setShowMultipleLevelModal] = useState(false);
  const [errorData, setErrorData] = useState([]);
  const [multiLineData, setMultiLineData] = useState([]);
  const [formData, setFormData] = useState([]);
  const handleCancel = () => {
    setSelectedTree();
    setShowModal(false);
    form.resetFields();
  };

  const [createProjectNomenclatureLevel, { loading }] = useMutation(
    CREATE_PROJECT_NOMENCLATURE_LEVEL,
    {
      onCompleted() {
        handleCancel();
        handleRefetch();
        setExpandedKeys((prev) => [...prev, `${selectedTree?.id}`]);
      },
      onError() {}
    }
  );
  const [
    updateProjectNomenclatureLevel,
    { loading: updateLoading }
  ] = useMutation(UPDATE_PROJECT_NOMENCLATURE_LEVEL, {
    onCompleted() {
      handleCancel();
      handleRefetch();
    },
    onError() {}
  });

  const submitForm = async (values) => {
    if (selectedTree?.isEdit) {
      updateProjectNomenclatureLevel({
        variables: {
          id: selectedTree?.id,
          ...values?.levels?.[0]
        }
      });
    } else {
      createProjectNomenclatureLevel({
        variables: {
          data: {
            projectId,
            parentLevelId: selectedTree?.id,
            ...values
          }
        }
      });
    }
  };

  return (
    <div id="add-edit-levels">
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        footer={null}
        closable={false}
        width={420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('add-edit-levels')
        }
      >
        <div>
          <h2>{selectedTree?.isEdit ? `Editing Level` : `Add Level`}</h2>
          {selectedTree?.isEdit &&
            Number(selectedTree?.totalInspectionCount) > 0 && (
              <p>
                {Number(selectedTree?.totalInspectionCount)} EQCs linked to this
                current level will get rename.
              </p>
            )}
          <p>
            {!selectedTree?.isEdit &&
              `Enter new levels into “${selectedTree?.name}”`}
          </p>
          <Form
            form={form}
            layout="vertical"
            onFinish={submitForm}
            onChange={() => {
              form.validateFields();
              setFormData(form.getFieldValue(['levels']));
            }}
            onFieldsChange={() => {
              setErrorData(
                filter(
                  form.getFieldsError(),
                  (record) => record?.errors?.length > 0
                )
              );
            }}
            initialValues={{
              levels: [
                {
                  name: selectedTree?.isEdit ? selectedTree?.name : ''
                }
              ]
            }}
          >
            <Form.List name="levels">
              {(fields, { add, remove }) => {
                return (
                  <>
                    <Row gutter={[15, 15]} className="width-percent-100">
                      <div className="form-inputs thin-scrollbar">
                        {fields.map((field, index) => (
                          <Col span={24} key={field.key}>
                            <Row gutter={[30]} align="middle">
                              <Col
                                xs={fields?.length > 1 ? 18 : 24}
                                sm={24}
                                flex="auto"
                                className="d-flex align-center"
                              >
                                <div className="mb-20 text-secondary">
                                  L
                                  {selectedTree?.isEdit
                                    ? selectedTree?.level
                                    : selectedTree?.level + 1}{' '}
                                  -
                                </div>
                                <div className="d-flex flex-vertical fill-width">
                                  <Form.Item
                                    {...field}
                                    name={[field.name, 'name']}
                                    fieldKey={[field.fieldKey, 'name']}
                                    rules={[
                                      () => ({
                                        validator() {
                                          const isNamePresent = form.getFieldValue(
                                            ['levels', field?.name, 'name']
                                          );

                                          if (!isNamePresent) {
                                            return Promise.reject(
                                              new Error('Required')
                                            );
                                          }
                                          if (isNamePresent?.length > 40) {
                                            return Promise.reject(
                                              new Error(
                                                'Level cannot be more than 40 characters'
                                              )
                                            );
                                          }
                                          if (
                                            filter(
                                              [
                                                ...map(formData, 'name'),
                                                ...(!selectedTree?.isEdit
                                                  ? map(
                                                      selectedTree?.data,
                                                      'name'
                                                    )
                                                  : [])
                                              ],
                                              (name) =>
                                                name?.toLowerCase() ===
                                                formData?.[
                                                  index
                                                ]?.name?.toLowerCase()
                                            )?.length > 1
                                          ) {
                                            return Promise.reject(
                                              new Error('Duplicate entry')
                                            );
                                          }
                                          return Promise.resolve();
                                        }
                                      })
                                    ]}
                                  >
                                    <Input autoFocus placeholder="Enter Name" />
                                  </Form.Item>
                                </div>
                              </Col>
                              {fields?.length > 1 && (
                                <Col className="d-flex align-center remove-col">
                                  <Form.Item className="mt-5">
                                    <DeleteButton
                                      className="pointer"
                                      onClick={() => {
                                        remove(field?.name);
                                        const data = form.getFieldValue([
                                          'levels'
                                        ]);
                                        setFormData(data);
                                        setErrorData(
                                          filter(
                                            form.getFieldsError(),
                                            (record) =>
                                              record?.errors?.length > 0
                                          )
                                        );
                                      }}
                                    />
                                  </Form.Item>
                                </Col>
                              )}
                            </Row>
                          </Col>
                        ))}
                      </div>
                      {!selectedTree?.isEdit && (
                        <Row>
                          <Col>
                            <Button
                              type="primary"
                              shape="round"
                              htmlType="submit"
                              onClick={() => {
                                if (
                                  trim(
                                    form.getFieldValue([
                                      'levels',
                                      fields?.length - 1,
                                      'name'
                                    ])
                                  )
                                ) {
                                  add();
                                }
                              }}
                              block
                            >
                              <AddButton />
                            </Button>
                          </Col>
                          <Col>
                            <Button
                              type="primary"
                              shape="round"
                              htmlType="submit"
                              icon={<AddButton className="mr-5" />}
                              className="ml-10 d-flex align-center"
                              onClick={(e) => {
                                e.preventDefault();
                                setShowMultipleLevelModal(true);
                              }}
                              block
                            >
                              Multiple
                            </Button>
                          </Col>
                        </Row>
                      )}
                    </Row>
                  </>
                );
              }}
            </Form.List>
            {errorData?.length > 0 && (
              <div className="text-danger mt-10">
                Please correct the errors to proceed
              </div>
            )}
            <div className="form-buttons mt-10">
              <Button
                shape="round"
                className="cancel-button"
                onClick={handleCancel}
                disabled={loading || updateLoading}
              >
                Cancel
              </Button>
              <Button
                shape="round"
                type="primary"
                className="save-button"
                htmlType="submit"
                disabled={loading || updateLoading}
                loading={loading || updateLoading}
                onClick={() => {
                  setErrorData(
                    filter(
                      form.getFieldsError(),
                      (data) => data?.errors?.length > 0
                    )
                  );
                }}
              >
                Save
              </Button>
            </div>
          </Form>
        </div>
      </Modal>
      {showMultipleLevelModal && (
        <Modal
          maskClosable={false}
          centered
          visible={showMultipleLevelModal}
          footer={null}
          closable={false}
          width={420}
          getContainer={() =>
            // eslint-disable-next-line no-undef
            document.getElementById('add-edit-levels')
          }
        >
          <h2>Add Multiple Entries</h2>
          <TextArea
            rows="10"
            cols="50"
            allowClear
            placeholder={
              'Please copy all the entries from the desired column from the spreadsheet then paste here \n\nOr \n\nPaste all the entries in separate line here'
            }
            onChange={(event) => {
              const rows = event?.target?.value
                ?.trim()
                ?.split('\n')
                ?.map((data) => data?.trim())
                ?.filter((data) => data.length > 0);
              setMultiLineData(rows);
            }}
          />
          <div className="form-buttons mt-10">
            <Button
              shape="round"
              className="cancel-button"
              onClick={() => {
                setShowMultipleLevelModal(false);
                setShowModal(true);
              }}
              disabled={loading || updateLoading}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
              onClick={() => {
                setShowMultipleLevelModal(false);
                if (multiLineData?.length > 0) {
                  const data = map(multiLineData, (levelName) => {
                    return { name: levelName };
                  });
                  const prev = filter(
                    form.getFieldValue('levels'),
                    (record) => record?.name
                  );
                  form.setFieldsValue({
                    levels: [...prev, ...data]
                  });
                  setFormData([...prev, ...data]);
                  setMultiLineData([]);
                }
              }}
              disabled={loading || updateLoading}
              loading={loading || updateLoading}
            >
              Okay
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default AddEditLevelsModal;
