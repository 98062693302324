import { useQuery } from '@apollo/client';
import { get } from 'lodash';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import LoaderComponent from '../../components/LoaderComponent';
import { REFRESH_TOKEN } from './graphql/Queries';

const RefreshToken = () => {
  const { initializeAuth, getRefreshToken, dispatch } = useContext(AppContext);
  const history = useHistory();
  const pathname = history?.location?.state?.pathname;
  const refreshToken = getRefreshToken();
  function successCallback(accessToken, authRefreshToken) {
    initializeAuth(accessToken, authRefreshToken);
  }

  const { loading } = useQuery(REFRESH_TOKEN, {
    fetchPolicy: 'network-only',
    variables: {
      data: { refreshToken: refreshToken }
    },
    onCompleted(response) {
      const accessToken = get(response, 'refreshToken.token');
      const authRefreshToken = get(response, 'refreshToken.refreshToken');
      successCallback(accessToken, authRefreshToken);
      if (pathname) {
        history.push(pathname);
      } else {
        history.goBack();
      }
    },
    onError() {
      dispatch({ type: 'LOGOUT' });
    }
  });

  if (loading) return <LoaderComponent />;

  return null;
};

export default RefreshToken;
