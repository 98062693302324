import { Col, Modal, Row } from 'antd';
import React, { useState } from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../common/constants';
import AgenciesContent from './AgenciesContent';
import AgenciesContact from './contacts/AgenciesContacts';

const Agencies = () => {
  const [agencyId, setAgencyId] = useState(null);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [modalVisible, setModalVisible] = useState(false);
  const [title, setTitle] = useState();
  return (
    <div className="global-agency-table">
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Agencies</div>
        </>
      )}
      <Row
        className="global-agency-table-wrapper"
        gutter={isDesktopViewport ? 10 : [16, 16]}
      >
        <Col span={isDesktopViewport ? 10 : 24}>
          <AgenciesContent
            setAgencyId={setAgencyId}
            agencyId={agencyId}
            setModalVisible={setModalVisible}
            setTitle={setTitle}
          />
        </Col>
        {isDesktopViewport ? (
          <Col span={14}>
            <AgenciesContact agencyId={agencyId} />
          </Col>
        ) : (
          <>
            <div id="agency-contact-modal" />
            <Modal
              maskClosable={false}
              getContainer={() =>
                // eslint-disable-next-line no-undef
                document.getElementById('agency-contact-modal')
              }
              width="100vw"
              title={title}
              visible={modalVisible}
              footer={null}
              destroyOnClose
              onCancel={() => setModalVisible(false)}
            >
              <AgenciesContact agencyId={agencyId} />
            </Modal>
          </>
        )}
      </Row>
    </div>
  );
};

export default Agencies;
