import { Button, Modal } from 'antd';
import { map } from 'lodash';
import React from 'react';

const AudioPlayerModal = ({ showModal, setShowModal, audios }) => {
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="import-modal"
      footer={null}
      closable={false}
      onCancel={() => setShowModal(false)}
      destroyOnClose
    >
      <h2 className="mb-25">Audio Player</h2>
      {map(audios, (audio, index) => (
        <div key={`audio-${index}`} className="d-flex align-center mt-25">
          <div className="d-flex align-center">
            <span>Audio</span>
            <span className="ml-5"> {index + 1}</span>
          </div>
          {/*  eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio controls name="media" className="audio-player ml-10">
            <source src={audio} type="audio/mp4" />
          </audio>
        </div>
      ))}
      <div className="form-buttons mt-25">
        <Button
          shape="round"
          className="cancel-button"
          onClick={() => setShowModal(false)}
        >
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default AudioPlayerModal;
