import { useQuery } from '@apollo/client';
import { Avatar, Layout, Menu } from 'antd';
import * as eva from 'eva-icons';
import Gleap from 'gleap';
import { filter } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import alternateLogo from '../../../assets/images/alternateLogo.png';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  MODULES,
  ROUTES,
  SYSTEM_ROLES
} from '../../../common/constants';
import CanPerform from '../../../components/CanPerform';
import HasAccess from '../../../components/HasAccess';
import { GET_USER_EQC_APPROVAL_LOGS_COUNT } from '../../../modules/todo/graphql/Queries';
import { GET_PROFILE } from '../../../modules/users/graphql/Queries';
import UserProfile from './UserProfile';

const { Header } = Layout;
const initialTodoFilter = {
  skip: 0,
  limit: 10,
  sortBy: { field: 'updatedAt', order: 'DESC' },
  isRfi: false
};
const AppHeader = ({ location: { pathname } }) => {
  const {
    state: { currentRole }
  } = useContext(AppContext);
  const { data } = useQuery(GET_USER_EQC_APPROVAL_LOGS_COUNT, {
    variables: { filter: initialTodoFilter },
    fetchPolicy: 'network-only'
  });
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { data: userData } = useQuery(GET_PROFILE, {
    onCompleted(res) {
      Gleap.identify(res?.getLoggedInUser?.id, {
        name: res?.getLoggedInUser?.name,
        email: res?.getLoggedInUser?.email,
        phone: res?.getLoggedInUser?.phoneNo
      });
    }
  });
  const isProjectAdmin = !!filter(
    userData?.getLoggedInUser?.projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.PROJECT_ADMIN
  ).length;
  const isInspector = !!filter(
    userData?.getLoggedInUser?.projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.INSPECTOR
  ).length;
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });

  // eslint-disable-next-line no-undef
  window.onbeforeunload = closeIt;

  function closeIt() {
    Gleap.clearIdentity();
  }
  return (
    <Header>
      <>
        <Link to={ROUTES.MAIN}>
          <div className="logo">
            <img
              src={
                userData?.getLoggedInUser?.tenantUser?.tenant?.logo ||
                alternateLogo
              }
              alt="logo"
              className="header-logo"
            />
          </div>
        </Link>
        <div className="gx-search-bar gx-d-none gx-d-lg-block gx-lt-icon-search-bar-lg">
          <div className="header d-flex align-center justify-center">
            <Menu
              theme="lite"
              mode="horizontal"
              selectedKeys={[`/${pathname.split('/')[1]}`]}
              defaultSelectedKeys={[
                hasInspectionAccess ? ROUTES.MAIN : ROUTES.PROJECTS
              ]}
            >
              {hasInspectionAccess &&
                CanPerform({
                  action: ALLOWED_ACTION_KEYS.VIEW_DASHBOARD_PAGE
                }) && (
                  <Menu.Item key={ROUTES.MAIN} className="d-flex align-center">
                    <NavLink to={ROUTES.MAIN}>{MODULES.DASHBOARD}</NavLink>
                  </Menu.Item>
                )}
              {CanPerform({
                action: ALLOWED_ACTION_KEYS.VIEW_PROJECTS_PAGE
              }) && (
                <Menu.Item
                  key={ROUTES.PROJECTS}
                  className="d-flex align-center"
                >
                  <NavLink to={ROUTES.PROJECTS}>{MODULES.PROJECTS}</NavLink>
                </Menu.Item>
              )}
              {CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_SETUP_PAGE }) && (
                <>
                  <Menu.Item key={ROUTES.SETUP}>
                    <NavLink
                      to={`${ROUTES.SETUP}${
                        currentRole === SYSTEM_ROLES.CHECKLIST_MAKER
                          ? ROUTES.CHECKLISTS
                          : ROUTES.USERS
                      }`}
                    >
                      {MODULES.SETUP}
                    </NavLink>
                  </Menu.Item>
                </>
              )}

              {CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_LOGS_PAGE }) && (
                <Menu.Item key={ROUTES.LOGS} className="d-flex align-center">
                  <NavLink to={ROUTES.LOGS}>{MODULES.LOGS}</NavLink>
                </Menu.Item>
              )}
              {hasInspectionAccess &&
                (CanPerform({
                  action: ALLOWED_ACTION_KEYS.VIEW_REPORTS_PAGE
                }) ||
                  isProjectAdmin) && (
                  <Menu.Item
                    key={ROUTES.REPORTS}
                    className="d-flex align-center"
                  >
                    <NavLink to={ROUTES.REPORTS}>{MODULES.REPORTS}</NavLink>
                  </Menu.Item>
                )}
              {hasInspectionAccess &&
                (CanPerform({
                  action: ALLOWED_ACTION_KEYS.VIEW_TODO_PAGE
                }) ||
                  isProjectAdmin ||
                  isInspector) && (
                  <Menu.Item key={ROUTES.TODO} className="d-flex align-center">
                    <NavLink to={ROUTES.TODO}>
                      {MODULES.TODO}
                      {data?.getUserEqcApprovalLogs?.total > 0 && (
                        <Avatar className="todo-count" size={25}>
                          {data?.getUserEqcApprovalLogs?.total}
                        </Avatar>
                      )}
                    </NavLink>
                  </Menu.Item>
                )}
            </Menu>
          </div>
        </div>
        <div className="header-notification">
          <UserProfile userData={userData} />
        </div>
      </>
    </Header>
  );
};
export default withRouter(AppHeader);
