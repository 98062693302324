import { ProjectOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Button, Descriptions, Popover, Tooltip } from 'antd';
import clsx from 'clsx';
import * as eva from 'eva-icons';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import {
  AddButton,
  DeleteButton,
  EditButton,
  Export,
  Import
} from '../../assets/svg';
import {
  BREAKPOINTS,
  CSV_SAMPLE_FILES,
  DEFAULTDATETIMEFORMAT,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  ROUTES
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { titleCase } from '../../common/utils';
import CommonImportModal from '../../components/CommonImportModal';
import CommonTable from '../../components/CommonTable';
import EllipsisText from '../../components/EllipsisText';
import Portal from '../../components/Portal';
import SearchComponent from '../../components/SearchComponent';
import { GET_UNASSIGN_CONTACT_LIST_COUNT } from '../projects/graphql/Queries';
import AddAgencyModal from './AddAgencyModal';
import DeleteAgencyModal from './DeleteAgencyModal';
import { DELETE_AGENCY, IMPORT_AGENCY_CSV } from './graphql/Mutation';
import { AGENCY_LIST } from './graphql/Queries';

const AgenciesTable = ({
  setAgencyId,
  agencyId,
  setModalVisible,
  setTitle: setModalTitle
}) => {
  const { getToken, getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [sortedInfo, setSortedInfo] = useState({});
  const [agencyData, setAgencyData] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const [showImportModal, setShowImportModal] = useState(false);
  const token = getToken();
  const history = useHistory();
  const url = `${process.env.REACT_APP_REPORT_SERVER_URL}/api/v1/export-csv/agencies?access_token=Bearer ${token}`;
  const sampleFileUrl = CSV_SAMPLE_FILES.AGENCY_CSV;
  const list = [
    'Agency Name',
    'Agency Type',
    'Contact Name',
    'Unique Contact Email',
    'Unique Contact Phone'
  ];
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const initialAgencyFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);

  const [showModal, setShowModal] = useState(false);
  const [agencyFilter, setAgencyFilter] = useState(initialAgencyFilter);
  const isHdViewport = useMedia(`(min-width: ${BREAKPOINTS.hd}px)`);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const [fetchAgencyData, { loading, data }] = useLazyQuery(AGENCY_LIST, {
    variables: { filter: agencyFilter },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setAgencyId(res?.agencyList?.data?.[0]?.id);
      const pagination = {
        ...paginationProp,
        total: res?.agencyList?.total
      };
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const { data: unassignContactList } = useQuery(
    GET_UNASSIGN_CONTACT_LIST_COUNT,
    {
      variables: {
        filter: {
          skip: 0,
          limit: 10
        }
      },
      fetchPolicy: 'network-only'
    }
  );

  useEffect(() => {
    fetchAgencyData({ variables: { filter: agencyFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleRefetchAfterDelete = () => {
    const newSkip =
      data?.agencyList?.data?.length === 1
        ? Math.max(0, agencyFilter?.skip - paginationProp?.pageSize)
        : agencyFilter?.skip;
    setAgencyFilter({
      ...agencyFilter,
      skip: newSkip,
      sortBy: { field: 'createdAt', order: 'DESC' }
    });
    fetchAgencyData({
      variables: {
        filter: {
          ...agencyFilter,
          skip: newSkip,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const [deleteAgency] = useMutation(DELETE_AGENCY, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_GLOBAL_AGENCY, {
        label: GA_LABEL.DELETE_GLOBAL_AGENCY,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        agency_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      handleRefetchAfterDelete();
      setDeleteModal(false);
    }
  });

  const [
    updateAgencyImport,
    { loading: importLoading }
  ] = useMutation(IMPORT_AGENCY_CSV, { onError() {} });

  const refetchAgencyDetails = () => {
    fetchAgencyData({ variables: { filter: { ...agencyFilter } } });
  };

  const handleAddEditAgency = () => {
    setShowModal(true);
  };

  const handleImport = () => {
    setShowImportModal(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setAgencyFilter({
        ...agencyFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.field,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchAgencyData({
        variables: {
          filter: {
            ...agencyFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.field,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setAgencyFilter({
        ...agencyFilter,
        skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });
      fetchAgencyData({
        variables: {
          filter: {
            ...agencyFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' }
          }
        }
      });
    }
  };

  const handleEdit = (e, record) => {
    e?.stopPropagation?.();
    setAgencyData(record);
    handleAddEditAgency();
  };
  const handleDelete = (e, record) => {
    e?.stopPropagation?.();
    setTitle('Agency');
    setName(record?.name);
    setMutationId(record?.id);
    setDeleteModal(true);
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return (
          <div className="d-flex align-center">
            <div>{agencyFilter?.skip + index + 1}</div>
            {record?.projectAgencies?.length > 0 && (
              <Popover
                placement="right"
                overlayClassName="global-agency-info"
                trigger={[!isDesktopViewport ? 'click' : 'hover']}
                content={
                  <div>
                    <Descriptions layout="horizontal" colon={false} column={1}>
                      <Descriptions.Item label="Project name">
                        <EllipsisText
                          text={
                            record?.projectAgencies?.[0]?.projectName || '-'
                          }
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="Added By">
                        <EllipsisText
                          text={record?.projectAgencies?.[0]?.creator || '-'}
                        />
                      </Descriptions.Item>
                      <Descriptions.Item label="Created At">
                        {moment(record?.projectAgencies?.[0]?.createdAt).format(
                          DEFAULTDATETIMEFORMAT
                        )}
                      </Descriptions.Item>
                    </Descriptions>
                  </div>
                }
              >
                <ProjectOutlined onClick={(e) => e.stopPropagation()} />
              </Popover>
            )}
          </div>
        );
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (text) => (
        <div>
          <EllipsisText className="text-break" text={text} />
        </div>
      )
    },
    {
      title: 'TYPE',
      dataIndex: 'type',
      key: 'type',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'type' && sortedInfo?.order,
      render: (text) => titleCase(text)
    },
    {
      key: 'action',
      width: 50,
      render: (record) => {
        return (
          <div className="d-flex action-icons">
            <Tooltip title="Edit">
              <Button
                shape="round"
                icon={<EditButton />}
                className="edit-button pointer"
                onClick={(e) => handleEdit(e, record)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                className="delete-button pointer"
                shape="round"
                icon={<DeleteButton />}
                onClick={(e) => handleDelete(e, record)}
              />
            </Tooltip>
          </div>
        );
      }
    }
  ];

  const onSearchChange = async (value) => {
    setAgencyFilter({ ...agencyFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchAgencyData({
      variables: { filter: { ...agencyFilter, skip: 0, search: value } }
    });
  };

  return (
    <>
      {showModal && (
        <AddAgencyModal
          showModal={showModal}
          setShowModal={setShowModal}
          agencyData={agencyData}
          isUpdate={!!agencyData}
          setAgencyData={setAgencyData}
          refetchAgencyDataWithInitialValues={refetchAgencyDetails}
        />
      )}

      {deleteModal && (
        <DeleteAgencyModal
          showModal={deleteModal}
          setShowModal={setDeleteModal}
          title={title}
          name={name}
          deleteMutation={deleteAgency}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      )}
      {showImportModal && (
        <CommonImportModal
          showImportModal={showImportModal}
          setShowImportModal={setShowImportModal}
          title="Agency"
          sampleFileUrl={sampleFileUrl}
          list={list}
          updateAgencyImport={updateAgencyImport}
          refetchData={fetchAgencyData}
          filePrefix="agencyCsv"
          importLoading={importLoading}
        />
      )}

      <Portal portalId="agency-btn">
        {!isDesktopViewport && (
          <SearchComponent
            id="search-container"
            className="search-component mr-10"
            getData={onSearchChange}
          />
        )}
        <div className="d-flex">
          <Button
            shape="round"
            type="primary"
            id="add-btn"
            icon={<AddButton />}
            onClick={handleAddEditAgency}
          >
            {isHdViewport && 'Add'}
          </Button>

          <Button
            shape="round"
            type="primary"
            icon={<Import />}
            onClick={handleImport}
          >
            {isHdViewport && 'Import'}
          </Button>
          <Button
            shape="round"
            type="link"
            target="_blank"
            icon={<Export />}
            href={url}
            className="export-button"
            onClick={() => {
              Event(GA_EVENT.EXPORT_AGENCIES, {
                label: GA_LABEL.EXPORT_AGENCIES,
                // eslint-disable-next-line no-undef
                pathname: window?.location?.href,
                user_id: currentUser?.id,
                user_name: currentUser?.name,
                tenant_id: currentUser?.tenantUser?.tenant?.id,
                tenant_name: currentUser?.tenantUser?.tenant?.organizationName
              });
            }}
          >
            {isHdViewport && 'Export'}
          </Button>
        </div>
      </Portal>
      <div
        className={clsx(
          'd-flex align-center  width-percent-100',
          isDesktopViewport &&
            unassignContactList?.unassignContactList?.total > 0
            ? 'justify-between'
            : 'justify-end'
        )}
      >
        {unassignContactList?.unassignContactList?.total > 0 && (
          <Button
            shape="round"
            type="primary"
            className={clsx(
              'd-flex align-center mb-10 mr-10',
              !isDesktopViewport && 'font-size-12 ml-10'
            )}
            onClick={() => {
              history.push(`${ROUTES.SETUP}${ROUTES.UNASSIGN_AGENCIES}`);
            }}
          >
            {`${unassignContactList?.unassignContactList?.total} ${
              unassignContactList?.unassignContactList?.total > 1
                ? 'Contacts'
                : 'Contact'
            } Unassign`}
          </Button>
        )}

        {isDesktopViewport && (
          <SearchComponent
            id="search-container"
            className="search-component mb-10 mr-10 width-percent-50"
            getData={onSearchChange}
          />
        )}
      </div>

      <div className="table pointer">
        <CommonTable
          rowClassName={(record) => {
            if (record?.id === agencyId) return 'row-dark';
          }}
          loading={loading}
          columns={columns}
          data={data?.agencyList?.data || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey={(obj) => obj?.id}
          onRow={(record) => {
            return {
              onClick: () => {
                if (record?.id) {
                  setAgencyId(record?.id);
                  setModalVisible(true);
                  setModalTitle(record?.name);
                }
              }
            };
          }}
        />
      </div>
    </>
  );
};

export default AgenciesTable;
