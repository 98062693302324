import { Card } from 'antd';
import React from 'react';
import StageListTable from './StageListTable';

const StageList = ({
  stageId,
  setStageId,
  setModalVisible,
  eqcTypeData,
  setTitle
}) => {
  return (
    <Card className="stage-list-card">
      <div className="header d-flex align-center justify-between mb-10">
        <h2>Checklist Stage</h2>
        <div className="eqc-stage-header-buttons global-checklist-stage d-flex">
          <div id="eqc-details-btn" className="d-flex" />
          <div id="add-btn" />
        </div>
      </div>
      <StageListTable
        setStageId={setStageId}
        stageId={stageId}
        setModalVisible={setModalVisible}
        eqcTypeData={eqcTypeData}
        setModalTitle={setTitle}
      />
    </Card>
  );
};

export default StageList;
