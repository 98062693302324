import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Profile from './Profile';

const ProfileWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.PROFILE} component={Profile} />
    </Switch>
  );
};

export default ProfileWrapper;
