import { Card } from 'antd';
import React from 'react';
import StageListTable from './StageListTable';

const StageList = ({
  setStageId,
  stageId,
  eqcTypeData,
  setStageData,
  isSequenceChanged,
  setIsSequenceChanged,
  setTitle,
  title,
  isEditable,
  refetchRef,
  stageData
}) => {
  return (
    <Card className="stage-list-card project-stages">
      <div className="header d-flex align-center justify-between mb-10">
        <h2>Checklist Stage</h2>
        <div className="eqc-stage-header-buttons d-flex">
          <div id="eqc-details-btn" className="d-flex" />
          <div id="add-btn" />
        </div>
      </div>
      <StageListTable
        setStageId={setStageId}
        stageId={stageId}
        setTitle={setTitle}
        title={title}
        eqcTypeData={eqcTypeData}
        setStageData={setStageData}
        isSequenceChanged={isSequenceChanged}
        setIsSequenceChanged={setIsSequenceChanged}
        isEditable={isEditable}
        refetchRef={refetchRef}
        stageData={stageData}
      />
    </Card>
  );
};

export default StageList;
