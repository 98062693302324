import { isNaN } from 'lodash';
import React from 'react';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import Error404 from '../../Error404';
import {
  ACCESS_TYPE,
  ADD_PROJECT_STEPS_KEYS,
  ALLOWED_ACTION_KEYS,
  ROUTES,
  TAB_KEYS
} from '../../common/constants';
import CanPerform from '../../components/CanPerform';
import HasAccess from '../../components/HasAccess';
import AddProject from './AddProject';
import Projects from './Projects';
import ProjectDetails from './components/projectDetails/ProjectDetails';
import EqcDetails from './components/projectDetails/pages/eqc/eqcDetails/EqcDetails';
import EqcTypeDetails from './components/projectDetails/pages/eqcTypes/eqcTypeDetails/EqcTypeDetails';
import InstructionDetails from './components/projectDetails/pages/instructions/instructionDetails';
import RfiDetails from './components/projectDetails/pages/rfi/RfiDetails';

const RedirectToProjectDetails = () => {
  const { projectId } = useParams();
  if (isNaN(Number(projectId))) {
    return <Error404 />;
  }
  const redirectPath = HasAccess({ type: ACCESS_TYPE.INSPECTION })
    ? TAB_KEYS.EQC
    : TAB_KEYS.INSTRUCTION;
  return <Redirect to={`${ROUTES.PROJECTS}/${projectId}/${redirectPath}`} />;
};

const RedirectToAddProject = () => {
  const { projectId } = useParams();
  if (isNaN(Number(projectId))) {
    return <Error404 />;
  }
  return (
    <Redirect
      to={`${ROUTES.ADD_PROJECTS}/${projectId}/${ADD_PROJECT_STEPS_KEYS.DETAILS}`}
    />
  );
};

const ProjectWrapper = () => {
  return (
    <Switch>
      <Route exact path={ROUTES.PROJECTS} component={Projects} />
      <Route exact path={ROUTES.ADD_PROJECTS} component={AddProject} />
      {CanPerform({ action: ALLOWED_ACTION_KEYS.ADD_PROJECT }) && (
        <Route
          path={`${ROUTES.ADD_PROJECTS}/:projectId/:step`}
          component={AddProject}
        />
      )}
      <Route
        path={`${ROUTES.ADD_PROJECTS}/:projectId`}
        component={RedirectToAddProject}
      />
      {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
        <Route
          path={`${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.EQC}/:eqcId`}
          component={EqcDetails}
        />
      )}
      {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
        <Route
          exact
          path={`${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.CHECKLISTS}/:eqcTypeId`}
          component={EqcTypeDetails}
        />
      )}
      {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
        <Route
          exact
          path={`${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.CHECKLISTS}/:eqcTypeId/draft`}
          component={EqcTypeDetails}
        />
      )}
      {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
        <Route
          exact
          path={`${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.CHECKLISTS}/:eqcTypeId/changelog`}
          component={EqcTypeDetails}
        />
      )}
      {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
        <Route
          exact
          path={`${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.RFI}/:rfiId`}
          component={RfiDetails}
        />
      )}
      {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
        <Route
          exact
          path={`${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.RFI}/:rfiId/draft`}
          component={RfiDetails}
        />
      )}
      {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
        <Route
          exact
          path={`${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.RFI}/:rfiId/changelog`}
          component={RfiDetails}
        />
      )}
      <Route
        exact
        path={`${ROUTES.PROJECTS}/:projectId`}
        component={RedirectToProjectDetails}
      />
      {HasAccess({ type: ACCESS_TYPE.INSTRUCTION }) && (
        <Route
          exact
          path={`${ROUTES.PROJECTS}/:projectId/${TAB_KEYS.INSTRUCTION}/:instructionId`}
          component={InstructionDetails}
        />
      )}
      <Route
        exact
        path={`${ROUTES.PROJECTS}/:projectId/:tab/unassigned`}
        component={ProjectDetails}
      />
      <Route
        exact
        path={`${ROUTES.PROJECTS}/:projectId/:tab`}
        component={ProjectDetails}
      />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default ProjectWrapper;
