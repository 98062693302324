import { useQuery } from '@apollo/client';
import { Card, Descriptions } from 'antd';
import clsx from 'clsx';
import { map } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useMedia } from 'react-use';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  PROJECT_CHECKLIST_STATUS
} from '../../../../../../common/constants';
import EllipsisText from '../../../../../../components/EllipsisText';
import { GET_PROJECT_RFI_VERSION_LOGS } from '../../../../graphql/Queries';
import VersionLogCard from './VersionLogCard';

const ChangeLog = ({ RFIData }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const { data, loading } = useQuery(GET_PROJECT_RFI_VERSION_LOGS, {
    variables: { projectId: RFIData?.project?.id },
    fetchPolicy: 'network-only',
    onCompleted: () => {},
    onError() {}
  });

  return (
    <>
      <h2>Version Change Log</h2>
      {map(data?.getProjectRFIVersionLogs?.data, (versionData) => {
        return (
          <Card className="change-log-module mt-24" loading={loading}>
            <Descriptions
              column={isDesktopViewport ? 4 : 1}
              layout="vertical"
              colon={false}
              className="d-flex"
            >
              <Descriptions.Item
                label={
                  versionData?.status === PROJECT_CHECKLIST_STATUS.ARCHIVED
                    ? 'Made archived by'
                    : 'Made live by'
                }
                className={clsx(!isDesktopViewport && 'common-item')}
              >
                <EllipsisText text={versionData?.publishedBy || '-'} />
              </Descriptions.Item>
              <Descriptions.Item
                label={
                  versionData?.status === PROJECT_CHECKLIST_STATUS.ARCHIVED
                    ? 'Made archived on'
                    : 'Made live on'
                }
              >
                {versionData?.publishedOn
                  ? moment(versionData?.publishedOn).format(DATETIMEWITHDIVIDE)
                  : '-'}
              </Descriptions.Item>
            </Descriptions>
            {versionData?.data?.length > 0 && (
              <>
                <h4 className="mt-16">Updates</h4>
                {map(versionData?.data, (record, index) => {
                  return <VersionLogCard key={index} data={record} />;
                })}
              </>
            )}
          </Card>
        );
      })}
    </>
  );
};

export default ChangeLog;
