import { Collapse, Space } from 'antd';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../common/constants';
import AgencyAnalysis from './components/AgencyAnalysis';
import Overview from './components/Overview';
import ProjectAnalysis from './components/ProjectAnalysis';
import UserAnalysis from './components/UserAnalysis';

const { Panel } = Collapse;
const Dashboard = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Dashboard</div>
        </>
      )}
      {isDesktopViewport ? (
        <Space direction="vertical" size={24} className="width-percent-100">
          <Overview />
          <ProjectAnalysis />
          <UserAnalysis />
          <AgencyAnalysis />
        </Space>
      ) : (
        <div className="width-percent-100 mb-20 dashboard-wrapper">
          <Collapse defaultActiveKey={['1']} expandIconPosition="right">
            <Panel header={<h3>Overview</h3>} key="1">
              <Overview />
            </Panel>
            <Panel header={<h3>Project Analysis</h3>} key="2">
              <ProjectAnalysis />
            </Panel>
            <Panel header={<h3>User Analysis</h3>} key="3">
              <UserAnalysis />
            </Panel>
            <Panel header={<h3>Agency Analysis</h3>} key="4">
              <AgencyAnalysis />
            </Panel>
          </Collapse>
        </div>
      )}
    </>
  );
};
export default Dashboard;
