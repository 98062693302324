import { useMutation } from '@apollo/client';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Switch
} from 'antd';
import { filter, forEach, includes, keys, map, omit, toLower } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../../AppContext';
import { DeleteButton } from '../../../../../../../assets/svg';
import {
  BREAKPOINTS,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
  TYPES,
  TYPES_LABELS
} from '../../../../../../../common/constants';
import { Event } from '../../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../../common/utils';
import CommonSelect from '../../../../../../../components/CommonSelect';
import TextEditor from '../../../../../../../components/TextEditor';
import {
  CREATE_PROJECT_RFI_POINT,
  UPDATE_PROJECT_RFI_POINT
} from '../../../../../graphql/Mutation';

const { required, characterWithoutWhiteSpace } = formValidatorRules;
const { Option } = CommonSelect;

const OptionUi = (isDesktopViewport, isDisabled) => {
  return (
    <>
      <Form.List name="options">
        {(fields, { add, remove }) => {
          return (
            <>
              <Row gutter={[15, 15]} className="width-percent-100">
                {fields.map((field) => (
                  <Col span={24} key={field.key}>
                    <Row gutter={[30]}>
                      <Col flex="auto">
                        <Form.Item
                          {...field}
                          label="Value"
                          name={[field.name, 'option']}
                          fieldKey={[field.fieldKey, 'option']}
                          validateTrigger={['onChange', 'onClick']}
                          rules={[
                            required,
                            characterWithoutWhiteSpace,
                            ({ getFieldValue }) => ({
                              validator(rule, value) {
                                const items = map(
                                  getFieldValue()?.options,
                                  (item) => item?.option
                                );
                                if (
                                  filter(
                                    items,
                                    (val) => toLower(val) === toLower(value)
                                  )?.length > 1 &&
                                  value
                                ) {
                                  // eslint-disable-next-line prefer-promise-reject-errors
                                  return Promise.reject(
                                    'should be a unique option'
                                  );
                                }
                                return Promise.resolve();
                              }
                            })
                          ]}
                        >
                          <Input
                            placeholder="Enter  Value"
                            disabled={isDisabled}
                          />
                        </Form.Item>
                      </Col>
                      {fields?.length > 2 && (
                        <Col className="d-flex align-center">
                          <Form.Item
                            label={isDesktopViewport && ' '}
                            className="mb-0"
                          >
                            <DeleteButton
                              className="pointer"
                              onClick={() => {
                                if (!isDisabled && fields?.length > 2)
                                  remove(field?.name);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      )}
                    </Row>
                  </Col>
                ))}

                <Row>
                  <Col className="mb-15">
                    <Button
                      className="grey-button"
                      shape="round"
                      disabled={isDisabled}
                      onClick={() => {
                        add();
                      }}
                      block
                    >
                      Add Option
                    </Button>
                  </Col>
                </Row>
              </Row>
            </>
          );
        }}
      </Form.List>
    </>
  );
};

const AddEditModal = (props) => {
  const {
    isUpdate,
    showModal,
    setShowModal,
    refetchStageDataWithInitialValues,
    isDisabled,
    isEditable,
    RFIData,
    selectedData
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const [displayOption, setDisplayOption] = useState(false);
  const [imageLoader, setImageLoader] = useState(false);
  const [isYesNoType, setIsYesNoType] = useState(true);
  const [isNumeric, setIsNumeric] = useState(false);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [displayOptionValue, setDisplayOptionValue] = useState();
  const { projectId } = useParams();

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };
  const toggleImageLoader = () => {
    setImageLoader((value) => !value);
  };
  const [createProjectRFIPoint, { loading: createLoading }] = useMutation(
    CREATE_PROJECT_RFI_POINT,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.ADD_PROJECT_RFI_POINT, {
          label: GA_LABEL.ADD_PROJECT_RFI_POINT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        form.resetFields();
        setShowModal(false);
        refetchStageDataWithInitialValues();
      }
    }
  );

  const [updateProjectRFIPoint, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT_RFI_POINT,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.UPDATE_PROJECT_RFI_POINT, {
          label: GA_LABEL.UPDATE_PROJECT_RFI_POINT,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          // project_stage_id: stageId,
          // stage_item_id: stageData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setShowModal(false);
        refetchStageDataWithInitialValues();
      }
    }
  );

  const handleTypeChange = (options) => {
    switch (options?.value) {
      case TYPES['Y/N']:
        setDisplayOption(false);
        setIsYesNoType(true);
        setIsNumeric(false);
        setDisplayOptionValue();
        break;
      case TYPES.OPTION:
        setDisplayOption(true);
        setIsYesNoType(false);
        setIsNumeric(false);
        form.setFieldsValue({ options: [{}, {}] });
        setDisplayOptionValue(
          OptionUi(
            isDesktopViewport,
            (isDisabled ||
              !isEditable ||
              (includes(
                [
                  PROJECT_CHECKLIST_STATUS.PUBLISHED,
                  PROJECT_CHECKLIST_STATUS.ARCHIVED
                ],
                RFIData?.projectRfi?.status
              ) &&
                isUpdate)) &&
              !selectedData?.isNewPoint
          )
        );
        break;
      case TYPES.NUMERIC:
        setDisplayOption(false);
        setIsYesNoType(false);
        setIsNumeric(true);
        setDisplayOptionValue();
        break;
      default:
        setDisplayOption(false);
        setIsYesNoType(false);
        setIsNumeric(false);
        break;
    }
  };
  const handleSelect = (labeledValue, options) => {
    handleTypeChange(options);
  };
  useEffect(() => {
    if (isUpdate) {
      handleTypeChange({ value: selectedData?.type });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);
  const onAgencySubmitFinish = async (formValues) => {
    if (
      formValues?.description?.replace(/<(.|\n)*?>/g, '').trim().length === 0 &&
      !formValues?.description?.includes('<img')
    ) {
      form.setFieldsValue('description', '');
    }
    const options = [];

    if (formValues.type === TYPES.OPTION) {
      forEach(formValues.options, (option) => {
        const typeOptionValue = {
          option: option.option
        };
        options.push(typeOptionValue);
      });
    }

    let newFormValues = {
      title: formValues.title,
      description:
        formValues?.description?.replace(/<(.|\n)*?>/g, '').trim().length ===
          0 && !formValues?.description?.includes('<img')
          ? ''
          : formValues?.description,
      type: formValues?.type,
      photo: formValues?.photo || false,
      remark: formValues?.remark || false,
      options,
      projectRFIId: Number(RFIData?.id)
    };
    if (isDisabled) {
      newFormValues = omit(newFormValues, ['title', 'type', 'options']);
    }
    const variables = isUpdate
      ? { data: newFormValues, id: selectedData.id }
      : newFormValues;

    try {
      if (isUpdate) {
        await updateProjectRFIPoint({
          variables: variables
        });
        return;
      }
      await createProjectRFIPoint({
        variables: {
          data: variables
        }
      });
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    if (showModal && isUpdate) {
      form.setFieldsValue({
        ...selectedData,
        description: selectedData?.description || ''
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="stageitem-modal"
      footer={null}
      closable={false}
      width={isDesktopViewport ? 700 : '90vw'}
    >
      <h2 className="mb-15">{isUpdate ? 'Edit RFI Point' : 'Add RFI Point'}</h2>
      {includes(
        [PROJECT_CHECKLIST_STATUS.PUBLISHED, PROJECT_CHECKLIST_STATUS.ARCHIVED],
        RFIData?.projectRfi?.status
      ) &&
        !selectedData?.isNewPoint &&
        isUpdate && (
          <Popover
            overlayClassName="checklist-item-note-popup"
            getPopupContainer={() =>
              // eslint-disable-next-line no-undef
              document.querySelector('.stageitem-modal')
            }
            trigger={isDesktopViewport ? 'hover' : 'click'}
            placement={isDesktopViewport ? 'right' : 'bottom'}
            destroyTooltipOnHide
            content={
              <div onClick={(e) => e.stopPropagation()}>
                Changing checklist question, type and options will affect the
                analysis of the data and will give you improper information.
                Decision making through this data will not be proper.
              </div>
            }
          >
            <div className="note">
              Why can't I edit question, type and options?
            </div>
          </Popover>
        )}
      <Form
        form={form}
        initialValues={
          isUpdate
            ? { description: '' }
            : {
                type: 'Y/N',
                noOption: true,
                options: [{}, {}],
                description: ''
              }
        }
        className={
          !includes(
            [
              PROJECT_CHECKLIST_STATUS.PUBLISHED,
              PROJECT_CHECKLIST_STATUS.ARCHIVED
            ],
            RFIData?.projectRfi?.status
          ) ||
          !isUpdate ||
          (selectedData?.isNewPoint && 'mt-16')
        }
        layout="vertical"
        onFinish={onAgencySubmitFinish}
      >
        <Form.Item
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 500,
              message: 'Title cannot be more than 500 characters'
            }
          ]}
          name="title"
          label="RFI Point"
        >
          <Input
            allowClear
            placeholder="Enter RFI Point"
            disabled={
              (isDisabled ||
                !isEditable ||
                (includes(
                  [
                    PROJECT_CHECKLIST_STATUS.PUBLISHED,
                    PROJECT_CHECKLIST_STATUS.ARCHIVED
                  ],
                  RFIData?.projectRfi?.status
                ) &&
                  isUpdate)) &&
              !selectedData?.isNewPoint
            }
          />
        </Form.Item>
        <Form.Item name="description" label="Information">
          <TextEditor
            readOnly={isDisabled || !isEditable}
            toggleImageLoader={toggleImageLoader}
          />
        </Form.Item>
        <Row gutter={30}>
          <Col span={isDesktopViewport ? 12 : 24}>
            <Form.Item name="type" label="Type">
              <CommonSelect
                className="mr-3"
                placeholder="Select Type"
                onSelect={handleSelect}
                disabled={
                  (isDisabled ||
                    !isEditable ||
                    (includes(
                      [
                        PROJECT_CHECKLIST_STATUS.PUBLISHED,
                        PROJECT_CHECKLIST_STATUS.ARCHIVED
                      ],
                      RFIData?.projectRfi?.status
                    ) &&
                      isUpdate)) &&
                  !selectedData?.isNewPoint
                }
              >
                {keys(TYPES_LABELS).map((type) => {
                  return (
                    <Option key={TYPES_LABELS[type]} value={type}>
                      {TYPES_LABELS[type]}
                    </Option>
                  );
                })}
              </CommonSelect>
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 6 : 12}>
            <Form.Item
              name="photo"
              label="RFI Photo Required"
              valuePropName="checked"
            >
              <Switch disabled={isDisabled || !isEditable} />
            </Form.Item>
          </Col>
          <Col span={isDesktopViewport ? 6 : 12}>
            <Form.Item
              name="remark"
              label="Remarks Required"
              valuePropName="checked"
            >
              <Switch disabled={isDisabled || !isEditable} />
            </Form.Item>
          </Col>
        </Row>
        {displayOption && (
          <>
            <Divider dashed />
            {!isNumeric && <h3>{isYesNoType ? 'Yes/No' : 'Options'}</h3>}
            {displayOptionValue}
          </>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            hidden={isDisabled || !isEditable}
            disabled={imageLoader}
            loading={createLoading || updateLoading}
          >
            {isUpdate ? 'Save' : 'Add'}
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddEditModal;
