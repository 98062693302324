import { Form, Input, Select } from 'antd';
import { map } from 'lodash';
import React from 'react';
import countryCode from '../assets/countryCode.json';
import { EXCEPT_THIS_SYMBOLS } from '../common/constants';

const { Option } = Select;

const PhoneNumberInputWithCountyCode = ({
  formItemName = 'countryCode',
  ...rest
}) => {
  return (
    <Input
      onKeyDown={(e) =>
        EXCEPT_THIS_SYMBOLS.includes(e?.key) && e?.preventDefault()
      }
      className="country-code-phone-input"
      addonBefore={
        <Form.Item name={formItemName} className="country-code-input">
          <Select showSearch placeholder="Code">
            {map(countryCode, (record) => (
              <Option key={record?.id} value={record?.dialCode}>
                {record?.dialCode}
              </Option>
            ))}
          </Select>
        </Form.Item>
      }
      placeholder="Enter Mobile Number"
      {...rest}
    />
  );
};

export default PhoneNumberInputWithCountyCode;
