import { useMutation } from '@apollo/client';
import { Col, Row, Tree } from 'antd';
import clsx from 'clsx';
import { filter, includes, isEmpty, toLower } from 'lodash';
import React, { useMemo, useState } from 'react';
import { useMedia } from 'react-use';
import 'reactflow/dist/style.css';
import { AddButton, DragIcon, EditIcon } from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  NOMENCLATURE_TREE_TITLE_CLASS_BY_LEVEL
} from '../../../../../../common/constants';
import CanPerform from '../../../../../../components/CanPerform';
import Portal from '../../../../../../components/Portal';
import SearchComponent from '../../../../../../components/SearchComponent';
import { MOVE_PROJECT_NOMENCLATURE_LEVEL } from '../../../../graphql/Mutation';

const { TreeNode } = Tree;

const getTitle = (strTitle, searchValue) => {
  const index = toLower(strTitle)?.indexOf(toLower(searchValue));
  const beforeStr = strTitle?.substring(0, index);
  const searchText = strTitle?.slice(index, index + searchValue?.length);
  const afterStr = strTitle?.slice(index + searchValue?.length);
  return index > -1 ? (
    <span>
      {beforeStr}
      <span className="site-tree-search-value">{searchText}</span>
      {afterStr}
    </span>
  ) : (
    <span>{strTitle}</span>
  );
};
const renderTreeNodes = ({
  data,
  checkedTreeNode,
  handleAdd,
  handleEdit,
  isDisabledNodeSelected,
  needActionButton,
  searchValue,
  isDesktopViewport,
  isProjectActive
}) => {
  return data?.map((item) => {
    if (item?.data?.length > 0) {
      return (
        <TreeNode
          {...item}
          checkable={needActionButton ? item?.level !== 0 : true}
          disableCheckbox={
            needActionButton
              ? (checkedTreeNode?.length > 0 &&
                  item?.parentLevelId !==
                    checkedTreeNode?.[0]?.data?.data?.parentLevelId) ||
                (checkedTreeNode?.length > 0 &&
                  (isDisabledNodeSelected ? item?.isActive : !item?.isActive))
              : false
          }
          title={
            <Row align="middle">
              <Col
                className={
                  item?.isActive
                    ? NOMENCLATURE_TREE_TITLE_CLASS_BY_LEVEL[item?.level]
                    : 'disabled-tree-title'
                }
              >
                <span className="text-secondary font-size-10">
                  L{item?.level} -&nbsp;
                </span>
                <span className="level-name">
                  {searchValue ? getTitle(item?.name, searchValue) : item?.name}
                </span>
              </Col>
              <CanPerform
                action={ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE}
                type={ALLOWED_ACTION_TYPE.BOTH}
              >
                {isProjectActive &&
                  isDesktopViewport &&
                  item?.isActive &&
                  needActionButton && (
                    <Col className="node-action ml-16">
                      {item?.level < 7 && (
                        <AddButton
                          className="add-button mr-5"
                          onClick={() => handleAdd(item)}
                          height={20}
                          width={20}
                        />
                      )}
                      {item?.level !== 0 && (
                        <EditIcon
                          height={20}
                          width={20}
                          onClick={() => handleEdit(item)}
                        />
                      )}
                    </Col>
                  )}
              </CanPerform>
            </Row>
          }
          key={item?.id}
        >
          {renderTreeNodes({
            data: item?.data,
            checkedTreeNode,
            handleAdd,
            handleEdit,
            isDisabledNodeSelected,
            needActionButton,
            searchValue,
            isDesktopViewport,
            isProjectActive
          })}
        </TreeNode>
      );
    }
    return (
      <TreeNode
        {...item}
        checkable={needActionButton ? item?.level !== 0 : true}
        disableCheckbox={
          needActionButton
            ? (checkedTreeNode?.length > 0 &&
                item?.parentLevelId !==
                  checkedTreeNode?.[0]?.data?.data?.parentLevelId) ||
              (checkedTreeNode?.length > 0 &&
                (isDisabledNodeSelected ? item?.isActive : !item?.isActive))
            : false
        }
        title={
          <Row align="middle">
            <Col
              className={
                item?.isActive
                  ? NOMENCLATURE_TREE_TITLE_CLASS_BY_LEVEL[item?.level]
                  : 'disabled-tree-title'
              }
            >
              <span className="text-secondary font-size-10">
                L{item?.level} -&nbsp;
              </span>
              <span className="level-name">
                {searchValue ? getTitle(item?.name, searchValue) : item?.name}
              </span>
            </Col>
            <CanPerform
              action={ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE}
              type={ALLOWED_ACTION_TYPE.BOTH}
            >
              {isProjectActive &&
                isDesktopViewport &&
                item?.isActive &&
                needActionButton && (
                  <Col className="node-action ml-16">
                    {item?.level < 7 && (
                      <AddButton
                        className="add-button mr-5"
                        onClick={() => handleAdd(item)}
                        height={20}
                        width={20}
                      />
                    )}
                    {item?.level !== 0 && (
                      <EditIcon
                        height={20}
                        width={20}
                        onClick={() => handleEdit(item)}
                      />
                    )}
                  </Col>
                )}
            </CanPerform>
          </Row>
        }
        key={item?.id}
      />
    );
  });
};
const NomenclatureTree = ({
  nomenclatureList,
  setShowAddEditLevelModal,
  setSelectedTree,
  chartNodes,
  setCheckedTreeNode,
  checkedTreeNode,
  setNomenclatureList,
  setCheckedKeys,
  checkedKeys,
  setIsDisabledNodeSelected,
  isDisabledNodeSelected,
  needActionButton = true,
  handleCheck,
  className = '',
  expandedKeys,
  setExpandedKeys,
  handleRefetch,
  isProjectActive
}) => {
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [searchValue, setSearchValue] = useState();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const canEdit = CanPerform({
    action: ALLOWED_ACTION_KEYS.EDIT_NOMENCLATURE,
    type: ALLOWED_ACTION_TYPE.BOTH
  });

  const handleAdd = (data) => {
    setSelectedTree(data);
    setShowAddEditLevelModal(true);
  };

  useMemo(() => {
    if (!searchValue && isEmpty(checkedKeys) && isEmpty(expandedKeys)) {
      const newArray = [];
      (function keyGenerator(list) {
        list?.forEach((data) => {
          if (data?.level < 1) {
            if (data?.data?.length > 0) {
              newArray.push(`${data?.id}`);
              keyGenerator(data?.data);
            } else {
              newArray.push(`${data?.id}`);
            }
          }
        });
      })(nomenclatureList);
      setExpandedKeys(newArray);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nomenclatureList]);

  const [moveProjectNomenclatureLevel] = useMutation(
    MOVE_PROJECT_NOMENCLATURE_LEVEL,
    {
      onCompleted() {
        handleRefetch();
      },
      onError() {}
    }
  );

  const handleEdit = (data) => {
    const newData = {
      ...data,
      isEdit: true
    };
    setSelectedTree(newData);
    setShowAddEditLevelModal(true);
  };

  const onCheck = (checkedKeysValue) => {
    if (handleCheck) {
      handleCheck(checkedKeysValue?.checked);
    } else {
      const filteredNode = filter(chartNodes, (list) =>
        includes(checkedKeysValue?.checked, list?.id)
      );
      setIsDisabledNodeSelected(
        filteredNode?.length > 0 && !filteredNode?.[0]?.data?.data?.isActive
      );
      setCheckedTreeNode(filteredNode);
    }
    setNomenclatureList([...nomenclatureList]);
    setCheckedKeys(checkedKeysValue?.checked);
  };
  const onSelect = (selectedKeysValue) => {
    setSelectedKeys(selectedKeysValue);
  };

  const onExpand = async (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onChange = (value) => {
    if (value) {
      const newExpandedKeys = chartNodes?.map((item) => {
        if (toLower(item?.data?.data?.name)?.indexOf(toLower(value)) > -1) {
          return item?.data?.data?.id;
        }
        return null;
      });
      setExpandedKeys(newExpandedKeys);
    }
    setSearchValue(value);
    setAutoExpandParent(true);
  };
  const onDrop = (info) => {
    setCheckedTreeNode([]);
    setCheckedKeys();
    moveProjectNomenclatureLevel({
      variables: {
        data: {
          id: info?.dragNode?.key,
          targetLevelId: info?.node?.key,
          order: info?.dropPosition + 1
        }
      }
    });
  };
  const searchBar = () => {
    return (
      <div className={clsx('search-div', !needActionButton ? 'mb-10' : 'p-10')}>
        <SearchComponent getData={onChange} defaultValue={searchValue} />
      </div>
    );
  };
  return (
    <>
      {needActionButton ? (
        <Portal portalId="search-div">{searchBar()}</Portal>
      ) : (
        searchBar()
      )}
      <div className={`tree-structure thin-scrollbar ${className}`}>
        <Tree
          autoExpandParent={autoExpandParent}
          onExpand={onExpand}
          expandedKeys={expandedKeys}
          checkable={isProjectActive && canEdit && isDesktopViewport}
          checkStrictly
          onCheck={onCheck}
          checkedKeys={checkedKeys}
          onSelect={onSelect}
          selectedKeys={selectedKeys}
          draggable={{
            icon: <DragIcon height={16} width={16} />,
            nodeDraggable: (node) => {
              return (
                node?.level !== 0 &&
                canEdit &&
                isProjectActive &&
                isDesktopViewport &&
                needActionButton
              );
            }
          }}
          onDrop={onDrop}
        >
          {renderTreeNodes({
            data: nomenclatureList,
            checkedTreeNode,
            handleAdd,
            handleEdit,
            isDisabledNodeSelected,
            needActionButton,
            searchValue,
            isDesktopViewport,
            isProjectActive
          })}
        </Tree>
      </div>
    </>
  );
};

export default NomenclatureTree;
