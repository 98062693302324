import { useMutation } from '@apollo/client';
import { Button, Modal } from 'antd';
import { map } from 'lodash';
import React from 'react';
import { NOMENCLATURE_ACTION_BUTTON_URL } from '../../../../../../common/constants';
import { DELETE_PROJECT_NOMENCLATURE_LEVEL } from '../../../../graphql/Mutation';

const DeleteLevelModal = ({
  showModal,
  setShowModal,
  handleRefetch,
  setCheckedTreeNode,
  checkedTreeNode,
  setCheckedKeys,
  checkedKeys
}) => {
  const data = [];
  map(checkedTreeNode, (record) => {
    if (Number(record?.data?.data?.totalInspectionCount) > 0) {
      data.push(record?.data?.data?.name);
    }
  });

  const handleCancel = () => {
    setShowModal(false);
  };

  const [deleteProjectNomenclatureLevel, { loading }] = useMutation(
    DELETE_PROJECT_NOMENCLATURE_LEVEL,
    {
      onCompleted() {
        setCheckedKeys();
        setCheckedTreeNode();
        handleCancel();
        handleRefetch();
      },
      onError() {}
    }
  );

  const handleDelete = () => {
    deleteProjectNomenclatureLevel({
      variables: {
        levels: map(checkedKeys, (item) => Number(item))
      }
    });
  };
  return (
    <div id="delete-levels">
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        footer={null}
        closable={false}
        width={420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('delete-levels')
        }
      >
        <div>
          <h2>Delete Level</h2>
          <p>Hey there! </p>
          <p>
            The nomenclature path of EQC's already done shall get disabled while
            all others get deleted.
          </p>
          <p>
            <a
              href={NOMENCLATURE_ACTION_BUTTON_URL.DELETE}
              rel="noreferrer"
              target="_blank"
            >
              Click here
            </a>{' '}
            to understand more in detail.
          </p>
          {/* commented for future use */}
          {/* {data?.length > 0 && (
            <>
              <b>
                Note: Currently, there are {data?.length} levels linked with
                EQCs that will be disabled instead of deleted.To view the
                details of these linked levels,
                <Button
                  className="click-here-button"
                  type="link"
                  onClick={() => setHideList(false)}
                >
                  click here
                </Button>
              </b>
              <ol hidden={hideList} className="thin-scrollbar">
                {map(data, (name) => {
                  return <li>{name}</li>;
                })}
              </ol>
            </>
          )} */}
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
              disabled={loading}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              onClick={handleDelete}
              disabled={loading}
              loading={loading}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteLevelModal;
