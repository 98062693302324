import { useLazyQuery } from '@apollo/client';
import { Card, Col, Modal, Row } from 'antd';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../../../../../../AppContext';
import { WrongIcon } from '../../../../../../../assets/svg';
import {
  DATETIMEWITHDIVIDE,
  DEFAULT_PAGE_SIZE,
  SCROLL_CONST
} from '../../../../../../../common/constants';
import EllipsisText from '../../../../../../../components/EllipsisText';
import LoaderComponent from '../../../../../../../components/LoaderComponent';
import { EQC_PAUSE_LOG_LIST_BY_HISTORY } from '../../../../../graphql/Queries';

const PauseRemarkModal = ({ showModal, setShowModal, selectedStageId }) => {
  const [commentData, setCommentData] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const { getCurrentProjectDetail } = useContext(AppContext);
  const currentProjectDetails = getCurrentProjectDetail();
  const [fetchComments, { loading }] = useLazyQuery(
    EQC_PAUSE_LOG_LIST_BY_HISTORY,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.eqcPauseLogListByHistory?.data;
        let datalist;
        if (scrollFlag) {
          datalist = [...commentData, ...data];
          setCommentData(datalist);
          setScrollFlag(false);
        } else {
          datalist = [...data];
          setCommentData(datalist);
        }
        setHasMore(datalist?.length < res?.eqcPauseLogListByHistory?.total);
      }
    }
  );
  useEffect(() => {
    if (showModal) {
      fetchComments({
        variables: {
          filter: {
            eqcStageHistoryId: selectedStageId,
            skip: 0,
            limit: DEFAULT_PAGE_SIZE
          }
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModal]);
  const handleScroll = (e) => {
    const { target } = e;
    const { scrollTop, scrollHeight, offsetHeight } = target || {};
    const scrolledToBottom =
      scrollTop + offsetHeight >= scrollHeight - SCROLL_CONST;
    if (scrolledToBottom && hasMore && !loading) {
      setScrollFlag(true);
      fetchComments({
        variables: {
          filter: {
            eqcStageHistoryId: selectedStageId,
            skip: commentData?.length,
            limit: DEFAULT_PAGE_SIZE
          }
        }
      });
    }
  };
  return (
    <div id="pause-remark-modal">
      <Modal
        maskClosable={false}
        visible={showModal}
        onCancel={() => setShowModal(false)}
        closable
        footer={
          <div className="text-danger font-size-12">
            *Start date not available in some rows due to app forcefully killed
            by user
          </div>
        }
        closeIcon={<WrongIcon />}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('pause-remark-modal')
        }
      >
        <div>
          <h2>Pause Remarks</h2>
          <div
            className="common-scroll-wrapper position-relative"
            onScroll={handleScroll}
          >
            <div className="position-absolute width-percent-100 infinite-data-container auditor-comment-modal">
              <LoaderComponent spinning={loading} size="medium">
                {commentData?.map((res) => (
                  <Card
                    className="comment-card mb-15"
                    loading={loading}
                    key={res}
                  >
                    <Row gutter={10} justify="space-between" align="middle">
                      <Col>
                        <div className="comment-time">Start date</div>
                        <div className="bold-detail-text">
                          {res?.pausedAt
                            ? moment(res?.pausedAt)
                                .tz(currentProjectDetails?.timeZone)
                                .format(DATETIMEWITHDIVIDE)
                            : '-'}
                        </div>
                      </Col>
                      <Col>
                        <div className="comment-time">End date</div>
                        <div className="bold-detail-text">
                          {moment(res?.restartedAt)
                            .tz(currentProjectDetails?.timeZone)
                            .format(DATETIMEWITHDIVIDE)}
                        </div>
                      </Col>
                    </Row>
                    <Row gutter={10} justify="space-between" align="middle">
                      <Col>
                        <div className="comment-time mt-16">Remark</div>
                        <EllipsisText
                          className="bold-detail-text"
                          text={res?.remark}
                        />
                      </Col>
                    </Row>
                  </Card>
                ))}
              </LoaderComponent>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default PauseRemarkModal;
