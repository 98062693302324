import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../common/utils';
import {
  DUPLICATE_PROJECT_EQC_TYPE,
  DUPLICATE_PROJECT_EQC_TYPE_STAGE
} from '../../../../graphql/Mutation';

const DuplicateEqcTypeModal = (props) => {
  const {
    showModal,
    setShowModal,
    projectEqcTypeId,
    projectEqcTypeStageId,
    setMutationId,
    refetchEqcTypeData,
    title = 'Duplicate Checklist'
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { required, characterWithoutWhiteSpace } = formValidatorRules;

  const handleCancel = () => {
    setShowModal(false);
    if (setMutationId) {
      setMutationId();
    }
    form.resetFields();
  };
  const [duplicateProjectEqcType, { loading }] = useMutation(
    DUPLICATE_PROJECT_EQC_TYPE,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.DUPLICATE_PROJECT_CHECKLIST, {
          label: GA_LABEL.DUPLICATE_PROJECT_CHECKLIST,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_checklist_id: projectEqcTypeId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        if (setMutationId) {
          setMutationId();
        }
        form.resetFields();
        setShowModal(false);
        refetchEqcTypeData();
      }
    }
  );
  const [
    duplicateProjectEqcTypeStage,
    { loading: stageDuplicateLoading }
  ] = useMutation(DUPLICATE_PROJECT_EQC_TYPE_STAGE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DUPLICATE_PROJECT_CHECKLIST_STAGE, {
        label: GA_LABEL.DUPLICATE_PROJECT_CHECKLIST_STAGE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        project_checklist_id: projectEqcTypeId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      form.resetFields();
      setShowModal(false);
      refetchEqcTypeData();
    }
  });

  const onFormSubmit = async (formValues) => {
    try {
      if (projectEqcTypeId) {
        await duplicateProjectEqcType({
          variables: {
            data: {
              ...formValues,
              projectId: Number(projectId),
              projectEqcTypeId: Number(projectEqcTypeId)
            }
          }
        });
      }
      if (projectEqcTypeStageId) {
        await duplicateProjectEqcTypeStage({
          variables: {
            data: {
              ...formValues,
              projectId: Number(projectId),
              projectEqcTypeStageId: Number(projectEqcTypeStageId)
            }
          }
        });
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        form={form}
        visible={showModal}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <h2 className="mb-15">{title}</h2>
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          <Form.Item
            name="name"
            label="Name"
            rules={[
              required,
              characterWithoutWhiteSpace,
              {
                max: 250,
                message: 'Name cannot be more than 250 characters'
              }
            ]}
          >
            <Input allowClear placeholder="Enter Name" />
          </Form.Item>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
              loading={stageDuplicateLoading || loading}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default DuplicateEqcTypeModal;
