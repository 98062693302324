import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../common/utils';
import { RENAME_INSTRUCTION } from '../../../../graphql/Mutation';

const { required, characterWithoutWhiteSpace } = formValidatorRules;
const EditModal = (props) => {
  const {
    showModal,
    setShowModal,
    instructionData,
    setInstructionData,
    refetchInstructionData,
    uniqueCode
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const handleCancel = () => {
    setShowModal(false);
  };
  const { projectId } = useParams();
  const [form] = Form.useForm();

  const [renameInstruction] = useMutation(RENAME_INSTRUCTION);
  useEffect(() => {
    if (uniqueCode) {
      form.setFieldsValue({ uniqueCode });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uniqueCode]);
  const onFinish = async (formValues) => {
    try {
      const response = await renameInstruction({
        variables: {
          name: `${uniqueCode}/${formValues?.name}`,
          id: instructionData?.id
        }
      });
      if (response?.data?.renameInstruction) {
        setInstructionData();
        form.resetFields();
        Event(GA_EVENT.RENAME_PROJECT_INSTRUCTION, {
          label: GA_LABEL.RENAME_PROJECT_INSTRUCTION,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_instruction_id: instructionData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        setShowModal(false);
        refetchInstructionData();
      }
    } catch (error) {
      return error;
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      closable={false}
      footer={null}
    >
      <h2 className="mb-15">Rename Issue</h2>
      <Form layout="vertical" form={form} onFinish={onFinish}>
        <Form.Item name="uniqueCode" label="Unique Code">
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="name"
          label="Name"
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 250,
              message: 'Name cannot be more than 250 characters'
            }
          ]}
        >
          <Input allowClear />
        </Form.Item>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditModal;
