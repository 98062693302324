import { Button, Form, Modal } from 'antd';
import { filter, includes, keys, map } from 'lodash';
import React from 'react';
import { PROJECT_USER_ROLES } from '../../../common/constants';
import { formValidatorRules, titleCase } from '../../../common/utils';
import CommonSelect from '../../../components/CommonSelect';

const { required } = formValidatorRules;
const { Option } = CommonSelect;

const AddProjectUserModal = (props) => {
  const {
    showModal,
    setShowModal,
    data,
    handleModalSubmit,
    selectedUsers,
    onPopupScroll,
    onSearch,
    showSelectRole = false,
    setShowSelectRole,
    onClear,
    setUpdateData
  } = props;
  const [form] = Form.useForm();
  const handleCancel = () => {
    setShowModal(false);
    if (setShowSelectRole) {
      setShowSelectRole(false);
    }
    if (setUpdateData) {
      setUpdateData();
    }
    form.resetFields();
  };
  const submitForm = (values) => {
    handleModalSubmit(values);
    if (setShowSelectRole) {
      setShowSelectRole(false);
    }
    form.resetFields();
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      footer={null}
      closable={false}
    >
      <h2 className="mb-15">{showSelectRole ? `Select Role` : `Add User`}</h2>
      <Form form={form} layout="vertical" onFinish={submitForm}>
        {showSelectRole ? (
          <Form.Item name="role" label="Role" rules={[required]}>
            <CommonSelect allowClear>
              {map(keys(PROJECT_USER_ROLES), (role, roleIndex) => (
                <Option key={`role-${roleIndex}`} value={role}>
                  {titleCase(PROJECT_USER_ROLES[role])}
                </Option>
              ))}
            </CommonSelect>
          </Form.Item>
        ) : (
          <Form.Item name="user" label="User" rules={[required]}>
            <CommonSelect
              className="mr-3"
              placeholder="Select User"
              allowClear
              mode="multiple"
              onPopupScroll={onPopupScroll}
              onSearch={onSearch}
              onClear={onClear}
              onBlur={onClear}
              onChange={onClear}
              filterOption={false}
            >
              {map(
                filter(
                  data,
                  ({ id }) => !includes(map(selectedUsers, 'id'), id)
                ),
                (record) => (
                  <Option
                    key={`user-${record?.id}`}
                    value={JSON.stringify(record)}
                  >
                    {record?.name}
                  </Option>
                )
              )}
            </CommonSelect>
          </Form.Item>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            Add
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default AddProjectUserModal;
