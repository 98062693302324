/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import { Card, Col, message, Row, Space } from 'antd';
import { get, groupBy, includes, isArray, map, sortBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import api from '../../../common/api';
import {
  BREAKPOINTS,
  DASHBOARD_API_ROUTES,
  DEFAULTDATETIMEFORMATFORREPORTS,
  STAGE_STATUS
} from '../../../common/constants';
import CommonCard from '../../../components/CommonCard';
import CommonDropdown from '../../../components/CommonDropdown';
import CommonTable from '../../../components/CommonTable';
import { GET_DASHBOARD_AGENCY_DROPDOWN_LIST } from '../graphql/Queries';
import ChartRenderer from './ChartRenderer';
import CustomRangePicker from './CustomRangePicker';

const AgencyAnalysis = () => {
  const initialEqcFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [eqcFilter, setEqcFilter] = useState(initialEqcFilter);
  const [filterOption, setFilterOption] = useState('');
  const [agencyId, setAgencyId] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [graphData, setGraphData] = useState([]);
  const [loading, setLoading] = useState(false);
  const { getToken, getCurrentTenant } = useContext(AppContext);
  const authToken = getToken();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const tenantId = getCurrentTenant();
  const customHeaders = {
    Authorization: `Bearer ${authToken}`
  };
  if (tenantId) {
    customHeaders.tenantId = tenantId;
  }
  const getAgency = async () => {
    setLoading(true);
    const eqcTypeName = 'ProjectEqcType.name';
    const projectName = 'Project.name';
    const status = 'EqcStageHistory.status';
    const count = 'EqcStageHistory.count';
    const agencyIdLabel = 'Agency.id';
    const agencyName = 'Agency.name';

    let query = `${DASHBOARD_API_ROUTES.AGENCY_WISE_ANALYSIS}?agencyId=${agencyId}`;
    if (isArray(filterOption) && filterOption?.length === 2) {
      query = `${query}&startDate=${filterOption?.[0]}&endDate=${filterOption?.[1]}`;
    }
    api
      .get(query, {
        transformRequest: (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.common.Authorization;
          return data;
        },
        headers: customHeaders
      })
      .then((resultSet) => {
        const table = sortBy(get(resultSet, 'data.data'), [
          function (o) {
            return o['ProjectEqcType.name'];
          }
        ]);
        const data = [];

        const dataArray = [];

        for (let i = 0; i < table.length; i += 1) {
          const dataItem = [];

          dataItem.push(table[i]);

          while (
            i < table.length - 1 &&
            table[i][eqcTypeName] === table[i + 1][eqcTypeName]
          ) {
            i += 1;
            dataItem.push(table[i]);
          }

          dataArray.push(dataItem);
        }
        for (let k = 0; k < dataArray.length; k += 1) {
          let pass = 0;
          let fail = 0;
          let total = 0;
          map(dataArray[k], (value) => {
            if (value[status] === STAGE_STATUS.FAIL) {
              fail += Number(value[count]);
            }
            if (value[status] === STAGE_STATUS.PASS) {
              pass += Number(value[count]);
            }
            if (
              includes(
                [STAGE_STATUS.FAIL, STAGE_STATUS.PASS, STAGE_STATUS.BYPASS],
                value[status]
              )
            ) {
              total += Number(value[count]);
            }
          });
          const row = {
            id: dataArray[k][0][agencyIdLabel],
            projectName: dataArray[k][0][projectName],
            eqcTypeName: dataArray[k][0][eqcTypeName],
            agencyName: dataArray[k][0][agencyName],
            failCount: Number(fail),
            passCount: Number(pass),
            totalCount: Number(total)
          };
          if (row.id !== null) {
            data.push(row);
          }
        }
        const projectData = groupBy(data, function (b) {
          return b.projectName;
        });
        const eqcData = [];
        for (const property in projectData) {
          const eqcItemData = groupBy(projectData[property], function (b) {
            return b.eqcTypeName;
          });
          const eqcType = [];
          for (const item1 in eqcItemData) {
            let pass = 0;
            let fail = 0;
            let total = 0;
            for (const newItem of eqcItemData[item1]) {
              fail += newItem?.failCount;
              pass += newItem?.passCount;
              total += newItem?.totalCount;
            }
            eqcType.push({
              projectName: property,
              eqcTypeName: item1,
              failCount: Number(fail),
              passCount: Number(pass),
              totalCount: Number(total)
            });
          }
          eqcData.push(...eqcType);
        }
        setTableData(eqcData);
      })
      .catch((error) => {
        if (error?.response?.data?.error?.message) {
          message.destroy();
          message.error(error?.response?.data?.error?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getAgencyGraph = async () => {
    let query = `${DASHBOARD_API_ROUTES.AGENCY_WISE_ANALYSIS_GRAPH}?agencyId=${agencyId}`;
    if (isArray(filterOption) && filterOption?.length === 2) {
      query = `${query}&startDate=${filterOption?.[0]}&endDate=${filterOption?.[1]}`;
    }
    api
      .get(query, {
        transformRequest: (data, headers) => {
          // eslint-disable-next-line no-param-reassign
          delete headers.common.Authorization;
          return data;
        },
        headers: customHeaders
      })
      .then((resultSet) => {
        setGraphData(get(resultSet, 'data.data'));
      })
      .catch((error) => {
        if (error?.response?.data?.error?.message) {
          message.destroy();
          message.error(error?.response?.data?.error?.message);
        }
      });
  };
  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setEqcFilter({ ...eqcFilter, skip: skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
  };

  const columns = [
    {
      title: 'Project Name',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: (a, b) => a?.projectName?.localeCompare(b?.projectName),
      sortOrder: sortedInfo?.columnKey === 'projectName' && sortedInfo?.order
    },
    {
      title: 'Checklist',
      dataIndex: 'eqcTypeName',
      key: 'eqcTypeName',
      sorter: (a, b) => a?.eqcTypeName?.localeCompare(b?.eqcTypeName),
      sortOrder: sortedInfo?.columnKey === 'eqcTypeName' && sortedInfo?.order
    },
    {
      title: 'Total Inspection',
      dataIndex: 'totalCount',
      key: 'totalCount',
      sorter: (a, b) => a?.totalCount - b?.totalCount,
      sortOrder: sortedInfo?.columnKey === 'totalCount' && sortedInfo?.order
    },
    {
      title: 'Failed Inspection',
      dataIndex: 'failCount',
      key: 'failCount',
      sorter: (a, b) => a?.failCount - b?.failCount,
      sortOrder: sortedInfo?.columnKey === 'failCount' && sortedInfo?.order,
      render: (failCount, { totalCount }) =>
        `${failCount} (${parseInt((failCount / totalCount) * 100, 10) || 0}%)`
    }
  ];

  useEffect(() => {
    if (agencyId) {
      getAgency();
      getAgencyGraph();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agencyId, filterOption]);

  const FilterView = useCallback(
    ({ agencyId: aId, currentFilter }) => {
      const handleChange = (value) => {
        setAgencyId(value);
      };

      const callback = (res) => {
        if (!aId && res?.dashboardProjectAgencyList?.data?.length) {
          setAgencyId(res?.dashboardProjectAgencyList?.data?.[0]?.id);
        }
      };

      return isDesktopViewport ? (
        <Space size={16}>
          <div className="filter-wrapper">
            <CommonDropdown
              placeholder="Select Agency"
              className="width-200"
              value={aId}
              onChange={handleChange}
              query={GET_DASHBOARD_AGENCY_DROPDOWN_LIST}
              responsePath="dashboardProjectAgencyList.data"
              valuePath="id"
              labelPath="name"
              optionKey="agency-analysis"
              callback={callback}
              showSearch
              optionFilterProp="children"
              fetchPolicy="network-only"
            />
          </div>
          <CustomRangePicker
            setDateSelected={(date) => {
              if (date?.length > 0) {
                setFilterOption([
                  moment(date?.[0]).format(DEFAULTDATETIMEFORMATFORREPORTS),
                  moment(date?.[1]).format(DEFAULTDATETIMEFORMATFORREPORTS)
                ]);
              } else {
                setFilterOption('');
              }
            }}
            selectedDates={currentFilter}
          />
        </Space>
      ) : (
        <>
          <div className="filter-wrapper">
            <CommonDropdown
              placeholder="Select Agency"
              className="width-percent-100 mb-15"
              value={aId}
              onChange={handleChange}
              query={GET_DASHBOARD_AGENCY_DROPDOWN_LIST}
              responsePath="dashboardProjectAgencyList.data"
              valuePath="id"
              labelPath="name"
              optionKey="agency-analysis"
              callback={callback}
              showSearch
              optionFilterProp="children"
              fetchPolicy="network-only"
            />
          </div>
          <CustomRangePicker
            className="width-percent-100"
            setDateSelected={(date) => {
              if (date?.length > 0) {
                setFilterOption([
                  moment(date?.[0]).format(DEFAULTDATETIMEFORMATFORREPORTS),
                  moment(date?.[1]).format(DEFAULTDATETIMEFORMATFORREPORTS)
                ]);
              } else {
                setFilterOption('');
              }
            }}
            selectedDates={currentFilter}
          />
        </>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  return isDesktopViewport ? (
    <Card>
      <div className="d-flex align-center justify-between mb-15">
        <h1>Agency Analysis</h1>
        <FilterView agencyId={agencyId} currentFilter={filterOption} />
      </div>
      <Row>
        <Col span={14}>
          <CommonTable
            columns={columns}
            data={tableData}
            onChange={handleTableChange}
            paginationConfig={{ ...paginationProp, total: tableData?.length }}
            rowKey={(obj, index) => index}
            loading={loading}
          />
        </Col>
        <Col span={9} offset={1}>
          {graphData?.length ? <ChartRenderer resultSet={graphData} /> : null}
        </Col>
      </Row>
    </Card>
  ) : (
    <div className="common-card-wrapper">
      <div className="mb-15">
        <FilterView agencyId={agencyId} currentFilter={filterOption} />
      </div>
      <Row gutter={[0, 16]}>
        <Col span={24}>
          <CommonTable
            columns={columns}
            data={tableData}
            onChange={handleTableChange}
            paginationConfig={{ ...paginationProp, total: tableData?.length }}
            rowKey={(obj) => obj?.id}
            loading={loading}
          />
        </Col>
        {graphData?.length ? (
          <Col span={24}>
            <CommonCard className="width-percent-100">
              <ChartRenderer
                resultSet={graphData}
                currentFilter={filterOption}
              />
            </CommonCard>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default AgencyAnalysis;
