import { Popover, Tabs, Tag } from 'antd';
import { filter } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../AppContext';
import { InfoIconDark } from '../../assets/svg';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  BREAKPOINTS,
  LOGS_TABS_KEYS,
  ROUTES,
  SYSTEM_ROLES
} from '../../common/constants';
import CanPerform from '../../components/CanPerform';
import HasAccess from '../../components/HasAccess';
import Activity from './pages/Activity';
import Gallery from './pages/Gallery';
import Instruction from './pages/Instructions';

const Logs = () => {
  const { TabPane } = Tabs;
  const { tab } = useParams();
  const history = useHistory();
  const [activeKey, setActiveKey] = useState(LOGS_TABS_KEYS.ACTIVITY);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const {
    state: {
      currentUser: { projectUsers }
    }
  } = useContext(AppContext);
  const isProjectAdmin = !!filter(
    projectUsers,
    (user) => user?.roles === SYSTEM_ROLES.PROJECT_ADMIN
  ).length;

  useEffect(() => {
    if (tab) {
      setActiveKey(tab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Activity Details</div>
        </>
      )}
      <div className="logs">
        <Tabs
          activeKey={activeKey}
          destroyInactiveTabPane
          onChange={(key) => {
            setActiveKey(key);
            history.push(`${ROUTES.LOGS}/${key}`);
          }}
        >
          {HasAccess({ type: ACCESS_TYPE.INSPECTION }) && (
            <TabPane
              tab="Eqc Logs"
              key={LOGS_TABS_KEYS.ACTIVITY}
              className="details-tab"
            >
              <div className="activity">
                <Activity />
              </div>
            </TabPane>
          )}
          {HasAccess({ type: ACCESS_TYPE.INSTRUCTION }) && (
            <TabPane tab="Issue Logs" key={LOGS_TABS_KEYS.INSTRUCTION}>
              <div className="instruction">
                <Instruction />
              </div>
            </TabPane>
          )}
          {(CanPerform({
            action: ALLOWED_ACTION_KEYS.VIEW_GALLERY
          }) ||
            isProjectAdmin) && (
            <TabPane
              tab={
                <div className="d-flex align-center">
                  Gallery
                  <Tag className="tag-beta ml-5" color="#202A3F">
                    BETA
                  </Tag>
                  <Popover
                    placement={isDesktopViewport ? 'right' : 'bottom'}
                    content={
                      <>
                        This is beta feature and may have bug and errors. This
                        feature can be stopped without any prior notice.
                      </>
                    }
                    overlayClassName="gallery-info"
                    trigger={[!isDesktopViewport ? 'click' : 'hover']}
                  >
                    <InfoIconDark height="18px" width="18px" />
                  </Popover>
                </div>
              }
              key={LOGS_TABS_KEYS.GALLERY}
            >
              <div className="instruction">
                <Gallery />
              </div>
            </TabPane>
          )}
        </Tabs>
      </div>
    </>
  );
};

export default Logs;
