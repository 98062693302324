import { Button, Modal } from 'antd';
import clsx from 'clsx';
import React from 'react';
import { WrongIcon } from '../../../../../../assets/svg';

const RfiActivationRequiredModal = ({
  showModal,
  setShowModal,
  onOk,
  projectSetupRequired = false,
  eqcTypeData = {}
}) => {
  const handleCancel = () => {
    setShowModal(false);
  };
  return (
    <div id="upgrade-modal">
      <Modal
        maskClosable={false}
        centered
        open={showModal}
        footer={null}
        width={320}
        onCancel={handleCancel}
        closeIcon={<WrongIcon />}
      >
        <div>
          <h2>{projectSetupRequired ? `RFI Setup Pending` : `Activate RFI`}</h2>
          <p>
            {projectSetupRequired
              ? 'The RFI setup is pending for the project. Please setup to enable for checklist stage.'
              : `RFI is currently disabled for this project. Please click the "Turn On" button to activate it`}
          </p>
          <div className="form-buttons">
            <Button
              shape="round"
              className={clsx(
                'cancel-button',
                !eqcTypeData?.projectEqcType?.status && 'mr-10'
              )}
              onClick={() => setShowModal(false)}
            >
              {projectSetupRequired ? 'Cancel' : `Not Now`}
            </Button>
            {!eqcTypeData?.projectEqcType?.status && (
              <Button
                shape="round"
                type="primary"
                className="save-button"
                onClick={() => onOk()}
              >
                {projectSetupRequired ? 'Setup Now' : `Turn On`}
              </Button>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default RfiActivationRequiredModal;
