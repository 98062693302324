import { useMutation } from '@apollo/client';
import { Button, Form, Input, Spin } from 'antd';
import React, { useState } from 'react';
import { Password } from '../../assets/svg/login';
import { GA_EVENT, GA_LABEL, REGEX, ROUTES } from '../../common/constants';
import { Event } from '../../common/trackEvents';
import {
  checkPasswordCharacters,
  formValidatorRules
} from '../../common/utils';
import CheckPassword from './components/CheckPassword';
import { SET_PASSWORD } from './graphql/Mutations';

const { required, password: passwordRule } = formValidatorRules;

const SetPassword = (props) => {
  const { history, history: { location: { search } = {} } = {} } = props;
  const [selectedKey, setSelectedKey] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const getQuery = () => {
    return new URLSearchParams(search);
  };
  const id = getQuery().get('uid');
  const resetToken = getQuery().get('token');

  const [setUserPassword, { loading }] = useMutation(SET_PASSWORD, {
    onCompleted() {
      Event(GA_EVENT.SET_PASSWORD, {
        label: GA_LABEL.SET_PASSWORD,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        user_id: id
      });
      history.replace(ROUTES.LOGIN);
    },
    onError() {}
  });

  const onFinish = ({ password }) => {
    setUserPassword({
      variables: {
        data: { password: password, uId: Number(id), token: resetToken }
      },
      context: {
        headers: {
          'x-token': resetToken
        }
      }
    })
      .then(() => {
        history.replace(ROUTES.LOGIN);
      })
      .catch((e) => {
        return e;
      });
  };
  const handleChange = (e) => {
    setSelectedKey(checkPasswordCharacters(e?.target?.value));
  };

  const handleFocus = () => {
    setIsVisible(true);
  };
  const handleBlur = (e) => {
    if (REGEX.PASSWORD.test(e?.target?.value)) {
      setIsVisible(false);
    }
  };
  return (
    <div className="gx-login-container">
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h2>Set Password</h2>
          <p>Enter password for your account</p>
        </div>
        <Spin spinning={false}>
          <Form
            name="change-password"
            layout="vertical"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            className="gx-login-form gx-form-row0 set-password-form"
          >
            <Form.Item
              name="password"
              label="New Password"
              rules={[required, passwordRule]}
              dependencies={['retype-password']}
              className="password-input"
            >
              <Input.Password
                addonBefore={<Password />}
                placeholder="Password"
                onChange={handleChange}
                onFocus={handleFocus}
                onBlur={(e) => handleBlur(e)}
              />
            </Form.Item>
            <CheckPassword selectedKey={selectedKey} isVisible={isVisible} />
            <Form.Item
              name="retype-password"
              label="Confirm Password"
              rules={[
                required,
                passwordRule,
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      getFieldValue('password') &&
                      (!value || value !== getFieldValue('password'))
                    ) {
                      return Promise.reject(
                        new Error('Passwords do not match')
                      );
                    }
                    return Promise.resolve();
                  }
                })
              ]}
              dependencies={['password']}
            >
              <Input.Password
                addonBefore={<Password />}
                placeholder="Retype password"
              />
            </Form.Item>
            <div className="form-buttons d-flex">
              <Form.Item>
                <Button
                  className="cancel-button mr-10"
                  onClick={() => {
                    history.push(ROUTES.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  shape="round"
                  className="save-button"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default SetPassword;
