import { useLazyQuery } from '@apollo/client';
import { Checkbox, Col, Popover, Row, Tag, Tooltip } from 'antd';
import clsx from 'clsx';
import { filter, forEach, includes, map, nth, slice, values } from 'lodash';
import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { InfoIcon, Timezone } from '../../../assets/svg';
import {
  BREAKPOINTS,
  DATETIMEWITHDIVIDE,
  DEFAULT_PAGE_SIZE,
  EQC_STATUS_KEYS,
  ROUTES,
  STAGE_STATUS,
  STAGE_STATUS_KEYS,
  TAB_KEYS
} from '../../../common/constants';
import { removeHistoryStateData, titleCase } from '../../../common/utils';
import CollapsibleFilterWrapper from '../../../components/CollapsibleFilterWrapper';
import CommonCard from '../../../components/CommonCard';
import CommonDropdown from '../../../components/CommonDropdown';
import CommonSelect from '../../../components/CommonSelect';
import CommonTable from '../../../components/CommonTable';
import EllipsisText from '../../../components/EllipsisText';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../components/SearchComponent';
import CustomRangePicker from '../../dashboard/components/CustomRangePicker';
import { GET_PROJECT_DROPDOWN_LIST } from '../../projects/graphql/Queries';
import { COMMON_PROJECT_EQC_TYPE_DROP_DOWN_LIST } from '../../report/graphql/Queries';
import { GET_USERS_DROPDOWN_LIST } from '../../users/graphql/Queries';
import { ACTIVITY_LOGS } from '../graphql/Queries';

const { Option } = CommonSelect;

const Activity = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [sortedInfo, setSortedInfo] = useState({});
  const [scrollFlag, setScrollFlag] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [activityData, setActivityData] = useState([]);
  const [selectedDates, setSelectedDates] = useState('');
  const [shouldProjectSelect, setShouldProjectSelect] = useState(false);
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const eqcFilter = history?.location?.state?.eqcFilter;
  const eqcPagination = history?.location?.state?.eqcPagination;
  const userRecord = history?.location?.state?.userRecord;
  const projectRecord = history?.location?.state?.projectRecord;

  const initialActivityLogFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' },
    isRfi: false
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(
    eqcPagination || initialPaginationValue
  );
  const [activityLogFilter, setActivityLogFilter] = useState(
    eqcFilter || initialActivityLogFilter
  );
  const [userId, setUserId] = useState(eqcFilter?.userId);
  const [projectId, setProjectId] = useState(eqcFilter?.projectId);
  const [selectedUserRecord, setSelectedUserRecord] = useState();
  const [selectedProjectRecord, setSelectedProjectRecord] = useState();
  const [eqcType, setEqcType] = useState(eqcFilter?.eqcType);
  const [eqcStatus, setEqcStatus] = useState(eqcFilter?.eqcStatus);
  const [eqcStageStatus, setEqcStageStatus] = useState(
    eqcFilter?.eqcStageStatus
  );
  const [latest, setLatest] = useState(eqcFilter?.latest);
  const [isRfi, setIsRfi] = useState(eqcFilter?.isRfi);

  useEffect(() => {
    if (shouldProjectSelect) {
      setTimeout(() => {
        setShouldProjectSelect(false);
      }, 5000);
    }
  }, [shouldProjectSelect]);

  useEffect(() => {
    if (eqcFilter) {
      setActivityLogFilter(eqcFilter);
      setLatest(eqcFilter?.latest);
      setIsRfi(eqcFilter?.isRfi);
      setEqcType(eqcFilter?.eqcType);
      setEqcStatus(eqcFilter?.eqcStatus);
      setUserId(eqcFilter?.userId);
      setProjectId(eqcFilter?.projectId);
      setEqcStageStatus(eqcFilter?.eqcStageStatus);
      if (eqcFilter?.fromDate && eqcFilter?.toDate) {
        setSelectedDates([eqcFilter?.fromDate, eqcFilter?.toDate]);
      }
    }
    if (eqcPagination) {
      setPaginationProp(eqcPagination);
    }
    if (userRecord) {
      setSelectedUserRecord(userRecord);
    }
    if (projectRecord) {
      setSelectedProjectRecord(projectRecord);
    }
  }, [eqcFilter, eqcPagination, userRecord, projectRecord]);

  const [fetchActivityLogs, { loading }] = useLazyQuery(ACTIVITY_LOGS, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.eqcActivityLogList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.eqcActivityLogList?.total
      };
      if (scrollFlag) {
        const datalist = [...activityData, ...data];
        setActivityData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setActivityData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {}
  });

  useEffect(() => {
    fetchActivityLogs({ variables: { filter: { ...activityLogFilter } } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activityLogFilter]);

  useEffect(() => {
    const newFilter = {
      ...activityLogFilter,
      projectId,
      eqcStageStatus,
      eqcType,
      eqcStatus,
      userId,
      skip: activityLogFilter?.skip !== 0 ? activityLogFilter?.skip : 0,
      latest,
      fromDate: selectedDates?.[0] && moment(selectedDates?.[0]).startOf('day'),
      toDate: selectedDates?.[1] && moment(selectedDates?.[1]).endOf('day')
    };
    setPaginationProp({
      ...paginationProp,
      current: paginationProp?.current !== 1 ? paginationProp?.current : 1
    });
    setActivityLogFilter(newFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    eqcStageStatus,
    eqcType,
    eqcStatus,
    userId,
    projectId,
    selectedDates,
    latest
  ]);

  const activityLogsData = async (value) => {
    if (!value) {
      removeHistoryStateData(history, history?.location, 'eqcFilter', 'search');
    }
    setActivityLogFilter({
      ...activityLogFilter,
      skip: 0,
      limit: paginationProp?.pageSize,
      search: value
    });
    setPaginationProp(initialPaginationValue);
  };

  const handleTableChange = (pagination, filterData, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setActivityLogFilter({
        ...activityLogFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.field,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
    } else {
      setActivityLogFilter({
        ...activityLogFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });
    }
  };

  const ChangeEqcStatus = ({ record }) => {
    if (record?.eqcStatus === 'PASSED') {
      return (
        <Tag className="active-tag">{titleCase(EQC_STATUS_KEYS.PASSED)}</Tag>
      );
    }
    if (record?.eqcStatus === 'IN_PROGRESS') {
      return (
        <Tag className="yellow-tag">
          {titleCase(EQC_STATUS_KEYS.IN_PROGRESS)}
        </Tag>
      );
    }
    return <Tag color="red">{titleCase(EQC_STATUS_KEYS.TERMINATE)}</Tag>;
  };

  const ChangeStageStatus = ({ record }) => {
    switch (record?.eqcStageStatus) {
      case STAGE_STATUS.PASS:
        return <Tag className="active-tag">{titleCase(STAGE_STATUS.PASS)}</Tag>;
      case STAGE_STATUS.APPROVED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.APPROVED)}</Tag>
        );
      case STAGE_STATUS.ACCEPTED:
        return (
          <Tag className="active-tag">{titleCase(STAGE_STATUS.ACCEPTED)}</Tag>
        );
      case STAGE_STATUS.APPROVAL_PENDING:
        return (
          <Tag className="yellow-tag">
            {titleCase(STAGE_STATUS.APPROVAL_PENDING)}
          </Tag>
        );
      case STAGE_STATUS.SKIP:
        return <Tag className="yellow-tag">{titleCase(STAGE_STATUS.SKIP)}</Tag>;
      case STAGE_STATUS.REDO:
        return <Tag color="red">{titleCase(STAGE_STATUS.REDO)}</Tag>;
      case STAGE_STATUS.REJECTED:
        return <Tag color="red">{titleCase(STAGE_STATUS.REJECTED)}</Tag>;
      case STAGE_STATUS.BYPASS:
        return (
          <Tag className="inactive-tag">{titleCase(STAGE_STATUS.BYPASS)}</Tag>
        );
      default:
        return <Tag color="red">{titleCase(STAGE_STATUS.FAIL)}</Tag>;
    }
  };

  const handleRefetch = () => {
    fetchActivityLogs({
      variables: {
        filter: {
          ...activityLogFilter,
          skip: activityData?.length,
          limit: DEFAULT_PAGE_SIZE,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const getApproverName = (record) => {
    const fetchName = [];
    const {
      eqcStageStatus: stageStatus,
      eqcStageApprovals,
      externalApproverName,
      internalApproverName
    } = record;

    if (stageStatus === STAGE_STATUS.APPROVAL_PENDING) {
      forEach(
        filter(
          eqcStageApprovals,
          (data) => data?.levelNumber === record?.levelNumber
        ),
        (approver) => {
          const {
            externalApproverName: extApprName,
            internalApproverName: intApprName
          } = approver;
          if (intApprName || extApprName) {
            fetchName.push(intApprName || extApprName);
          }
        }
      );
    } else if (internalApproverName || externalApproverName) {
      fetchName.push(internalApproverName || externalApproverName);
    }
    const selectedName = nth(fetchName, 0);
    if (fetchName?.length === 1) {
      return (
        <Tooltip title={selectedName} placement="right">
          {selectedName}
        </Tooltip>
      );
    }
    if (fetchName?.length > 1) {
      return (
        <Tooltip
          title={slice(fetchName, 1, fetchName?.length).join(', ')}
          placement="right"
        >
          {`${selectedName} and +${fetchName?.length - 1}`}
        </Tooltip>
      );
    }
    return '-';
  };

  const getDateHeader = () => {
    return (
      <Popover
        destroyTooltipOnHide
        getPopupContainer={() =>
          // eslint-disable-next-line no-undef
          document.querySelector('.logs')
        }
        placement="bottom"
        overlayClassName="date-header-popover"
        content={
          <div
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
            }}
          >
            <h5>This will show date and time of EQC stage when synced</h5>
          </div>
        }
      >
        <InfoIcon height={16} width={16} className="info-icon ml-5" />
      </Popover>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{activityLogFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'PROJECT',
      dataIndex: 'projectName',
      key: 'projectName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'projectName' && sortedInfo?.order,
      render: (text) => (
        <div>
          <EllipsisText text={text} />
        </div>
      )
    },
    {
      title: 'CHECKLIST',
      dataIndex: 'eqcType',
      key: 'eqcType',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'eqcType' && sortedInfo?.order,
      render: (text) => (
        <div>
          <EllipsisText text={text} />
        </div>
      )
    },
    {
      title: 'STAGE',
      dataIndex: 'eqcStageName',
      key: 'eqcStageName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'eqcStageName' && sortedInfo?.order,
      render: (text) => (
        <div>
          <EllipsisText text={text} />
        </div>
      )
    },
    {
      title: 'EQC',
      dataIndex: 'eqcName',
      key: 'eqcName',
      render: (text, record) => (
        <div className="text-primary">{record?.eqcName}</div>
      )
    },
    {
      title: 'EQC STATUS',
      key: 'eqcStatus',
      render: (text, record) => {
        return <ChangeEqcStatus record={record} />;
      }
    },
    {
      title: 'STAGE STATUS',
      key: 'eqcStageStatus',
      render: (text, record) => {
        return (
          <>
            {includes(
              [
                STAGE_STATUS?.APPROVAL_PENDING,
                STAGE_STATUS?.APPROVED,
                STAGE_STATUS?.REDO
              ],
              record?.eqcStageStatus
            ) && <div className="mb-5">{record?.levelName}</div>}
            <ChangeStageStatus record={record} />
          </>
        );
      }
    },
    {
      title: 'APPROVER',
      key: 'approver',
      render: (approver, record) => getApproverName(record)
    },
    {
      title: 'USER',
      dataIndex: 'creatorName',
      key: 'creatorName',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'creatorName' && sortedInfo?.order
    },
    {
      title: (
        <div className="d-flex align-center tags-col">
          DATE
          {getDateHeader()}
        </div>
      ),
      key: 'createdAt',
      dataIndex: 'createdAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'createdAt' && sortedInfo?.order,
      render: (dueDate, record) => {
        return record?.createdAt ? (
          <div>
            {moment(`${record?.createdAt}`)
              .tz(record?.timeZone)
              .format(DATETIMEWITHDIVIDE)}
            <div className="d-flex align-center timezone-div">
              <Timezone title="timeZone" />
              {record?.timeZone}
            </div>
          </div>
        ) : (
          '-'
        );
      }
    }
  ];

  const handleRowClick = (record) => {
    if (record?.projectId && record?.eqcId) {
      history.push(
        `${ROUTES.PROJECTS}/${record?.projectId}/${TAB_KEYS.EQC}/${record?.eqcId}`,
        {
          navFilter,
          navPagination,
          eqcFilter: JSON.stringify(activityLogFilter),
          eqcPagination: paginationProp,
          userRecord: selectedUserRecord,
          projectRecord: selectedProjectRecord,
          currentPath: history?.location?.pathname,
          stageId: record?.stageId
        }
      );
    }
  };
  return (
    <div>
      <div className="d-flex justify-between">
        <div>{isDesktopViewport && <h1>Activity Details</h1>}</div>
        <div
          className={isDesktopViewport ? 'logs-filter' : 'width-percent-100'}
        >
          <CollapsibleFilterWrapper
            searchProps={{
              className: 'search-component',
              getData: activityLogsData,
              defaultValue: eqcFilter?.search
            }}
            className="mb-15"
          >
            <Row
              className={`d-flex filter-search align-center ${
                isDesktopViewport ? 'justify-end' : ''
              }`}
              wrap
              gutter={isDesktopViewport ? [10, 10] : [0, 10]}
            >
              <Col span={isDesktopViewport ? null : 12}>
                <Checkbox
                  onChange={(event) => {
                    setIsRfi(event?.target?.checked);
                    setActivityLogFilter({
                      ...activityLogFilter,
                      skip: 0,
                      isRfi: event?.target?.checked
                    });
                    setPaginationProp({ ...paginationProp, current: 1 });
                  }}
                  checked={isRfi}
                >
                  RFI
                </Checkbox>
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <Checkbox
                  onChange={(event) => {
                    setLatest(event?.target?.checked);
                    setActivityLogFilter({ ...activityLogFilter, skip: 0 });
                    setPaginationProp({ ...paginationProp, current: 1 });
                  }}
                  checked={latest}
                >
                  Latest
                </Checkbox>
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="Project"
                  className={clsx(
                    `instruction-selector dropdown-width-auto`,
                    isDesktopViewport ? 'width-200' : 'width-percent-100',
                    !projectId &&
                      shouldProjectSelect &&
                      'blinking-dropdown-border'
                  )}
                  onChange={(id, record) => {
                    setProjectId(id);
                    setSelectedProjectRecord(record);
                    setActivityLogFilter({ ...activityLogFilter, skip: 0 });
                    setPaginationProp({ ...paginationProp, current: 1 });
                    setEqcType();
                    setShouldProjectSelect(false);
                    if (!id) {
                      removeHistoryStateData(
                        history,
                        history?.location,
                        'eqcFilter',
                        'projectId'
                      );
                    }
                  }}
                  value={projectId}
                  query={GET_PROJECT_DROPDOWN_LIST}
                  fetchPolicy="network-only"
                  responsePath="projectDropdownList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="project"
                  showSearch
                  optionFilterProp="children"
                  customOptions={selectedProjectRecord}
                  dropdownMatchSelectWidth={false}
                />
              </Col>
              <Col
                span={isDesktopViewport ? null : 12}
                onClick={() => {
                  setShouldProjectSelect(true);
                }}
                className={!projectId && 'cursor-disabled '}
              >
                <CommonDropdown
                  allowClear
                  showSearch
                  placeholder="Checklist"
                  optionFilterProp="children"
                  className={clsx(
                    `instruction-selector dropdown-width-auto`,
                    isDesktopViewport ? 'width-200' : 'width-percent-100',
                    !projectId && 'pointerEvents-none'
                  )}
                  disabled={!projectId}
                  onChange={(type) => {
                    setEqcType(type);
                    setActivityLogFilter({ ...activityLogFilter, skip: 0 });
                    setPaginationProp({ ...paginationProp, current: 1 });
                  }}
                  query={COMMON_PROJECT_EQC_TYPE_DROP_DOWN_LIST}
                  variables={{
                    filter: {
                      projectId: Number(projectId)
                    }
                  }}
                  fetchPolicy="network-only"
                  responsePath="commonProjectEqcTypeDropdownList.data"
                  valuePath="name"
                  labelPath="name"
                  optionKey="project-eqc-types"
                  useEffectDeps={[projectId]}
                  conditionToCheckBeforeQuery={!!projectId}
                  projectId={projectId}
                  value={eqcType}
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonDropdown
                  allowClear
                  placeholder="User"
                  className={`instruction-selector dropdown-width-auto ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  onChange={(id, record) => {
                    setUserId(id);
                    setSelectedUserRecord(record);
                    setActivityLogFilter({ ...activityLogFilter, skip: 0 });
                    setPaginationProp({ ...paginationProp, current: 1 });
                    if (!id) {
                      removeHistoryStateData(
                        history,
                        history?.location,
                        'eqcFilter',
                        'userId'
                      );
                    }
                  }}
                  query={GET_USERS_DROPDOWN_LIST}
                  value={userId}
                  fetchPolicy="network-only"
                  responsePath="userDropdownList.data"
                  valuePath="id"
                  labelPath="name"
                  optionKey="user"
                  showSearch
                  optionFilterProp="children"
                  dropdownMatchSelectWidth={false}
                  customOptions={selectedUserRecord}
                  placement="bottomRight"
                />
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonSelect
                  allowClear
                  placeholder="Stage Status"
                  className={`instruction-selector ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={eqcStageStatus}
                  onChange={(status) => {
                    setEqcStageStatus(status);
                    setActivityLogFilter({ ...activityLogFilter, skip: 0 });
                    setPaginationProp({ ...paginationProp, current: 1 });
                    if (!status) {
                      removeHistoryStateData(
                        history,
                        history?.location,
                        'eqcFilter',
                        'eqcStageStatus'
                      );
                    }
                  }}
                >
                  {values(STAGE_STATUS_KEYS).map((stageStatus) => (
                    <Option key={stageStatus} value={stageStatus}>
                      {titleCase(stageStatus)}
                    </Option>
                  ))}
                </CommonSelect>
              </Col>
              <Col span={isDesktopViewport ? null : 12}>
                <CommonSelect
                  allowClear
                  placeholder="Eqc Status"
                  className={`instruction-selector ${
                    isDesktopViewport ? 'width-200' : 'width-percent-100'
                  } `}
                  value={eqcStatus}
                  onChange={(status) => {
                    setEqcStatus(status);
                    setActivityLogFilter({ ...activityLogFilter, skip: 0 });
                    setPaginationProp({ ...paginationProp, current: 1 });
                    if (!status) {
                      removeHistoryStateData(
                        history,
                        history?.location,
                        'eqcFilter',
                        'eqcStatus'
                      );
                    }
                  }}
                >
                  {values(EQC_STATUS_KEYS).map((eqcStatusText) => (
                    <Option key={eqcStatusText} value={eqcStatusText}>
                      {titleCase(eqcStatusText)}
                    </Option>
                  ))}
                </CommonSelect>
              </Col>
              {isDesktopViewport && (
                <Col>
                  <SearchComponent
                    id="search-container-id"
                    getData={activityLogsData}
                    defaultValue={eqcFilter?.search}
                    className="search-component width-200"
                  />
                </Col>
              )}
              <Col span={isDesktopViewport ? null : 12}>
                <CustomRangePicker
                  className={clsx(!isDesktopViewport && 'width-percent-100')}
                  setDateSelected={(data) => {
                    setSelectedDates(data);
                    setActivityLogFilter({ ...activityLogFilter, skip: 0 });
                    setPaginationProp({ ...paginationProp, current: 1 });
                  }}
                  selectedDates={selectedDates}
                  handleClear={() => {
                    setActivityLogFilter({ ...activityLogFilter, skip: 0 });
                    setPaginationProp({ ...paginationProp, current: 1 });
                    removeHistoryStateData(
                      history,
                      history?.location,
                      'eqcFilter',
                      'fromDate'
                    );
                    removeHistoryStateData(
                      history,
                      history?.location,
                      'eqcFilter',
                      'toDate'
                    );
                  }}
                />
              </Col>
            </Row>
          </CollapsibleFilterWrapper>
        </div>
      </div>

      {isDesktopViewport ? (
        <div className="table">
          <CommonTable
            loadingData={loading}
            columns={columns}
            data={activityData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowClassName="pointer"
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record)
              };
            }}
          />
        </div>
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="activity-scroll-wrapper"
          dataLength={activityData?.length}
          skeletonRows={columns?.length - 2}
        >
          {map(activityData, (activity, index) => {
            return (
              <CommonCard key={activity?.id} onClick={handleRowClick}>
                <div className="common-card d-flex">
                  <div className="mr-5 fw-medium">{index + 1}.</div>
                  <div>
                    <div className="card-header fw-medium">
                      <span className="text-break">
                        {activity?.projectName}
                      </span>
                    </div>
                    <div className="card-content text-secondary">
                      <br />
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Checklist:</span>
                        <span className="text-break">{activity?.eqcType}</span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Stage:</span>
                        <span className="text-break">
                          {activity?.eqcStageName}
                        </span>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">EQC:</span>{' '}
                        <NavLink
                          className="text-primary text-underline pointer text-break"
                          to={`${ROUTES.PROJECTS}/${activity?.projectId}/${TAB_KEYS.EQC}/${activity?.eqcId}`}
                        >
                          {activity?.eqcName}
                        </NavLink>
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">EQC Status:</span>
                        <ChangeEqcStatus record={activity} />
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">STAGE Status:</span>
                        <span className="mr-5">{activity?.levelName}</span>
                        <ChangeStageStatus record={activity} />
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">Approver:</span>
                        {getApproverName(activity)}
                      </div>
                      <div className="mb-15">
                        <span className="fw-medium mr-5">User:</span>
                        {activity?.creatorName}
                      </div>
                      <div>
                        <span className="d-flex align-center fw-medium mr-5 tags-col">
                          Date {getDateHeader()}&nbsp;:
                        </span>
                        {activity?.createdAt ? (
                          <div>
                            {moment(`${activity?.createdAt}`)
                              .tz(activity?.timeZone)
                              .format(DATETIMEWITHDIVIDE)}
                            <div className="d-flex align-center timezone-div">
                              <Timezone title="timeZone" />
                              {activity?.timeZone}
                            </div>
                          </div>
                        ) : (
                          '-'
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </CommonCard>
            );
          })}
        </InfiniteScrollHandler>
      )}
    </div>
  );
};

export default Activity;
