import { useLazyQuery } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import clsx from 'clsx';
import { map, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import { ForwardArrow } from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  DEFAULT_PAGE_SIZE,
  ROUTES
} from '../../../../../../common/constants';
import CanPerform from '../../../../../../components/CanPerform';
import CommonCard from '../../../../../../components/CommonCard';
import CommonTable from '../../../../../../components/CommonTable';
import InfiniteScrollHandler from '../../../../../../components/InfiniteScrollHandler';
import SearchComponent from '../../../../../../components/SearchComponent';
import {
  GET_UNASSIGN_AGENCY_LIST,
  GET_UNASSIGN_CONTACT_LIST
} from '../../../../graphql/Queries';
import AssignAgencyModal from './AssignAgencyModal';

const UnassignAgency = ({ isGlobal = false }) => {
  const { projectId } = useParams();
  const history = useHistory();
  const initialProjectAgencyFilter = {
    skip: 0,
    limit: 10,
    projectId,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };
  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };
  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [projectAgencyFilter, setProjectAgencyFilter] = useState(
    initialProjectAgencyFilter
  );
  const [showModal, setShowModal] = useState(false);
  const [sortedInfo, setSortedInfo] = useState({});
  const [agencyData, setAgencyData] = useState();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [agencyListData, setAgencyListData] = useState();
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [fetchProjectAgency, { loading }] = useLazyQuery(
    GET_UNASSIGN_AGENCY_LIST,
    {
      variables: {
        filter: projectAgencyFilter
      },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.unassignAgencyContactList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.unassignAgencyContactList?.total
        };
        if (scrollFlag) {
          const datalist = [...agencyListData, ...data];
          setAgencyListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setAgencyListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );
  const [fetchAgencyContact, { loading: globalContactLoading }] = useLazyQuery(
    GET_UNASSIGN_CONTACT_LIST,
    {
      variables: {
        filter: omit(projectAgencyFilter, 'projectId')
      },
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        const data = res?.unassignContactList?.data;
        const pagination = {
          ...paginationProp,
          total: res?.unassignContactList?.total
        };
        if (scrollFlag) {
          const datalist = [...agencyListData, ...data];
          setAgencyListData(datalist);
          setScrollFlag(false);
        } else {
          const datalist = [...data];
          setAgencyListData(datalist);
        }
        setHasMore(!!data?.length);
        setPaginationProp(pagination);
      },
      onError() {}
    }
  );

  useEffect(() => {
    if (isGlobal) {
      fetchAgencyContact({ variables: { filter: projectAgencyFilter } });
    } else {
      fetchProjectAgency({ variables: { filter: projectAgencyFilter } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchAgencyDetails = () => {
    setPaginationProp(initialPaginationValue);
    setProjectAgencyFilter(initialProjectAgencyFilter);
    if (isGlobal) {
      fetchAgencyContact({
        variables: { filter: { ...initialProjectAgencyFilter } }
      });
    } else {
      fetchProjectAgency({
        variables: { filter: { ...initialProjectAgencyFilter } }
      });
    }
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setPaginationProp({ ...paginationProp, ...pagination });
    setSortedInfo(sorter);
    if (sorter?.column) {
      setProjectAgencyFilter({
        ...projectAgencyFilter,
        skip: skip,
        limit: pagination.pageSize
      });
      if (isGlobal) {
        fetchAgencyContact({
          variables: {
            filter: {
              ...projectAgencyFilter,
              skip,
              limit: pagination.pageSize,
              sortBy: {
                field: sorter?.columnKey,
                order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
              }
            }
          }
        });
      } else {
        fetchProjectAgency({
          variables: {
            filter: {
              ...projectAgencyFilter,
              skip,
              limit: pagination.pageSize,
              sortBy: {
                field: sorter?.columnKey,
                order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
              }
            }
          }
        });
      }
    } else {
      setProjectAgencyFilter({
        ...projectAgencyFilter,
        skip: skip,
        limit: pagination.pageSize
      });

      if (isGlobal) {
        fetchAgencyContact({
          variables: {
            filter: {
              ...projectAgencyFilter,
              skip,
              limit: pagination.pageSize
            }
          }
        });
      } else {
        fetchProjectAgency({
          variables: {
            filter: {
              ...projectAgencyFilter,
              skip,
              limit: pagination.pageSize
            }
          }
        });
      }
    }
  };

  const handleAddEdit = () => {
    setShowModal(true);
  };

  const getActionButtons = (record) => {
    const handleEdit = () => {
      setAgencyData(record);
      handleAddEdit();
    };
    return (
      <CanPerform
        action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_AGENCIES}
        type={ALLOWED_ACTION_TYPE.BOTH}
      >
        <Tooltip>
          <Button
            shape="round"
            type="primary"
            className={clsx(
              'assign-action-button',
              !isDesktopViewport && 'width-percent-100'
            )}
            onClick={handleEdit}
          >
            Assign
          </Button>
        </Tooltip>
      </CanPerform>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{projectAgencyFilter?.skip + index + 1}</div>;
      }
    },
    ...(isGlobal
      ? [
          {
            title: 'PROJECT NAME',
            key: 'projectName',
            sorter: true,
            sortOrder:
              sortedInfo?.columnKey === 'projectName' && sortedInfo?.order,
            render: (record) => {
              return record?.projectAgency?.project?.name;
            }
          }
        ]
      : []),
    {
      title: 'CONTACT NAME',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order,
      render: (record) => {
        return isGlobal ? record?.contact?.name : record?.name;
      }
    },
    {
      title: 'NUMBER',
      key: 'phoneNo',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'phoneNo' && sortedInfo?.order,
      render: (record) => {
        return isGlobal ? record?.contact?.phoneNo : record?.phoneNo;
      }
    },
    ...(isGlobal
      ? []
      : [
          {
            key: 'actions',
            align: 'right',
            render: (record) => getActionButtons(record)
          }
        ])
  ];

  const onSearchChange = async (value) => {
    setProjectAgencyFilter({ ...projectAgencyFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchProjectAgency({
      variables: { filter: { ...projectAgencyFilter, skip: 0, search: value } }
    });
  };
  const handleRefetch = () => {
    if (isGlobal) {
      fetchAgencyContact({
        variables: {
          filter: {
            skip: agencyListData?.length,
            limit: DEFAULT_PAGE_SIZE
          }
        }
      });
    } else {
      fetchProjectAgency({
        variables: {
          filter: {
            ...projectAgencyFilter,
            skip: agencyListData?.length,
            limit: DEFAULT_PAGE_SIZE
          }
        }
      });
    }
  };

  return (
    <>
      {showModal && (
        <AssignAgencyModal
          showModal={showModal}
          setShowModal={setShowModal}
          agencyData={agencyData}
          setAgencyData={setAgencyData}
          refetchAgencyData={refetchAgencyDetails}
        />
      )}

      <div className="project-tab-details">
        <div
          className={`project-tab-details-header ${
            isDesktopViewport ? 'd-flex justify-between align-center' : ''
          }`}
        >
          <div
            className={clsx(
              'd-flex align-center',
              !isDesktopViewport && 'mb-10'
            )}
          >
            <h2
              className={clsx(
                'pointer mr-10 text-primary',
                !isDesktopViewport && 'font-size-16'
              )}
              onClick={() => {
                history.push(
                  isGlobal
                    ? `${ROUTES.SETUP}${ROUTES.AGENCIES}`
                    : `${ROUTES.PROJECTS}/${projectId}${ROUTES.AGENCIES}`
                );
              }}
            >
              Agency
            </h2>
            <h2
              className={clsx(
                'd-flex align-center',
                !isDesktopViewport && 'font-size-16'
              )}
            >
              <ForwardArrow className="mr-10" />
              Assign Contacts
            </h2>
          </div>
          {!isGlobal && (
            <div className="d-flex align-center">
              <SearchComponent
                className={`search-component ${
                  !isDesktopViewport ? 'width-percent-100' : ''
                }`}
                getData={onSearchChange}
              />
            </div>
          )}
        </div>
        {isDesktopViewport ? (
          <CommonTable
            columns={columns}
            data={agencyListData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
            loading={loading || globalContactLoading}
            onRow={(record) => {
              return {
                onClick: () =>
                  isGlobal &&
                  history.push(
                    `${ROUTES.PROJECTS}/${record?.projectAgency?.project?.id}/agencies/unassigned`
                  )
              };
            }}
          />
        ) : (
          <InfiniteScrollHandler
            scrollFlag={scrollFlag}
            loading={loading || globalContactLoading}
            refetchData={handleRefetch}
            setScrollFlag={setScrollFlag}
            hasMore={hasMore}
            dataLength={agencyListData?.length}
            skeletonRows={columns?.length - 3}
          >
            {map(agencyListData, (agency, index) => {
              return (
                <CommonCard
                  key={agency?.id}
                  onClick={() =>
                    isGlobal &&
                    history.push(
                      `${ROUTES.PROJECTS}/${agency?.projectAgency?.project?.id}/agencies/unassigned`
                    )
                  }
                >
                  <div className="d-flex flex-vertical">
                    <div className="common-card d-flex">
                      <div className="mr-5 fw-medium">{index + 1}.</div>
                      <div>
                        <div className="card-header fw-medium">
                          <span className="text-break">
                            {isGlobal
                              ? agency?.projectAgency?.project?.name
                              : agency?.name}
                          </span>
                        </div>
                        <div className="card-content text-secondary mt-15">
                          {isGlobal && (
                            <div className="mb-15">
                              <span className="fw-medium mr-5">
                                Contact Name:
                              </span>
                              {agency?.contact?.name}
                            </div>
                          )}
                          <div>
                            <span className="fw-medium mr-5">
                              Contact Number:
                            </span>
                            {isGlobal
                              ? agency?.contact?.phoneNo
                              : agency?.phoneNo}
                          </div>
                        </div>
                      </div>
                    </div>
                    {!isGlobal && (
                      <div className="d-flex user-action-btn mt-15">
                        {getActionButtons(agency)}
                      </div>
                    )}
                  </div>
                </CommonCard>
              );
            })}
          </InfiniteScrollHandler>
        )}
      </div>
    </>
  );
};

export default UnassignAgency;
