import { useMutation } from '@apollo/client';
import { Button, Col, Form, Input, Modal, Row } from 'antd';
import { get, map } from 'lodash';
import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { AppContext } from '../../AppContext';
import { Email, LoginLogo, Password } from '../../assets/svg/login';
import api from '../../common/api';
import { ROUTES, TENANT_ID } from '../../common/constants';
import { formValidatorRules } from '../../common/utils';
import CommonSelect from '../../components/CommonSelect';
import { LOGIN } from './graphql/Mutations';

const { required, email } = formValidatorRules;
const { Option } = CommonSelect;
const Login = (props) => {
  const { history } = props;
  const { initializeAuth } = useContext(AppContext);
  const [showTenantSelectionModal, setShowTenantSelectionModal] = useState(
    false
  );
  const pathname = history?.location?.state?.from?.pathname;
  const [tenants, setTenants] = useState([]);
  const [selectedTenantId, setSelectedTenantId] = useState();
  const [form] = Form.useForm();
  const [loginMutate, { loading: loginLoading }] = useMutation(LOGIN, {
    onError() {
      // eslint-disable-next-line no-undef
      localStorage.removeItem(TENANT_ID);
    }
  });

  function successCallback(accessToken, refreshToken) {
    initializeAuth(accessToken, refreshToken);
    if (pathname !== ROUTES.LOGOUT) {
      history.push(pathname);
    } else {
      history.replace('/');
    }
  }
  const loginWithSelectedTenant = async (tenantData, tenantId) => {
    const values = form.getFieldsValue(true);
    // eslint-disable-next-line no-undef
    localStorage.setItem(TENANT_ID, tenantId);
    if (!loginLoading) {
      const selectedTenantResponse = await loginMutate({
        variables: { data: { ...values, webAccess: true } }
      });
      const newAccessToken = get(selectedTenantResponse, 'data.login.token');
      const newRefreshToken = get(
        selectedTenantResponse,
        'data.login.refreshToken'
      );
      successCallback(newAccessToken, newRefreshToken);
    }
  };

  const onFinish = async (values) => {
    try {
      const response = await loginMutate({
        variables: { data: { ...values, webAccess: true } }
      });
      const accessToken = get(response, 'data.login.token');
      const refreshToken = get(response, 'data.login.refreshToken');
      const tenantData = get(response, 'data.login.tenantUserData');

      if (tenantData) {
        if (tenantData?.length > 1) {
          setTenants(tenantData);
          setShowTenantSelectionModal(true);
        } else {
          loginWithSelectedTenant(tenantData, tenantData[0]?.tenantId);
        }
      } else {
        delete api.defaults.headers.common.tenantid;
        successCallback(accessToken, refreshToken);
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('error from login => ', error);
    }
  };

  return (
    <div className="gx-login-container">
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <div
        className={`gx-login-content ${
          showTenantSelectionModal ? 'opacity-0' : ''
        }`}
      >
        <div className="card-body">
          <h1>Login</h1>
          <p>Sign in to your admin account</p>
          <Form
            layout="vertical"
            name="Login"
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
            form={form}
          >
            <Form.Item
              name="email"
              rules={[required, email]}
              label="Email Address"
            >
              <Input
                addonBefore={<Email />}
                placeholder="Enter Email"
                type="email"
              />
            </Form.Item>

            <Form.Item name="password" rules={[required]} label="Password">
              <Input.Password
                addonBefore={<Password />}
                placeholder="Password"
              />
            </Form.Item>
            <Row className="login-row">
              <Col>
                <Link to={ROUTES.FORGOT}>Forgot password ?</Link>
              </Col>
              <Col>
                <Form.Item className="login-button">
                  <Button
                    type="primary"
                    htmlType="submit"
                    shape="round"
                    loading={loginLoading}
                  >
                    Login
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </div>
      </div>
      <div id="tenant-list">
        <Modal
          visible={showTenantSelectionModal}
          onOk={() => loginWithSelectedTenant(tenants, selectedTenantId)}
          onCancel={() => setShowTenantSelectionModal(false)}
          okButtonProps={{
            loading: loginLoading,
            shape: 'round',
            disabled: !selectedTenantId,
            className: !selectedTenantId && 'no-hover'
          }}
          okText="Proceed"
          cancelButtonProps={{
            shape: 'round'
          }}
          centered
          closable={false}
          getContainer={() =>
            // eslint-disable-next-line no-undef
            document.getElementById('tenant-list')
          }
        >
          <div className="mb-10">
            <span className="required-mark">*</span>Select organization to
            Proceed
          </div>
          <CommonSelect
            placeholder="Select organization to login with"
            onChange={setSelectedTenantId}
            className="width-percent-100"
          >
            {map(tenants, (tenant) => (
              <Option key={tenant?.tenantId} value={tenant?.tenantId}>
                {tenant?.tenant?.organizationName}
              </Option>
            ))}
          </CommonSelect>
        </Modal>
      </div>
    </div>
  );
};

export default Login;
