import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import { findIndex, sortBy } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  sortableContainer,
  sortableElement,
  sortableHandle
} from 'react-sortable-hoc';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DragIcon,
  EditButton,
  Import
} from '../../../../../assets/svg';
import {
  BREAKPOINTS,
  CSV_SAMPLE_FILES,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  REQUEST_FEATURE_UPGRADE_KEYS
} from '../../../../../common/constants';
import { Event } from '../../../../../common/trackEvents';
import CommonImportModal from '../../../../../components/CommonImportModal';
import CommonTable from '../../../../../components/CommonTable';
import EllipsisText from '../../../../../components/EllipsisText';
import Portal from '../../../../../components/Portal';
import UpgradeModal from '../../../../../components/UpgradeModal';
import {
  DELETE_STAGE,
  IMPORT_EQC_TYPE_CSV,
  REORDER_STAGE
} from '../../../graphql/Mutation';
import { GET_STAGE_LIST } from '../../../graphql/Queries';
import DeleteModalStage from '../DeleteModalStage';
import AddEditStageModal from './AddEditStageModal';

const StageListTable = ({
  setStageId,
  stageId,
  setModalVisible,
  setModalTitle
}) => {
  const { getCurrentUser, getTenantUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { eqcTypeId } = useParams();
  const [showImportModal, setShowImportModal] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const currentTenant = getTenantUser();

  const sampleFileUrl = CSV_SAMPLE_FILES.STAGE_CSV;
  const list = [
    'Stage Type',
    'Checklist Point',
    'Input',
    'Description(Text Only)'
  ];
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  const DragHandle = sortableHandle(() => (
    <DragIcon
      style={{ cursor: 'grabbing', color: '#999' }}
      onClick={(e) => e.preventDefault()}
    />
  ));
  const initialStageFilter = {
    skip: 0,
    limit: 10,
    eqcTypeId: Number(eqcTypeId),
    sortBy: { field: 'order', order: 'ASC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [showModal, setShowModal] = useState(false);
  const [deleteStageModal, setDeleteStageModal] = useState(false);
  const [stageData, setStageData] = useState();
  const [title, setTitle] = useState('');
  const [name, setName] = useState('');
  const [mutationId, setMutationId] = useState('');
  const [dataSource, setDataSource] = useState([]);
  const [stageFilter, setStageFilter] = useState(initialStageFilter);

  const [fetchStageData, { loading }] = useLazyQuery(GET_STAGE_LIST, {
    variables: { filter: stageFilter },
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setStageId(res?.eqcTypeStageList?.data[0]?.id);
      const pagination = {
        ...paginationProp,
        total: res?.eqcTypeStageList?.total
      };
      setPaginationProp(pagination);
      setDataSource(sortBy(res?.eqcTypeStageList?.data, ['order']));
    },
    onError() {}
  });
  const handleRefetchAfterDelete = () => {
    const newSkip =
      dataSource?.length === 1
        ? Math.max(0, stageFilter?.skip - paginationProp?.pageSize)
        : stageFilter?.skip;
    setStageFilter({
      ...stageFilter,
      skip: newSkip
    });
    fetchStageData({
      variables: {
        filter: {
          ...stageFilter,
          skip: newSkip
        }
      }
    });
  };
  const [deleteStage] = useMutation(DELETE_STAGE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DELETE_GLOBAL_CHECKLIST_STAGE, {
        label: GA_LABEL.DELETE_GLOBAL_CHECKLIST_STAGE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        checklist_id: eqcTypeId,
        stage_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setDeleteStageModal(false);
      handleRefetchAfterDelete();
    }
  });

  const [updateStageImport, { loading: importLoading }] = useMutation(
    IMPORT_EQC_TYPE_CSV,
    {
      onError() {}
    }
  );

  useEffect(() => {
    fetchStageData({ variables: { filter: stageFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refetchStageDetails = () => {
    fetchStageData({ variables: { filter: { ...stageFilter } } });
  };

  const [reorderStage, { loading: reorderLoading }] = useMutation(
    REORDER_STAGE,
    {
      onCompleted() {
        Event(GA_EVENT.REORDER_GLOBAL_CHECKLIST_STAGE, {
          label: GA_LABEL.REORDER_GLOBAL_CHECKLIST_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          checklist_id: eqcTypeId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        fetchStageData();
      },
      onError() {}
    }
  );

  const handleAddEditUser = (record) => {
    if (record) {
      setStageData(record);
    } else {
      setStageData();
    }
    setShowModal(true);
  };

  const handleTableChange = (pagination) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setStageFilter({ ...stageFilter, skip: skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
    fetchStageData({
      variables: {
        filter: {
          ...stageFilter,
          skip,
          limit: pagination.pageSize
        }
      }
    });
  };
  const handleImport = () => {
    setShowImportModal(true);
  };
  const handleEdit = (e, record) => {
    e?.stopPropagation?.();
    handleAddEditUser(record);
  };
  const handleDelete = (e, record) => {
    e?.stopPropagation?.();
    setTitle('Stage');
    setName(record?.name);
    setMutationId(record?.id);
    setDeleteStageModal(true);
  };
  const columns = [
    {
      width: '5%',
      className: 'drag-visible',
      render: () => (
        <Tooltip title="Change order">
          <div>
            <DragHandle />
          </div>
        </Tooltip>
      )
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      className: 'drag-visible',
      key: 'name',
      width: '70%',
      render: (record) => (
        <div>
          <EllipsisText text={record} />
        </div>
      )
    },
    {
      key: 'action',
      render: (record) => {
        return (
          <div className="d-flex action-icons">
            <Tooltip title="Edit">
              <Button
                shape="round"
                icon={<EditButton />}
                className="edit-button pointer b-0"
                onClick={(e) => handleEdit(e, record)}
              />
            </Tooltip>
            <Tooltip title="Delete">
              <Button
                className="delete-button pointer b-0"
                shape="round"
                icon={<DeleteButton />}
                onClick={(e) => handleDelete(e, record)}
              />
            </Tooltip>
          </div>
        );
      }
    }
  ];

  const SortableItem = sortableElement((props) => <tr {...props} />);
  const SortableContainer = sortableContainer((props) => <tbody {...props} />);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      reorderStage({
        variables: {
          data: {
            eqcTypeStageIds: [
              dataSource[oldIndex]?.id,
              dataSource[newIndex]?.id
            ],
            eqcTypeId: Number(eqcTypeId)
          }
        }
      });
    }
  };

  const DraggableContainer = (props) => (
    <SortableContainer
      useDragHandle
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      axis="y"
      lockAxis="y"
      lockOffset={['0%', '100%']}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = findIndex(
      dataSource,
      (item) => item?.id === restProps['data-row-key']
    );
    if (index !== -1)
      return (
        <SortableItem index={index} className={className} {...restProps} />
      );
    return null;
  };
  return (
    <>
      {showModal && (
        <AddEditStageModal
          showModal={showModal}
          setShowModal={setShowModal}
          stageData={stageData}
          isUpdate={!!stageData}
          setStageData={setStageData}
          refetchStageDataWithInitialValues={refetchStageDetails}
          eqcTypeId={eqcTypeId}
        />
      )}
      {deleteStageModal && (
        <DeleteModalStage
          showModal={deleteStageModal}
          setShowModal={setDeleteStageModal}
          title={title}
          name={name}
          deleteStage={deleteStage}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      )}
      {showImportModal && (
        <CommonImportModal
          showImportModal={showImportModal}
          setShowImportModal={setShowImportModal}
          title="Checklist Stages"
          sampleFileUrl={sampleFileUrl}
          list={list}
          updateStageImport={updateStageImport}
          refetchData={fetchStageData}
          filePrefix="eqcTypeCsv"
          eqcTypeId={eqcTypeId}
          stageUi
          importLoading={importLoading}
        />
      )}
      <Portal portalId="eqc-details-btn">
        <Portal portalId="add-btn">
          <Button
            shape="round"
            type="primary"
            id="add-btn"
            icon={<AddButton />}
            className="add-button b-0"
            onClick={() => {
              if (
                paginationProp?.total <
                currentTenant?.tenant?.featureConfig?.stageMaxLimit
              ) {
                handleAddEditUser();
              } else {
                setShowUpgradeModal(true);
              }
            }}
          >
            {isDesktopViewport && 'Add'}
          </Button>
        </Portal>
        <Button
          shape="round"
          icon={<Import />}
          className="import-button b-0"
          onClick={handleImport}
        >
          {isDesktopViewport && 'Import'}
        </Button>
      </Portal>

      <div className="pointer">
        <CommonTable
          columns={columns}
          data={dataSource || []}
          onChange={handleTableChange}
          paginationConfig={paginationProp}
          rowKey="id"
          loading={loading || reorderLoading}
          rowClassName={(record) => {
            if (record?.id === stageId) return 'row-dark';
          }}
          onRow={(record) => {
            return {
              onClick: () => {
                if (record?.id) {
                  setStageId(record?.id);
                  setModalVisible(true);
                  setModalTitle(record?.name);
                }
              }
            };
          }}
          components={{
            body: dataSource?.length > 0 && {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
        />
      </div>
      {showUpgradeModal && (
        <UpgradeModal
          showModal={showUpgradeModal}
          setShowModal={setShowUpgradeModal}
          featureKey={REQUEST_FEATURE_UPGRADE_KEYS.STAGE_MAX_LIMIT}
          isAlreadyRequested={
            currentTenant?.tenant?.featureApprovalRequestConfig
              ?.stageMaxLimitRequestSent
          }
        />
      )}
    </>
  );
};

export default StageListTable;
