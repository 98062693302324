import { Card } from 'antd';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../../../../common/constants';
import StageItemTable from './StageItemTable';

const StageItemList = ({ stageId, eqcTypeData }) => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <Card className="stage-item-card">
      <div
        className={`header d-flex align-center justify-between ${
          isDesktopViewport ? 'mb-10' : ''
        } `}
      >
        <h2>Stage Items</h2>
        <div className="eqc-stage-header-buttons d-flex">
          <div id="stageitem-add-btn" />
        </div>
      </div>
      <StageItemTable stageId={stageId} eqcTypeData={eqcTypeData} />
    </Card>
  );
};

export default StageItemList;
