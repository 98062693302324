import { Button, Modal } from 'antd';
import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';

const DeleteUserModal = ({
  showModal = false,
  setShowModal,
  title,
  name,
  deleteProjectUser,
  mutationId,
  setMutationId,
  fetchProjectUser
}) => {
  const { projectId } = useParams();
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const handleOk = () => {
    deleteProjectUser({
      variables: {
        id: mutationId
      }
    })
      .then(() => {
        Event(GA_EVENT.DELETE_PROJECT_USER, {
          label: GA_LABEL.DELETE_PROJECT_USER,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_user_id: mutationId,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        fetchProjectUser();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.log(err);
      });
    setShowModal(false);
  };

  const handleCancel = () => {
    setMutationId('');
    setShowModal(false);
  };
  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2>{`Remove ${title}`}</h2>
        <p>Are you sure you want to remove {name}?</p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            No, Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleOk}
          >
            Yes, Remove
          </Button>
        </div>
      </Modal>
    </div>
  );
};
export default DeleteUserModal;
