import { useLazyQuery } from '@apollo/client';
import { Button, Divider, Form, message, Modal, Upload } from 'antd';
import { isEmpty, map } from 'lodash';
import React, { useContext, useState } from 'react';
import { CSVLink } from 'react-csv';
import { AppContext } from '../AppContext';
import { GA_EVENT, GA_LABEL } from '../common/constants';
import { Event } from '../common/trackEvents';
import { fileUpload, formValidatorRules } from '../common/utils';
import { GET_URL } from '../modules/profile/graphql/Queries';

const { required } = formValidatorRules;

const CommonImportModal = (props) => {
  const [fileObj, setFileObj] = useState();
  const [fileName, setFileName] = useState();
  const [disabled, setDisabled] = useState(true);
  const [btnLoading, setBtnLoading] = useState(false);
  const { getCurrentUser } = useContext(AppContext);
  const [responseData, setResponseData] = useState({});
  const currentUser = getCurrentUser();
  const {
    showImportModal,
    setShowImportModal,
    list = [],
    sampleFileUrl,
    updateUserImport,
    filePrefix,
    refetchData,
    updateStageImport,
    updateAgencyImport,
    title,
    stageUi,
    eqcTypeId,
    importLoading,
    additionalPoints = [],
    projectId,
    importProjectNomenclatureCsv
  } = props;
  const [form] = Form.useForm();

  const importUi = () => {
    return (
      <>
        <div className="d-flex  import-required">
          <h4>Required</h4>
          <Form.Item>
            <ul className="font-size-16">
              {list.map((item) => (
                <li key={item?.id} value={item}>
                  {item}
                </li>
              ))}
            </ul>
          </Form.Item>
        </div>
        <Divider dashed className="import-divider" />
        <div className="d-flex  import-additional-info">
          <h4>Additional Info</h4>
          <Form.Item name="name">
            <ul className="font-size-16">
              <li>File extension MUST be .csv</li>
              {!stageUi && isEmpty(additionalPoints) && (
                <li>Mobile number, email MUST be in a valid format</li>
              )}
              {map(additionalPoints, (text) => (
                <li>{text}</li>
              ))}
            </ul>
          </Form.Item>
        </div>
      </>
    );
  };

  const importErrorUi = (errorMessage, path, showErrorMessage = true) => {
    const notImportedData = path;
    return (
      <>
        <div className="d-flex justify-between import-error">
          <h4>Error</h4>
          <Form.Item>
            <CSVLink filename={fileName} data={notImportedData}>
              <Button shape="round">Download Error File</Button>
            </CSVLink>
          </Form.Item>
        </div>
        <Divider dashed className="import-divider" />
        {showErrorMessage && (
          <div className="d-flex import-additional-info">
            <p>{errorMessage}</p>
          </div>
        )}
      </>
    );
  };

  const [modalUi, setModalUi] = useState(importUi());

  const handleCancel = () => {
    setShowImportModal(false);
    form.resetFields();
    setBtnLoading(false);
    setResponseData({});
  };

  const [fetchSignedUrl, { loading }] = useLazyQuery(GET_URL, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const signedImageUrl = res?.getSignedPutUrl?.signedUrl;
      setFileName(res?.getSignedPutUrl?.key);
      setBtnLoading(true);
      fileUpload(signedImageUrl, fileObj).finally(() => {
        setBtnLoading(false);
      });
    },
    onError() {
      setBtnLoading(false);
    }
  });

  const handleImport = (file) => {
    switch (filePrefix) {
      case 'userCsv':
        updateUserImport({
          variables: {
            key: file
          }
        }).then((res) => {
          Event(GA_EVENT.IMPORTED_GLOBAL_USER, {
            label: GA_LABEL.IMPORTED_GLOBAL_USER,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName
          });
          const path = res && res?.data?.importUserCsv?.invalidData;
          setResponseData(res?.data?.importUserCsv);
          if (res?.data?.importUserCsv?.invalidData?.length > 0) {
            map(res?.data?.importUserCsv?.invalidData, (errorMessage) => {
              setModalUi(importErrorUi(errorMessage?.message, path));
            });
            setShowImportModal(true);
          } else {
            setShowImportModal(false);
          }
          if (res?.data?.importUserCsv?.invalidDataCount === 0) {
            message.success('Csv imported successfully.');
          }
          refetchData();
        });
        break;
      case 'agencyCsv':
        updateAgencyImport({
          variables: {
            key: file
          }
        }).then((res) => {
          Event(GA_EVENT.IMPORTED_GLOBAL_AGENCY, {
            label: GA_LABEL.IMPORTED_GLOBAL_AGENCY,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName
          });
          const path = res && res?.data?.importAgencyCsv?.invalidData;
          if (res?.data?.importAgencyCsv?.invalidData?.length > 0) {
            map(res?.data?.importAgencyCsv?.invalidData, (errorMessage) => {
              setModalUi(importErrorUi(errorMessage?.message, path));
            });
            setShowImportModal(true);
          } else {
            setShowImportModal(false);
          }
          if (res?.data?.importAgencyCsv?.invalidDataCount === 0) {
            message.success('Csv imported successfully.');
          }
          refetchData();
        });
        break;
      case 'eqcTypeCsv':
        updateStageImport({
          variables: {
            eqcTypeId: eqcTypeId,
            key: file
          }
        }).then((res) => {
          Event(GA_EVENT.IMPORTED_GLOBAL_CHECKLIST_STAGE, {
            label: GA_LABEL.IMPORTED_GLOBAL_CHECKLIST_STAGE,
            eqcTypeId: eqcTypeId,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName
          });
          const path = res && res?.data?.importEqcTypeCsv?.invalidData;
          if (res?.data?.importEqcTypeCsv?.invalidData?.length > 0) {
            map(res?.data?.importEqcTypeCsv?.invalidData, (errorMessage) => {
              setModalUi(importErrorUi(errorMessage?.message, path));
            });
            setShowImportModal(true);
          } else {
            setShowImportModal(false);
          }
          if (res?.data?.importEqcTypeCsv?.invalidDataCount === 0) {
            message.success('Csv imported successfully.');
          }
          refetchData();
        });
        break;
      case 'nomenclatureCsv':
        importProjectNomenclatureCsv({
          variables: {
            projectId: projectId,
            key: file
          }
        }).then((res) => {
          Event(GA_EVENT.IMPORTED_NOMENCLATURE, {
            label: GA_LABEL.IMPORTED_NOMENCLATURE,
            projectId: projectId,
            // eslint-disable-next-line no-undef
            pathname: window?.location?.href,
            user_id: currentUser?.id,
            user_name: currentUser?.name,
            tenant_id: currentUser?.tenantUser?.tenant?.id,
            tenant_name: currentUser?.tenantUser?.tenant?.organizationName
          });
          const path = res && res?.data?.importProjectNomenclatureCsv?.data;
          if (res?.data?.importProjectNomenclatureCsv?.data?.length > 0) {
            map(
              res?.data?.importProjectNomenclatureCsv?.data,
              (errorMessage) => {
                setModalUi(importErrorUi(errorMessage?.message, path, false));
              }
            );
            setShowImportModal(true);
          } else {
            setShowImportModal(false);
          }
          if (res?.data?.importProjectNomenclatureCsv?.data?.length === 0) {
            message.success('Csv imported successfully.');
            refetchData();
          }
        });
        break;
      default:
        break;
    }
  };

  const handleUpload = (info) => {
    try {
      fetchSignedUrl({
        variables: {
          data: {
            fileName: `${filePrefix}/${info.file.name}`,
            contentType: info.file.type,
            acl: 'private'
          }
        }
      });
      setFileObj(info.file);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('Error while uploading image', error);
    }
  };
  const importProps = {
    accept: '.csv',
    onChange: (info) => {
      if (info?.file?.type === 'text/csv') {
        if (info?.fileList?.length) {
          setDisabled(false);
          handleUpload(info);
        } else {
          setDisabled(true);
        }
      } else if (info?.fileList?.length > 0) {
        message.error('File extension MUST be .csv');
        setDisabled(true);
      }
    },
    beforeUpload: () => {
      return false;
    }
  };
  return (
    <Modal
      maskClosable={false}
      centered
      visible={showImportModal}
      className="import-modal"
      footer={null}
      closable={false}
    >
      <h2>{`Import ${title}`}</h2>
      {responseData?.invalidDataCount > 0 && (
        <h4>Upload complete: New users added successfully</h4>
      )}
      <Form form={form} layout="horizontal">
        {!responseData?.invalidDataCount > 0 && (
          <Form.Item rules={[required]}>
            <Upload {...importProps} maxCount={1}>
              <Button
                type="primary"
                shape="round"
                disabled={loading || btnLoading || importLoading}
              >
                Select File
              </Button>
            </Upload>
          </Form.Item>
        )}

        {!responseData?.invalidDataCount > 0 ? (
          <>
            <h4>
              Use the
              <a className="sample-file pointer" href={sampleFileUrl}>
                {' '}
                Sample file
              </a>{' '}
              to fill the data. Upload the file after verifying the format of
              your file with the sample file.
            </h4>
            {stageUi && (
              <h4 className="note-error">
                Note: Existing Checklist stages and their items will be
                overridden.
              </h4>
            )}
            <br />
            {modalUi}
          </>
        ) : (
          <h5>
            Note: There is issue in uploading {responseData?.invalidDataCount}{' '}
            users. Please click on Download error file button for more details
          </h5>
        )}
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          {responseData?.invalidDataCount > 0 ? (
            <CSVLink filename={fileName} data={responseData?.invalidData}>
              <Button
                type="primary"
                className="ml-10 save-button"
                shape="round"
              >
                Download Error File
              </Button>
            </CSVLink>
          ) : (
            <Button
              shape="round"
              type="primary"
              className="save-button"
              disabled={disabled}
              onClick={() => {
                handleImport(fileName);
              }}
              loading={loading || btnLoading || importLoading}
            >
              Start Upload
            </Button>
          )}
        </div>
      </Form>
    </Modal>
  );
};

export default CommonImportModal;
