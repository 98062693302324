import { useLazyQuery } from '@apollo/client';
import { Empty } from 'antd';
import * as eva from 'eva-icons';
import { findIndex, isNaN, toUpper } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../../AppContext';
import Error404 from '../../../../../../../Error404';
import { ForwardArrow, ProjectIcon } from '../../../../../../../assets/svg';
import { ROUTES, TAB_KEYS } from '../../../../../../../common/constants';
import EllipsisText from '../../../../../../../components/EllipsisText';
import LoaderComponent from '../../../../../../../components/LoaderComponent';
import {
  GET_PROJECT_EQC,
  GET_SINGLE_PROJECT
} from '../../../../../graphql/Queries';
import EqcStages from './EqcStages';
import EqcSummary from './EqcSummary';

const EqcDetails = () => {
  const { getCurrentUser, dispatch } = useContext(AppContext);
  const { projectId, eqcId } = useParams();
  const currentUserId = getCurrentUser()?.id || {};
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;
  const eqcFilter =
    typeof history?.location?.state?.eqcFilter === 'string'
      ? JSON.parse(history?.location?.state?.eqcFilter)
      : history?.location?.state?.eqcFilter;
  const eqcPagination = history?.location?.state?.eqcPagination;
  const eqcTypeRecord = history?.location?.state?.eqcTypeRecord;
  const userRecord = history?.location?.state?.userRecord;
  const projectRecord = history?.location?.state?.projectRecord;
  const currentPath = history?.location?.state?.currentPath;
  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        history.push(currentPath, {
          navFilter,
          navPagination,
          eqcFilter,
          eqcPagination,
          eqcTypeRecord,
          userRecord,
          projectRecord
        });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const [getWebProject, { data: projectData }] = useLazyQuery(
    GET_SINGLE_PROJECT,
    {
      fetchPolicy: 'network-only',
      onCompleted: (res) => {
        dispatch({
          type: 'SET_PROJECT_DETAILS',
          data: JSON.stringify(res?.getProject)
        });
        const index = findIndex(
          res?.getProject?.projectUsers,
          (user) => Number(user?.userId) === Number(currentUserId)
        );
        if (index !== -1) {
          const loggedInUserRole =
            res?.getProject?.projectUsers?.[index]?.roles;
          dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
        } else {
          dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
        }
      }
    }
  );

  useEffect(() => {
    if (!isNaN(Number(projectId))) {
      getWebProject({
        variables: {
          id: projectId
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  const [fetchEqcDataLazyQuery, { data: eqcData }] = useLazyQuery(
    GET_PROJECT_EQC,
    {
      fetchPolicy: 'network-only',
      onCompleted() {
        setLoading(false);
      }
    }
  );

  const fetchEqcData = () => {
    if (!isNaN(Number(eqcId))) {
      fetchEqcDataLazyQuery({
        variables: {
          id: eqcId
        }
      });
    }
  };

  useEffect(() => {
    fetchEqcData();
    return () => {
      dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isNaN(Number(projectId)) || isNaN(Number(eqcId))) {
    return <Error404 />;
  }
  return loading ? (
    <LoaderComponent />
  ) : (
    <>
      <div className="project-details">
        <div className="project-details-title justify-between page-header">
          <div className="project-details-title-text d-flex">
            <ProjectIcon
              onClick={() => {
                history.push(ROUTES.PROJECTS, {
                  navFilter,
                  navPagination
                });
              }}
              className="pointer"
            />
            <ForwardArrow />
            <h1
              className="pointer mr-10 text-primary"
              onClick={() => {
                history.push(
                  `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.EQC}`,
                  TAB_KEYS[
                    toUpper(
                      currentPath?.split('/')?.[
                        currentPath?.split('/')?.length - 1
                      ]
                    )
                  ]
                    ? {
                        navFilter,
                        navPagination,
                        eqcFilter,
                        eqcPagination,
                        eqcTypeRecord,
                        userRecord
                      }
                    : {}
                );
              }}
            >
              {projectData?.getProject?.name}
            </h1>
            <ForwardArrow />
            <EllipsisText
              className="h1-typography"
              text={eqcData?.getEqc?.eqcName}
            />
          </div>
        </div>
        {eqcData?.getEqc ? (
          <div className="eqc-tab-details mobile-card-wrapper">
            <div className="mb-15 ">
              <EqcSummary
                data={eqcData?.getEqc}
                projectData={projectData}
                refetch={() => {
                  if (!eqcData?.isAudited) {
                    fetchEqcData();
                  }
                }}
              />
            </div>
            <div>
              <EqcStages data={eqcData?.getEqc} />
            </div>
          </div>
        ) : (
          <Empty />
        )}
      </div>
    </>
  );
};

export default EqcDetails;
