import { useMutation } from '@apollo/client';
import { Button, Col, Form, Row, Spin, Switch } from 'antd';
import { filter, includes, map } from 'lodash';
import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import {
  ADDONS,
  EQC_CONFIG_LABELS,
  GA_EVENT,
  GA_LABEL
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import { UPDATE_PROJECT_RFI_DRAFT } from '../../../../graphql/Mutation';

const RfiSettings = (props) => {
  const { RFIData, refetch, isEditable, isDisabled } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();
  const { projectId } = useParams();

  const [updateProjectRFIDraft, { loading: updateLoading }] = useMutation(
    UPDATE_PROJECT_RFI_DRAFT,
    {
      onError() {},
      onCompleted() {
        Event(GA_EVENT.EDIT_PROJECT_RFI_STAGE, {
          label: GA_LABEL.EDIT_PROJECT_RFI_STAGE,
          // eslint-disable-next-line no-undef
          pathname: window?.location?.href,
          project_id: projectId,
          project_rfi_id: RFIData?.id,
          user_id: currentUser?.id,
          user_name: currentUser?.name,
          tenant_id: currentUser?.tenantUser?.tenant?.id,
          tenant_name: currentUser?.tenantUser?.tenant?.organizationName
        });
        refetch({
          variables: {
            projectRFIId: RFIData?.id
          }
        });
      }
    }
  );

  const onSubmitFinish = (formValues) => {
    try {
      updateProjectRFIDraft({
        variables: { data: formValues, id: RFIData?.id }
      });
    } catch (error) {
      return error;
    }
  };

  useEffect(() => {
    form.setFieldsValue({ ...RFIData, reRFI: RFIData?.isReRfiRequired });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RFIData]);

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onSubmitFinish}
      className="stage-config"
    >
      <Spin spinning={updateLoading}>
        {map(
          filter(EQC_CONFIG_LABELS, (type) =>
            includes(type?.allowed_module, ADDONS.RFI)
          ),
          (item) => {
            return (
              <div className="config-div">
                <Row
                  justify="space-between notification-row"
                  align="middle"
                  key={item?.key}
                  gutter={{ xs: 0, sm: 30 }}
                >
                  <Col xs={12} sm={16}>
                    <div className="title">{item?.label}</div>
                    <div className="description">{item?.description}</div>
                  </Col>
                  <Col>
                    <div className="d-flex justify-center align-center">
                      <Form.Item
                        name={item?.key}
                        valuePropName="checked"
                        className="nested-form"
                      >
                        <Switch
                          disabled={
                            (item?.key !== 'needApproval' && isDisabled) ||
                            !isEditable
                          }
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row />
              </div>
            );
          }
        )}
      </Spin>
      {isEditable && (
        <div className="form-buttons mt-15">
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
            loading={updateLoading}
          >
            Save
          </Button>
        </div>
      )}
    </Form>
  );
};

export default RfiSettings;
