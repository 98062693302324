import { Card } from 'antd';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  ROUTES
} from '../../../../../../common/constants';
import CanPerform from '../../../../../../components/CanPerform';
import HasAccess from '../../../../../../components/HasAccess';
import EqcDetails from '../../../../../eqctype/components/eqcDetails/EqcDetails';
import UnassignAgency from '../agencies/UnassignAgency';
import Setup from './Setup';

function SetupWrapper() {
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });

  return (
    <Switch>
      <Route exact path={`${ROUTES.SETUP}/:tab`} component={Setup} />
      {hasInspectionAccess &&
        CanPerform({
          action: ALLOWED_ACTION_KEYS.VIEW_CHECKLISTS_PAGE
        }) && (
          <Route
            path={`${ROUTES.SETUP}${ROUTES.CHECKLISTS}/:eqcTypeId`}
            component={EqcDetails}
          />
        )}
      {CanPerform({
        action: ALLOWED_ACTION_KEYS.VIEW_AGENCY_PAGE
      }) && (
        <Route exact path={`${ROUTES.SETUP}${ROUTES.UNASSIGN_AGENCIES}`}>
          <Card>
            <UnassignAgency isGlobal />
          </Card>
        </Route>
      )}
    </Switch>
  );
}

export default SetupWrapper;
