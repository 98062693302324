import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Dropdown, Menu, Switch, Tag, Tooltip } from 'antd';
import { map } from 'lodash';
import moment from 'moment-timezone';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../AppContext';
import {
  AddButton,
  DeleteButton,
  DuplicateIcon,
  EditButton,
  KebabMenu,
  Timezone
} from '../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  BREAKPOINTS,
  DEFAULTDATETIMEFORMAT,
  DEFAULT_PAGE_SIZE,
  GA_EVENT,
  GA_LABEL,
  ROUTES,
  UOMS
} from '../../../common/constants';
import { Event } from '../../../common/trackEvents';
import { removeHistoryStateData } from '../../../common/utils';
import CanPerform from '../../../components/CanPerform';
import CommonCard from '../../../components/CommonCard';
import CommonTable from '../../../components/CommonTable';
import InfiniteScrollHandler from '../../../components/InfiniteScrollHandler';
import Portal from '../../../components/Portal';
import SearchComponent from '../../../components/SearchComponent';
import ActivationModal from '../ActivationModal';
import AddEditEqcModal from '../AddEditEqcModal';
import DeleteModal from '../DeleteModal';
import DuplicateModal from '../DuplicateModal';
import { DELETE_EQC_TYPE, UPDATE_EQC_TYPE_STATUS } from '../graphql/Mutation';
import { GET_EQC_TYPE } from '../graphql/Queries';

const EqcTable = () => {
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const history = useHistory();
  const navFilter = history?.location?.state?.navFilter;
  const navPagination = history?.location?.state?.navPagination;

  const initialEqcFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE
  };

  const [paginationProp, setPaginationProp] = useState(
    navPagination || initialPaginationValue
  );
  const [showStatusModal, setStatusModal] = useState(false);
  const [showDuplicateModal, setDuplicateModal] = useState(false);
  const [showDeleteModal, setDeleteModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [eqcData, setEqcData] = useState();
  const [isActive, setIsActive] = useState(false);
  const [mutationId, setMutationId] = useState('');
  const [sortedInfo, setSortedInfo] = useState({});
  const [title, setTitle] = useState('');
  const [eqcFilter, setEqcFilter] = useState(navFilter || initialEqcFilter);
  const [hasMore, setHasMore] = useState(true);
  const [scrollFlag, setScrollFlag] = useState(false);
  const [eqcTypeListData, setEqcTypeListData] = useState([]);
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  useEffect(() => {
    if (navFilter) {
      setEqcFilter(navFilter);
    }
    if (navPagination) {
      setPaginationProp(navPagination);
    }
  }, [navFilter, navPagination]);

  const [fetchEqcData, { loading }] = useLazyQuery(GET_EQC_TYPE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      const data = res?.eqcTypeList?.data;
      const pagination = {
        ...paginationProp,
        total: res?.eqcTypeList?.total
      };
      if (scrollFlag) {
        const datalist = [...eqcTypeListData, ...data];
        setEqcTypeListData(datalist);
        setScrollFlag(false);
      } else {
        const datalist = [...data];
        setEqcTypeListData(datalist);
      }
      setHasMore(!!data?.length);
      setPaginationProp(pagination);
    },
    onError() {}
  });

  const [updateStatus] = useMutation(UPDATE_EQC_TYPE_STATUS, {
    onCompleted() {
      Event(GA_EVENT.EDIT_GLOBAL_CHECKLIST, {
        label: GA_LABEL.EDIT_GLOBAL_CHECKLIST,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        checklist_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setStatusModal(false);
      fetchEqcData({ variables: { filter: eqcFilter } });
    },
    onError() {}
  });
  const handleRefetchAfterDelete = () => {
    const newSkip =
      eqcTypeListData?.length === 1
        ? Math.max(0, eqcFilter?.skip - paginationProp?.pageSize)
        : eqcFilter?.skip;
    setEqcFilter({
      ...eqcFilter,
      skip: newSkip,
      sortBy: { field: 'createdAt', order: 'DESC' }
    });
    fetchEqcData({
      variables: {
        filter: {
          ...eqcFilter,
          skip: newSkip,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const [deleteEqc] = useMutation(DELETE_EQC_TYPE, {
    onCompleted() {
      Event(GA_EVENT.DELETE_GLOBAL_CHECKLIST, {
        label: GA_LABEL.DELETE_GLOBAL_CHECKLIST,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        checklist_id: mutationId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      handleRefetchAfterDelete();
    },
    onError() {}
  });

  useEffect(() => {
    fetchEqcData({ variables: { filter: eqcFilter } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eqcFilter]);

  const refetchEqcDetails = () => {
    fetchEqcData({ variables: { filter: { ...eqcFilter } } });
  };

  const handleAddEditEqc = () => {
    setShowModal(true);
  };

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setPaginationProp({ ...paginationProp, ...pagination });
    if (sorter?.column) {
      setEqcFilter({
        ...eqcFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: {
          field: sorter.columnKey,
          order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
        }
      });
      fetchEqcData({
        variables: {
          filter: {
            ...eqcFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: {
              field: sorter.columnKey,
              order: sorter.order === 'ascend' ? 'ASC' : 'DESC'
            }
          }
        }
      });
    } else {
      setEqcFilter({
        ...eqcFilter,
        skip: skip,
        limit: pagination.pageSize,
        sortBy: { field: 'createdAt', order: 'DESC' }
      });
      fetchEqcData({
        variables: {
          filter: {
            ...eqcFilter,
            skip,
            limit: pagination.pageSize,
            sortBy: { field: 'createdAt', order: 'DESC' }
          }
        }
      });
    }
  };

  const ActiveStatus = ({ record }) => {
    if (record?.isActive === true) {
      return <Tag className="active-tag">Active</Tag>;
    }
    return <Tag className="inactive-tag">Inactive</Tag>;
  };

  const handleActiveStatus = (record) => {
    if (record?.isActive === false) setTitle('Activate');
    else setTitle('Deactivate');
    setIsActive(record?.isActive);
    setStatusModal(true);
  };
  const getActionButtons = (record) => {
    const handleEdit = (e) => {
      e?.stopPropagation?.();
      setEqcData(record);
      handleAddEditEqc();
    };
    const handleDuplicate = (e) => {
      e?.stopPropagation?.();
      setEqcData(record);
      setDuplicateModal(true);
    };
    const handleDelete = (e) => {
      e?.stopPropagation?.();
      setTitle(record?.name);
      setMutationId(record?.id);
      setDeleteModal(true);
    };
    const handleSwitch = (e) => {
      e?.stopPropagation?.();
      setMutationId(record?.id);
      handleActiveStatus(record);
    };
    const menu = (
      <Menu>
        <Menu.Item key="edit" onClick={handleEdit}>
          Edit
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="duplicate" onClick={handleDuplicate}>
          Duplicate
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="delete" onClick={handleDelete}>
          <span className="text-danger">Delete</span>
        </Menu.Item>
      </Menu>
    );
    return isDesktopViewport ? (
      <div className="d-flex align-center">
        <Tooltip title="Edit">
          <Button
            shape="round"
            icon={<EditButton />}
            className="edit-button pointer b-0"
            onClick={handleEdit}
          />
        </Tooltip>
        <CanPerform action={ALLOWED_ACTION_KEYS.DELETE_EQC}>
          <Tooltip title="Duplicate">
            <Button
              shape="round"
              icon={<DuplicateIcon />}
              className="pointer b-0"
              onClick={handleDuplicate}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              shape="round"
              icon={<DeleteButton />}
              className="edit-button pointer b-0"
              onClick={handleDelete}
            />
          </Tooltip>
          <Tooltip title={record?.isActive ? 'Deactivate' : 'Activate'}>
            <Switch
              onChange={(checkedValue, e) => handleSwitch(e)}
              checked={record?.isActive}
            />
          </Tooltip>
        </CanPerform>
      </div>
    ) : (
      <div className="d-flex align-center">
        <Tooltip title={record?.isActive ? 'Deactivate' : 'Activate'}>
          <Switch
            onChange={(checkedValue, e) => handleSwitch(e)}
            checked={record?.isActive}
            size="small"
          />
        </Tooltip>
        <Dropdown overlay={menu} trigger={['click']}>
          <KebabMenu />
        </Dropdown>
      </div>
    );
  };
  const columns = [
    {
      title: '#',
      key: 'id',
      render: (text, record, index) => {
        return <div>{eqcFilter?.skip + index + 1}</div>;
      }
    },
    {
      title: 'NAME',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'name' && sortedInfo?.order
    },
    {
      title: 'DEFAULT UOM',
      dataIndex: 'uom',
      key: 'uom',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'uom' && sortedInfo?.order
    },
    {
      title: 'REFERENCE NUMBER',
      dataIndex: 'iso',
      key: 'iso',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'iso' && sortedInfo?.order
    },
    {
      title: 'ACTIVE',
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'isActive' && sortedInfo?.order,
      render: (activeStatus, record) => {
        return <ActiveStatus record={record} />;
      }
    },
    {
      title: 'UPDATED BY',
      key: 'updatedBy',
      render: (record) => {
        return record?.creator?.name;
      }
    },
    {
      title: 'UPDATED AT',
      key: 'updatedAt',
      sorter: true,
      sortOrder: sortedInfo?.columnKey === 'updatedAt' && sortedInfo?.order,
      render: (updatedAt, record) => {
        return record?.updatedAt ? (
          <div>
            {moment(`${record?.updatedAt}`)
              .tz(currentUser?.tenantUser?.tenant?.timeZone)
              .format(DEFAULTDATETIMEFORMAT)}
            <div className="d-flex align-center timezone-div">
              <Timezone title="timeZone" />
              {currentUser?.tenantUser?.tenant?.timeZone}
            </div>
          </div>
        ) : (
          '-'
        );
      }
    },
    {
      title: 'ACTIONS',
      render: (record) => getActionButtons(record)
    }
  ];

  const onSearchChange = async (value) => {
    if (!value) {
      removeHistoryStateData(history, history?.location, 'navFilter', 'search');
    }
    setEqcFilter({ ...eqcFilter, skip: 0, search: value });
    setPaginationProp(initialPaginationValue);
    fetchEqcData({
      variables: { filter: { ...eqcFilter, skip: 0, search: value } }
    });
  };

  const handleRefetch = () => {
    fetchEqcData({
      variables: {
        filter: {
          ...eqcFilter,
          skip: eqcTypeListData?.length,
          sortBy: { field: 'createdAt', order: 'DESC' }
        }
      }
    });
  };
  const handleRowClick = (record) => {
    if (record?.id) {
      history.push(`${ROUTES.SETUP}${ROUTES.CHECKLISTS}/${record?.id}`, {
        navFilter: eqcFilter,
        navPagination: paginationProp,
        currentPath: history?.location?.pathname
      });
    }
  };
  return (
    <>
      {showModal && (
        <AddEditEqcModal
          showModal={showModal}
          setShowModal={setShowModal}
          setEqcData={setEqcData}
          eqcData={eqcData}
          isUpdate={!!eqcData}
          refetchUserDataWithInitialValues={refetchEqcDetails}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showModal={showDeleteModal}
          setShowModal={setDeleteModal}
          title={title}
          deleteEqc={deleteEqc}
          mutationId={mutationId}
          setMutationId={setMutationId}
        />
      )}
      {showDuplicateModal && (
        <DuplicateModal
          showModal={showDuplicateModal}
          setShowModal={setDuplicateModal}
          eqcData={eqcData}
          setEqcData={setEqcData}
          refetchEqcDataWithInitialValues={refetchEqcDetails}
        />
      )}
      {showStatusModal && (
        <ActivationModal
          showModal={showStatusModal}
          setShowModal={setStatusModal}
          title={title}
          updateStatus={updateStatus}
          mutationId={mutationId}
          setMutationId={setMutationId}
          isActive={isActive}
          name="Checklist"
          subtitle="Checklist"
        />
      )}
      <Portal portalId="eqc-add-search-button">
        <SearchComponent
          id="search-container-id"
          getData={onSearchChange}
          className="search-component mr-10"
          defaultValue={navFilter?.search}
        />
        <Button
          className="d-flex"
          shape="round"
          type="primary"
          id="add-btn"
          icon={<AddButton />}
          onClick={handleAddEditEqc}
        >
          {isDesktopViewport && 'Add'}
        </Button>
      </Portal>
      {isDesktopViewport ? (
        <div className="pointer">
          <CommonTable
            loadingData={loading}
            columns={columns}
            data={eqcTypeListData || []}
            onChange={handleTableChange}
            paginationConfig={paginationProp}
            rowKey={(obj) => obj?.id}
            onRow={(record) => {
              return {
                onClick: () => handleRowClick(record)
              };
            }}
          />
        </div>
      ) : (
        <InfiniteScrollHandler
          scrollFlag={scrollFlag}
          loading={loading}
          refetchData={handleRefetch}
          setScrollFlag={setScrollFlag}
          hasMore={hasMore}
          wrapperClassName="eqc-type-scroll-wrapper"
          dataLength={eqcTypeListData?.length}
          skeletonRows={columns?.length - 2}
        >
          {map(eqcTypeListData, (eqcType, index) => {
            return (
              <CommonCard
                key={eqcType?.id}
                onClick={() => handleRowClick(eqcType)}
              >
                <div className="common-card d-flex">
                  <div className="mr-5 fw-medium">
                    <div className="d-flex align-center">{index + 1}.</div>
                  </div>
                  <div>
                    <div className="card-header fw-medium">
                      <span className="text-break">{eqcType?.name}</span>
                    </div>
                    <div className="card-content text-secondary">
                      <br />
                      <div className="mb-15">
                        <span className="fw-medium mr-5">UOM:</span>
                        <span className="text-break">{UOMS[eqcType?.uom]}</span>
                      </div>
                      <div className="mb-15 ">
                        <span className="fw-medium mr-5">
                          Reference Number:
                        </span>
                        <span className="text-break">{eqcType?.iso}</span>
                      </div>
                      <div className="mb-15 ">
                        <span className="fw-medium mr-5">Status:</span>
                        <ActiveStatus record={eqcType} />
                      </div>
                      <div className="mb-15 ">
                        <span className="fw-medium mr-5">Updated By:</span>
                        {eqcType?.creator?.name}
                      </div>
                      <div>
                        <span className="fw-medium mr-5">Updated At:</span>
                        {eqcType?.updatedAt
                          ? moment(`${eqcType?.updatedAt}`).format(
                              DEFAULTDATETIMEFORMAT
                            )
                          : '-'}
                      </div>
                    </div>
                  </div>
                  <span
                    className="d-flex position-absolute user-action-btn"
                    onClick={(e) => e?.stopPropagation?.()}
                  >
                    {getActionButtons(eqcType)}
                  </span>
                </div>
              </CommonCard>
            );
          })}
        </InfiniteScrollHandler>
      )}
    </>
  );
};

export default EqcTable;
