import { Tabs } from 'antd';
import { find, map, nth, sortBy } from 'lodash';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import EllipsisText from '../../../../../../../components/EllipsisText';
import EqcStageDetails from './EqcStageDetails';

const { TabPane } = Tabs;

const EqcStages = ({ data = {} }) => {
  const history = useHistory();
  const requestedStageId = history?.location?.state?.stageId;
  const { eqcStages, eqcStageHistories } = data;
  const stages = sortBy(eqcStages, ['order', 'id']);
  const currentStage = requestedStageId
    ? find(stages, ({ id }) => id === requestedStageId)
    : nth(eqcStageHistories, 0) || nth(stages, 0);
  const [activeKey, setActiveKey] = useState(currentStage?.name);
  return (
    <div>
      <Tabs
        activeKey={activeKey}
        onChange={(key) => {
          setActiveKey(key);
        }}
      >
        {map(stages, (stage) => (
          <TabPane tab={<EllipsisText text={stage?.name} />} key={stage?.name}>
            <EqcStageDetails stage={stage} eqcData={data} />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

export default EqcStages;
