import { Button, Modal } from 'antd';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AppContext } from '../AppContext';
import history from '../historyData';

export default function RouterPrompt(props) {
  const { dispatch } = useContext(AppContext);
  const {
    openPrompt = false,
    title = 'Leave this page',
    description = 'Changes made will not be saved, are you sure?',
    okText = 'Yes',
    cancelText = 'No',
    handleContinue,
    pageFilters = {}
  } = props;

  const [showPrompt, setShowPrompt] = useState(false);
  const [currentPath, setCurrentPath] = useState('');

  const currentLocation = useLocation();

  const unblockRef = useRef();

  useEffect(() => {
    if (openPrompt) {
      // eslint-disable-next-line no-undef
      window.onbeforeunload = function () {
        return true;
      };
    }
    unblockRef.current = history?.block((location) => {
      if (openPrompt && location?.pathname !== currentLocation?.pathname) {
        setCurrentPath(location?.pathname);
        setShowPrompt(true);
        return false;
      }
      return true;
    });
    return () => {
      unblockRef?.current();
      // eslint-disable-next-line no-undef
      window.onbeforeunload = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openPrompt]);

  const handleOK = async () => {
    if (handleContinue) {
      await handleContinue();
    }
    if (unblockRef) {
      unblockRef?.current();
    }
    setShowPrompt(false);
    dispatch({
      type: 'SET_SHOW_PROMPT',
      data: false
    });
    history?.push(currentPath, pageFilters);
  };

  const handleCancel = () => {
    setShowPrompt(false);
  };

  return showPrompt ? (
    <div id="router-prompt">
      <Modal
        open={showPrompt}
        footer={null}
        closable={false}
        maskClosable={false}
        centered
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('router-prompt')
        }
      >
        <h2>{title}</h2>
        <p>{description}</p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            {cancelText}
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            onClick={handleOK}
          >
            {okText}
          </Button>
        </div>
      </Modal>
    </div>
  ) : null;
}
