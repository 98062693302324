import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import { filter } from 'lodash';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import countryCodeData from '../../assets/countryCode.json';
import {
  GA_EVENT,
  GA_LABEL,
  MOBILE_NUMBER_NOTE,
  REGEX
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import PhoneNumberInputWithCountyCode from '../../components/PhoneNumberInputWithCountyCode';
import { UPDATE_LOGGED_IN_USER } from './graphql/Mutation';

const { required, characterWithoutWhiteSpace } = formValidatorRules;

const EditModal = (props) => {
  const { showModal, setShowModal, userData, refetchUserData } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const [updateUser] = useMutation(UPDATE_LOGGED_IN_USER, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.EDIT_USER_PROFILE, {
        label: GA_LABEL.EDIT_USER_PROFILE,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      form.resetFields();
      setShowModal(false);
      refetchUserData();
    }
  });

  const onFinish = async (formValues) => {
    const newFormValues = {
      name: formValues?.name,
      phoneNo: `${formValues?.countryCode}${formValues?.phoneNo}`,
      country: filter(
        countryCodeData,
        (record) => record?.dialCode === formValues?.countryCode
      )?.[0]?.countryCode?.toUpperCase(),
      countryCode: formValues?.countryCode
    };

    const variables = newFormValues;

    try {
      updateUser({
        variables: { data: { ...variables } }
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="dialog"
      footer={null}
      closable={false}
    >
      <h2>Edit Details</h2>
      <Form
        form={form}
        initialValues={{
          ...userData,
          phoneNo: userData?.phoneNo.replace(userData?.countryCode, '')
        }}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 250,
              message: 'Name cannot be more than 250 characters'
            }
          ]}
          name="name"
          label="Name"
        >
          <Input allowClear placeholder="Enter Name" />
        </Form.Item>
        <Form.Item name="email" label="Email">
          <Input allowClear disabled placeholder="Enter Email" />
        </Form.Item>
        <Form.Item
          label="Phone Number"
          name="phoneNo"
          dependencies={['countryCode']}
          rules={[
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!getFieldValue('countryCode')) {
                  return Promise.reject(
                    new Error('Please Select Country Code')
                  );
                }
                if (!value) {
                  return Promise.reject(new Error('Enter Mobile Number'));
                }
                if (value && !value.match(REGEX.PHONE)) {
                  return Promise.reject(
                    new Error('should be a valid contact number')
                  );
                }
                return Promise.resolve();
              }
            })
          ]}
        >
          <PhoneNumberInputWithCountyCode />
        </Form.Item>
        <div className="text-danger note-text note-margin ">
          Note : {MOBILE_NUMBER_NOTE}
        </div>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default EditModal;
