import { useMutation } from '@apollo/client';
import { Button, Form, Input, message, Spin } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Email, LoginLogo } from '../../assets/svg/login';
import { GA_EVENT, GA_LABEL, ROUTES } from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import { FORGOT_PASSWORD } from './graphql/Mutations';

const { required, email } = formValidatorRules;

const ForgotPassword = () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [forgotPassword, { loading }] = useMutation(FORGOT_PASSWORD, {
    onError(error) {
      if (error && error.message) {
        message.error(error.message);
      } else {
        message.error('Something went wrong');
      }
    },
    onCompleted() {
      Event(GA_EVENT.FORGOT_PASSWORD, {
        label: GA_LABEL.FORGOT_PASSWORD,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href
      });
      history.push(ROUTES.LOGIN);
    }
  });
  const onFinish = async (values) => {
    forgotPassword({
      variables: values
    });
  };
  return (
    <div className="gx-login-container">
      <div className="login-logo">
        <LoginLogo width="200" height="60" />
      </div>
      <div className="gx-login-content">
        <div className="gx-mb-4">
          <h1>Forgot Password</h1>
          <p>Enter your e-mail address</p>
        </div>
        <Spin spinning={false}>
          <Form
            layout="vertical"
            onFinish={onFinish}
            className="gx-login-form gx-form-row0"
            form={form}
          >
            <Form.Item
              name="email"
              label="Email Address"
              rules={[required, email]}
            >
              <Input addonBefore={<Email />} placeholder="admin@example.com" />
            </Form.Item>
            <div className="form-buttons d-flex">
              <Form.Item>
                <Button
                  className="cancel-button mr-10"
                  onClick={() => {
                    history.push(ROUTES.LOGIN);
                  }}
                >
                  Cancel
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  className="save-button"
                  shape="round"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                >
                  Submit
                </Button>
              </Form.Item>
            </div>
          </Form>
        </Spin>
      </div>
    </div>
  );
};

export default ForgotPassword;
