import { Button, Modal } from 'antd';
import React from 'react';

const DeleteModal = ({
  showModal = false,
  setShowModal,
  title,
  updateUserStatus,
  mutationId,
  setMutationId,
  name,
  subtitle,
  loading
}) => {
  const handleOk = () => {
    updateUserStatus({
      variables: {
        id: mutationId
      }
    });
  };

  const handleCancel = () => {
    setMutationId('');
    setShowModal(false);
  };
  return (
    <div>
      <Modal
        maskClosable={false}
        centered
        closable={false}
        footer={null}
        visible={showModal}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <h2>{`${title} ${subtitle}`}</h2>
        <p>
          {title === 'Deactivate'
            ? `Are you sure you want to ${title} ${name} and remove from all projects?`
            : `Are you sure you want to ${title} ${name}?`}
        </p>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            No, Cancel
          </Button>
          <Button
            shape="round"
            className="save-button"
            onClick={handleOk}
            loading={loading}
            type="primary"
          >
            Yes, {title}
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteModal;
