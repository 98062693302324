import { message } from 'antd';
import ct from 'countries-and-timezones';
import * as htmlToImage from 'html-to-image';
import { camelCase, filter, startCase } from 'lodash';
import moment from 'moment';
import Resizer from 'react-image-file-resizer';
import watermark from 'watermarkjs';
import countryCodeData from '../assets/countryCode.json';
import { DEFAULTDATEFORMAT, ELEMENT_IDS, REGEX } from './constants';

// Portal related methods
export const injectUsingPortal = (portalId) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isPortalIdExists = (portalId) => {
  return !!injectUsingPortal(portalId);
};

// Check for document Id's exists
export const getElementFromDocumentId = (portalId) => {
  // eslint-disable-next-line no-undef
  return document.getElementById(portalId);
};

export const isDocumentIdExist = (portalId) => {
  return !!getElementFromDocumentId(portalId);
};
// Check for document Id's exists end

export const formatDate = (
  datetime,
  format = `${DEFAULTDATEFORMAT} hh:mm A`
) => {
  if (datetime && moment && format) {
    return moment(datetime).format(format);
  }

  return datetime;
};

export const formValidatorRules = {
  required: {
    required: true,
    message: 'Required'
  },
  email: {
    type: 'email',
    message: 'The input is not valid E-mail!'
  },
  characterWithoutWhiteSpace: {
    pattern: REGEX.CHARACTER_WITHOUT_WHITESPACE_IN_STARTING_AND_ENDING,
    message: 'Enter proper value!'
  },
  number: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!Number(value) || !REGEX.NUMBER.test(Number(value))) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject('Should be a valid Number');
      }
      return Promise.resolve();
    }
  }),
  password: () => ({
    validator(rule, value) {
      if (!value) {
        return Promise.resolve();
      }
      if (!REGEX.PASSWORD.test(value)) {
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject();
      }
      return Promise.resolve();
    }
  })
};

export const combineDateTimeAndGetISOString = (date, time) => {
  const timeObj = new Date(time);
  const dateObj = new Date(date);

  let formattedDateTime = dateObj.setUTCHours(timeObj.getUTCHours());
  formattedDateTime = new Date(formattedDateTime).setUTCMinutes(
    timeObj.getUTCMinutes()
  );
  formattedDateTime = new Date(formattedDateTime).toISOString();

  return formattedDateTime;
};

export const formatPhoneNumber = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');

  // Check if the input is of correct length
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `(${match[1]}) ${match[2]}-${match[3]}`;
  }

  return null;
};

export const formatPhoneNumberWithoutMask = (str) => {
  // Filter only numbers from the input
  const cleaned = `${str}`.replace(/\D/g, '');
  if (cleaned) return cleaned;
  return null;
};

export const formatPrice = (price) => {
  const formatedPrice = price || 0;

  return Number(formatedPrice).toLocaleString('en', {
    style: 'currency',
    currency: 'USD'
  });
};

export const formItemProps = { normalize: (value) => value.trim() };

export async function fileUpload(signedUrl, image, onUploadProgress) {
  return new Promise((resolve, reject) => {
    try {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest();
      xhr.open('PUT', signedUrl);
      xhr.setRequestHeader('Content-Type', image.type);
      xhr.addEventListener('error', function (error) {
        if (error) {
          reject(error);
        }
      });
      xhr.addEventListener('load', function () {
        if (this.readyState === 4) {
          resolve(xhr.response);
        }
      });
      if (onUploadProgress) {
        xhr.upload.onprogress = (e) => {
          let percentComplete = 0;
          percentComplete = Math.ceil((e.loaded / e.total) * 100);
          onUploadProgress(percentComplete);
        };
      }
      xhr.send(image);
    } catch (error) {
      message.error(error.message);
    }
  });
}

export const titleCase = (str = '') => startCase(camelCase(str));

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line no-undef
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function timeTaken(startDate, EndDate) {
  // eslint-disable-next-line no-nested-ternary
  return moment(EndDate).diff(startDate, 'minutes', true) >= 60
    ? moment(EndDate).diff(startDate, 'minutes', true) % 60 === 0
      ? `${Math.abs(moment(EndDate).diff(startDate, 'hours', true)).toFixed(
          2
        )} h`
      : `${
          Math.abs(moment(EndDate).diff(startDate, 'hours', true))
            .toFixed(2)
            .split('.')[0]
        } h ${
          Math.abs(moment(EndDate).diff(startDate, 'hours', true))
            .toFixed(2)
            .split('.')[1]
        }mins`
    : `${Math.abs(moment(EndDate).diff(startDate, 'minutes', true)).toFixed(
        0
      )} mins`;
}

export const checkPasswordCharacters = (value) => {
  const data = [];
  if (value?.length >= 8 && value?.length <= 16) {
    data.push('8_letter');
  }
  if (/[A-Z]/.test(value)) {
    data.push('caps');
  }
  if (/[0-9]/.test(value)) {
    data.push('number');
  }
  if (/[a-z]/.test(value)) {
    data.push('small');
  }
  if (/[!@#$%^&*(),.?":{}|<>]/.test(value)) {
    data.push('special');
  }
  return data;
};

export const removeHistoryStateData = (
  history,
  location,
  field,
  subField = ''
) => {
  const data = { ...history?.location?.state };
  if (subField) {
    delete data[field]?.[subField];
  } else {
    delete data[field];
  }
  history.replace(location?.pathname, data);
};

export const compressImage = ({
  file,
  maxwidth = 1000,
  maxHeight = 1000,
  fileType,
  quality = 50,
  callBack,
  outputType = 'file'
}) => {
  // eslint-disable-next-line no-new
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      maxwidth,
      maxHeight,
      fileType,
      quality,
      0,
      (uri) => {
        resolve(callBack(uri));
      },
      outputType
    );
  });
};

export function dataURLtoFile(dataurl, filename) {
  const arr = dataurl?.split(',');
  const mime = arr?.[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr?.[arr?.length - 1]);
  let n = bstr?.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr?.charCodeAt(n);
  }
  // eslint-disable-next-line no-undef
  return new File([u8arr], filename, { type: mime });
}

export const addWatermark = async (file) => {
  const timeStamp = await htmlToImage
    // eslint-disable-next-line no-undef
    .toPng(document.querySelector(`#${ELEMENT_IDS?.TIME_STAMP}`))
    .then((dataUrl) => {
      return dataUrl;
    })
    .catch(function (error) {
      // eslint-disable-next-line no-console
      console.error('oops, something went wrong!', error);
    });
  const imageWithTimeStamp = await watermark([file, timeStamp])
    .image(watermark.image.lowerRight())
    .then((img) => {
      return img?.src;
    });
  return imageWithTimeStamp;
};

export function copyToClipboard(text, messageText) {
  // eslint-disable-next-line no-undef
  navigator.clipboard.writeText(text).then(() => {
    message.destroy();
    message.success(messageText);
  });
}

// export const downloadImageByHtmlNode = async (
//   selector,
//   nameOfImage,
//   chartRef
// ) => {
//   // eslint-disable-next-line no-undef
//   const rr = document.querySelector(selector);
//   const selectedElement = rr.cloneNode(true);
//   selectedElement.style.backgroundColor = 'white';
//   // eslint-disable-next-line no-undef
//   const iDiv = document.createElement('div');
//   iDiv.style.height = `${chartRef?.current?.scrollHeight}px`;
//   iDiv.style.width = `${chartRef?.current?.scrollWidth}px`;

//   // eslint-disable-next-line no-undef
//   document.body.appendChild(iDiv);

//   iDiv.appendChild(selectedElement);

//   // eslint-disable-next-line no-console
//   console.log(
//     'selectedElement',
//     chartRef?.current?.scrollHeight,
//     chartRef?.current?.scrollWidth,
//     iDiv
//   );
//   // eslint-disable-next-line no-undef
//   const timeStamp = await htmlToImage.toJpeg(iDiv).then((dataUrl) => {
//     return dataUrl;
//   });
//   // eslint-disable-next-line no-undef
//   document.body.removeChild(iDiv);
//   // eslint-disable-next-line no-undef
//   const a = document.createElement('a');
//   a.href = timeStamp;
//   a.download = `${nameOfImage}.jpeg`;
//   // eslint-disable-next-line no-undef
//   document.body.appendChild(a);
//   a.click();
//   // eslint-disable-next-line no-undef
//   document.body.removeChild(a);
// };

export const downloadImageByHtmlNode = async (
  selector,
  nameOfImage,
  chartRef
) => {
  // eslint-disable-next-line no-undef
  const containerDiv = document.createElement('div');
  try {
    // eslint-disable-next-line no-undef
    const selectedElement = document.querySelector(selector)?.cloneNode(true);
    selectedElement.style.backgroundColor = 'white';

    containerDiv.appendChild(selectedElement);

    if (chartRef && chartRef.current) {
      containerDiv.style.height = `${chartRef.current.scrollHeight}px`;
      containerDiv.style.minWidth = `${chartRef.current.scrollWidth}px`;
    }

    // eslint-disable-next-line no-undef
    document.body.appendChild(containerDiv);

    const timeStamp = await htmlToImage.toJpeg(containerDiv).then((dataUrl) => {
      return dataUrl;
    });

    // eslint-disable-next-line no-undef
    const link = document.createElement('a');
    link.href = timeStamp;
    link.download = `${nameOfImage}.jpeg`;

    link.click();
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error:', error);
  } finally {
    // eslint-disable-next-line no-undef
    document.body.removeChild(containerDiv);
  }
};

export function getPhoneCodeFromTimezone(timezone) {
  const tz = ct?.getTimezone(timezone);
  const phoneCode = filter(
    countryCodeData,
    (data) =>
      data?.countryCode?.toLocaleLowerCase() ===
      tz?.countries[0]?.toLocaleLowerCase()
  )?.[0]?.dialCode;
  return phoneCode;
}
