import { useLazyQuery } from '@apollo/client';
import clsx from 'clsx';
import * as eva from 'eva-icons';
import { includes, isNaN } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useMedia } from 'react-use';
import Error404 from '../../../../../../Error404';
import { ForwardArrow, ProjectIcon } from '../../../../../../assets/svg';
import {
  BREAKPOINTS,
  ROUTES,
  TAB_KEYS
} from '../../../../../../common/constants';
import EllipsisText from '../../../../../../components/EllipsisText';
import LoaderComponent from '../../../../../../components/LoaderComponent';
import { GET_PROJECT_RFI } from '../../../../graphql/Queries';
import RfiDetailContent from './RfiDetailContent';

const RfiDetails = () => {
  const { rfiId, projectId } = useParams();

  const { pathname } = useLocation();
  const history = useHistory();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const [RFIData, setRFIData] = useState([]);

  useEffect(() => {
    eva.replace();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [fetchDataLazyQuery, { loading }] = useLazyQuery(GET_PROJECT_RFI, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setRFIData(res?.getProjectRFI);
    },
    onError({ graphQLErrors }) {
      if (
        includes(
          ['Project RFI not found!', 'You do not have RFI feature access!'],
          graphQLErrors?.[0]?.message
        )
      ) {
        history.push(`${ROUTES.PROJECTS}/${projectId}/eqc`);
      }
    }
  });

  const fetchRFIData = () => {
    if (!isNaN(Number(projectId)) && !isNaN(Number(rfiId))) {
      fetchDataLazyQuery({
        variables: {
          projectRFIId: rfiId
        }
      });
    }
  };
  useEffect(() => {
    fetchRFIData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rfiId, pathname]);

  if (loading) {
    return <LoaderComponent setHeight={80} />;
  }
  if (isNaN(Number(projectId)) || isNaN(Number(rfiId))) {
    return <Error404 />;
  }
  return (
    <div className="eqc-type-details">
      <div
        className={`project-details-title details-header  justify-between page-header ${
          isDesktopViewport ? 'd-flex' : ''
        }`}
      >
        <div
          className={`project-details-title-text d-flex ${
            isDesktopViewport ? '' : 'mb-15'
          }`}
        >
          <ProjectIcon
            onClick={() => {
              history.push(ROUTES.PROJECTS, {});
            }}
            className="pointer"
          />
          <ForwardArrow />
          <h1
            className="pointer mr-10 text-primary"
            onClick={() => {
              history.push(`${ROUTES.PROJECTS}/${projectId}`);
            }}
          >
            {RFIData?.project?.name}
          </h1>
          <ForwardArrow />
          <div
            className={
              includes(pathname?.split('/'), 'changelog') &&
              'pointer text-primary'
            }
          >
            <EllipsisText
              className={clsx(
                includes(pathname?.split('/'), 'changelog')
                  ? 'font-size-22 fw-medium text-primary'
                  : 'h1-typography'
              )}
              onClick={() => {
                if (includes(pathname?.split('/'), 'changelog')) {
                  history.push(
                    `${ROUTES.PROJECTS}/${projectId}/${TAB_KEYS.RFI}/${RFIData?.id}`
                  );
                }
              }}
              text="RFI Setup"
            />
          </div>
          {includes(pathname?.split('/'), 'changelog') && (
            <>
              <ForwardArrow />
              <h1>Changelog</h1>
            </>
          )}
        </div>
      </div>
      <div className={clsx(isDesktopViewport ? 'mt-25' : 'p-14')}>
        <RfiDetailContent RFIData={RFIData} refetch={fetchDataLazyQuery} />
      </div>
    </div>
  );
};

export default RfiDetails;
