import { Select } from 'antd';
import React from 'react';

const CommonSelect = (props) => {
  const {
    children,
    getPopupContainer,
    loading,
    disabled,
    inputRef,
    ...rest
  } = props;
  return (
    <div className="common-dropdown-wrapper position-relative">
      <Select
        getPopupContainer={(trigger) =>
          typeof getPopupContainer === 'function'
            ? getPopupContainer(trigger)
            : trigger.parentElement
        }
        {...rest}
        loading={loading}
        disabled={disabled || loading}
        ref={inputRef}
      >
        {children}
      </Select>
    </div>
  );
};

export default CommonSelect;

CommonSelect.Option = Select.Option;
