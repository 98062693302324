import React from 'react';
import StageItemTable from './StageItemTable';

const StageItemList = ({
  stageId,
  eqcTypeData,
  isEditable,
  selectedStageData
}) => {
  return (
    <div className="stage-item-card project-checklist">
      <div className="header d-flex align-center justify-end mb-10">
        <div className="eqc-stage-header-buttons d-flex">
          <div id="stageitem-add-btn" />
        </div>
      </div>
      <StageItemTable
        stageId={stageId}
        eqcTypeData={eqcTypeData}
        isEditable={isEditable}
        selectedStageData={selectedStageData}
      />
    </div>
  );
};

export default StageItemList;
