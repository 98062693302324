import { Button, Col, Row } from 'antd';
import moment from 'moment';
import React from 'react';
import { useLocation, useMedia } from 'react-use';
import { SuccessIcon } from '../../assets/svg';
import {
  BREAKPOINTS,
  DEFAULTDATETIMEFORMAT,
  GA_EVENT,
  GA_LABEL
} from '../../common/constants';
import { Event } from '../../common/trackEvents';
import EllipsisText from '../../components/EllipsisText';

const ApproverSuccess = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const location = useLocation();

  return (
    <div
      className={
        isDesktopViewport
          ? 'success-container d-flex'
          : 'success-container d-grid'
      }
    >
      <Row className="success-container-content">
        <Col>
          <center>
            <h1 className="juistify-center">
              Thank you for reviewing this EQC.
            </h1>
          </center>
          <center>
            <SuccessIcon />
            <div>
              <h2 className="mb-40">
                <EllipsisText
                  text={`You have given ${
                    location?.state?.state?.status
                  } to user on ${moment(
                    location?.state?.state?.updatedAt
                  ).format(DEFAULTDATETIMEFORMAT)} for ${
                    location?.state?.state?.name
                  }.`}
                />
              </h2>
              <Button
                shape="round"
                type="primary"
                onClick={() => {
                  Event(GA_EVENT.REVISIT_APPROVER_COMMENT, {
                    label: GA_LABEL.REVISIT_APPROVER_COMMENT,
                    // eslint-disable-next-line no-undef
                    pathname: window?.location?.href
                  });
                  // eslint-disable-next-line no-undef
                  window.history.go(-1);
                }}
              >
                Revisit Your Comments
              </Button>
            </div>
          </center>
        </Col>
      </Row>
    </div>
  );
};

export default ApproverSuccess;
