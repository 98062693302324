import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal } from 'antd';
import React, { useContext } from 'react';
import { AppContext } from '../../AppContext';
import { GA_EVENT, GA_LABEL } from '../../common/constants';
import { Event } from '../../common/trackEvents';
import { formValidatorRules } from '../../common/utils';
import { DUPLICATE_EQC_TYPE } from './graphql/Mutation';

const { required, characterWithoutWhiteSpace } = formValidatorRules;

const DuplicateModal = (props) => {
  const {
    showModal,
    setShowModal,
    eqcData,
    setEqcData,
    refetchEqcDataWithInitialValues
  } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const [form] = Form.useForm();

  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };

  const [duplicateEqc] = useMutation(DUPLICATE_EQC_TYPE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.DUPLICATE_GLOBAL_CHECKLIST, {
        label: GA_LABEL.DUPLICATE_GLOBAL_CHECKLIST,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        checklist_id: eqcData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setEqcData();
      form.resetFields();
      setShowModal(false);
      refetchEqcDataWithInitialValues();
    }
  });

  const onFinish = async (formValues) => {
    const newFormValues = {
      ...formValues
    };

    const variables = { eqcTypeId: eqcData.id, ...newFormValues };

    try {
      await duplicateEqc({
        variables: { data: { ...variables } }
      });
    } catch (error) {
      return error;
    }
  };

  return (
    <Modal
      maskClosable={false}
      centered
      visible={showModal}
      className="dialog"
      footer={null}
      closable={false}
    >
      <h2>Duplicate Checklist</h2>
      <Form
        form={form}
        initialValues={null}
        layout="vertical"
        onFinish={onFinish}
      >
        <Form.Item
          rules={[
            required,
            characterWithoutWhiteSpace,
            {
              max: 250,
              message: 'Name cannot be more than 250 characters'
            }
          ]}
          name="name"
          label="Name"
        >
          <Input allowClear placeholder="Enter Name" />
        </Form.Item>
        <div className="form-buttons">
          <Button
            shape="round"
            className="cancel-button"
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            shape="round"
            type="primary"
            className="save-button"
            htmlType="submit"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
};

export default DuplicateModal;
