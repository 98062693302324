import { InboxOutlined } from '@ant-design/icons';
import { useLazyQuery, useMutation } from '@apollo/client';
import { Button, Col, Descriptions, Row, Tabs, Tag } from 'antd';
import TabPane from 'antd/lib/tabs/TabPane';
import clsx from 'clsx';
import { capitalize, dropRight, findIndex, includes } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useMedia } from 'react-use';
import { AppContext } from '../../../../../../AppContext';
import {
  ArchiveOutline,
  DeleteButton,
  EditIcon,
  SaveOutline
} from '../../../../../../assets/svg';
import {
  ALLOWED_ACTION_KEYS,
  ALLOWED_ACTION_TYPE,
  BREAKPOINTS,
  CHECKLIST_ITEM_TAB_KEYS,
  CHECKLIST_STATUS_CLASSNAME,
  CHECKLIST_STATUS_LABEL,
  CONFIRMATION_TYPES,
  GA_EVENT,
  GA_LABEL,
  PROJECT_CHECKLIST_STATUS,
  ROUTES,
  TAB_KEYS
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import CanPerform from '../../../../../../components/CanPerform';
import CommonCard from '../../../../../../components/CommonCard';
import EllipsisText from '../../../../../../components/EllipsisText';
import RouterPrompt from '../../../../../../components/RouterPrompt';
import {
  ARCHIVE_PROJECT_RFI,
  CREATE_PROJECT_RFI_DRAFT,
  DISCARD_PROJECT_RFI_DRAFT
} from '../../../../graphql/Mutation';
import { GET_SINGLE_PROJECT } from '../../../../graphql/Queries';
import ChangeLog from './ChangeLog';
import ConfirmationModal from './ConfirmationModal';
import RfiGoLiveModal from './RfiGoLiveModal';
import RfiPointsList from './RfiPointsList/RfiPointsList';
import RfiSettings from './RfiSettings';

const RfiDetailContent = ({ RFIData, refetch }) => {
  const { state, getCurrentUser, dispatch } = useContext(AppContext);
  const { pathname } = useLocation();
  const [isEditable, setIsEditable] = useState(false);
  const [isActionLoading, setIsActionLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPublishModal, setShowPublishModal] = useState(false);
  const [activeTab, setActiveTab] = useState(CHECKLIST_ITEM_TAB_KEYS.RFI);
  const [tabKey, setTabKey] = useState(Date.now());
  const [confirmationType, setConfirmationType] = useState(
    CONFIRMATION_TYPES?.EDIT
  );

  const [alreadyDraftedUserData, setAlreadyDraftedUserData] = useState();
  const currentUserId = getCurrentUser()?.id || {};
  const currentUser = getCurrentUser();
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);
  const history = useHistory();

  const handleGoBack = () => {
    setIsEditable(false);
    setShowModal(false);
    history.replace(dropRight(pathname?.split('/'))?.join('/'));
  };
  const [getProject] = useLazyQuery(GET_SINGLE_PROJECT, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      dispatch({
        type: 'SET_PROJECT_DETAILS',
        data: JSON.stringify(res?.getProject)
      });
      const index = findIndex(
        res?.getProject?.projectUsers,
        (user) => Number(user?.userId) === Number(currentUserId)
      );
      if (index !== -1) {
        const loggedInUserRole = res?.getProject?.projectUsers?.[index]?.roles;
        dispatch({ type: 'SET_PROJECT_ROLE', data: loggedInUserRole });
      } else {
        dispatch({ type: 'SET_PROJECT_ROLE', data: '' });
      }
    }
  });

  useEffect(() => {
    if (RFIData?.project?.id) {
      getProject({
        variables: {
          id: RFIData?.project?.id
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RFIData?.project?.id]);

  const [archiveProjectRFI] = useMutation(ARCHIVE_PROJECT_RFI, {
    onError() {
      setIsActionLoading(false);
    },
    onCompleted(response) {
      Event(GA_EVENT.PUBLISH_PROJECT_RFI_VERSION, {
        label: GA_LABEL.PUBLISH_PROJECT_RFI_VERSION,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: RFIData?.project?.id,
        project_rfi_id: RFIData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setIsActionLoading(false);
      setShowModal(false);
      history.push(
        `${ROUTES.PROJECTS}/${RFIData?.project?.id}/${TAB_KEYS.RFI}/${response?.archiveProjectRFI?.projectRFIId}`
      );
    }
  });
  const [createProjectRFIDraft] = useMutation(CREATE_PROJECT_RFI_DRAFT, {
    onError() {
      setIsActionLoading(false);
    },
    onCompleted(res) {
      Event(GA_EVENT.DRAFT_NEW_PROJECT_RFI_VERSION, {
        label: GA_LABEL.DRAFT_NEW_PROJECT_RFI_VERSION,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: RFIData?.project?.id,
        project_rfi_id: RFIData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setIsActionLoading(false);
      if (!res?.createProjectRFIDraft?.draftCreatorInfo) {
        history.push(
          `${ROUTES.PROJECTS}/${RFIData?.project?.id}/${TAB_KEYS.RFI}/${res?.createProjectRFIDraft?.projectRFIId}/draft`
        );
        setShowModal(false);
      } else {
        setShowModal(false);
        setAlreadyDraftedUserData(res?.createProjectRFIDraft);
        setConfirmationType(CONFIRMATION_TYPES?.MULTIPLE_USER);
        setShowModal(true);
      }
    }
  });

  const [discardProjectRFIDraft] = useMutation(DISCARD_PROJECT_RFI_DRAFT, {
    onError() {
      setIsActionLoading(false);
    },
    onCompleted() {
      Event(GA_EVENT.DISCARD_PROJECT_RFI_CHANGES, {
        label: GA_LABEL.DISCARD_PROJECT_RFI_CHANGES,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: RFIData?.project?.id,
        project_rfi_id: RFIData?.id,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      setIsEditable(false);
      setIsActionLoading(false);
      setShowModal(false);
      if (confirmationType === CONFIRMATION_TYPES.DISCARD) {
        history.goBack();
      }
    }
  });

  useEffect(() => {
    if (
      includes(
        [PROJECT_CHECKLIST_STATUS.PUBLISHED, PROJECT_CHECKLIST_STATUS.ARCHIVED],
        RFIData?.projectRfi?.status
      )
    ) {
      dispatch({
        type: 'SET_SHOW_PROMPT',
        data: isEditable
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditable]);

  useEffect(() => {
    if (
      includes([PROJECT_CHECKLIST_STATUS?.DRAFT], RFIData?.status) &&
      !includes(pathname?.split('/'), 'changelog')
    ) {
      setIsEditable(true);
    } else {
      setIsEditable(false);
    }
    setTabKey(Date.now());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RFIData]);

  const handleAction = async (type) => {
    switch (type || confirmationType) {
      case CONFIRMATION_TYPES.EDIT:
      case CONFIRMATION_TYPES?.MULTIPLE_USER:
      case CONFIRMATION_TYPES?.UNARCHIVE:
        createProjectRFIDraft({
          variables: {
            data: {
              projectId: RFIData?.project?.id,
              discardDraft: !!alreadyDraftedUserData?.draftCreatorInfo
            }
          }
        });
        break;
      case CONFIRMATION_TYPES.ARCHIVE:
        await archiveProjectRFI({
          variables: { id: RFIData?.id }
        });
        break;
      case CONFIRMATION_TYPES.DISCARD:
        discardProjectRFIDraft({
          variables: {
            id: RFIData?.id
          }
        });
        break;
      default:
        break;
    }
  };
  return (
    <div className="mb-20">
      <RouterPrompt
        openPrompt={state?.showPrompt}
        handleContinue={() => {
          if (
            includes(
              [
                PROJECT_CHECKLIST_STATUS.PUBLISHED,
                PROJECT_CHECKLIST_STATUS.ARCHIVED
              ],
              RFIData?.projectRfi?.status
            )
          ) {
            handleAction(CONFIRMATION_TYPES?.DISCARD);
          } else {
            handleGoBack();
          }
        }}
      />
      <CommonCard className="checklist-details mb-24">
        <Row justify="space-between" align="middle">
          <Col span={isDesktopViewport ? 18 : 24}>
            {(!isEditable ||
              includes(
                [
                  PROJECT_CHECKLIST_STATUS.PUBLISHED,
                  PROJECT_CHECKLIST_STATUS.ARCHIVED
                ],
                RFIData?.projectRfi?.status
              ) ||
              !CanPerform({
                action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS,
                type: ALLOWED_ACTION_TYPE.BOTH
              })) && (
              <EllipsisText
                className="h2-typography mb-10"
                text={RFIData?.name}
              />
            )}
            {!includes(pathname?.split('/'), 'changelog') &&
              includes(
                [
                  PROJECT_CHECKLIST_STATUS.PUBLISHED,
                  PROJECT_CHECKLIST_STATUS.ARCHIVED
                ],
                RFIData?.status
              ) &&
              RFIData?.activityLogCount > 0 && (
                <div
                  className="change-log-button"
                  onClick={() => {
                    setIsEditable(false);
                    if (!includes(pathname?.split('/'), 'changelog')) {
                      history.push(
                        `${ROUTES.PROJECTS}/${RFIData?.project?.id}/${TAB_KEYS.RFI}/${RFIData?.id}/changelog`
                      );
                    }
                  }}
                >
                  ChangeLog
                </div>
              )}
            <div
              className={clsx(
                'd-flex checklist-basic-details',
                isDesktopViewport && 'mt-24'
              )}
            >
              <Descriptions
                column={isDesktopViewport ? 6 : 3}
                layout="vertical"
                colon={false}
                className="d-flex"
              >
                <Descriptions.Item
                  label="Status"
                  className={clsx(!isDesktopViewport && 'common-item')}
                >
                  <Tag className={CHECKLIST_STATUS_CLASSNAME[RFIData?.status]}>
                    {capitalize(CHECKLIST_STATUS_LABEL[RFIData?.status])}
                  </Tag>
                </Descriptions.Item>
              </Descriptions>
            </div>
          </Col>
          {!includes(pathname?.split('/'), 'changelog') && (
            <Col
              className="flex-vertical d-flex justify-end"
              span={isDesktopViewport ? 6 : 24}
            >
              {RFIData?.status === PROJECT_CHECKLIST_STATUS?.ARCHIVED ? (
                <CanPerform
                  action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
                  type={ALLOWED_ACTION_TYPE.BOTH}
                >
                  <div className="d-flex justify-end">
                    <Button
                      shape="round"
                      type="primary"
                      icon={<InboxOutlined />}
                      className={clsx(
                        !isDesktopViewport && 'justify-center fill-width',
                        'unarchive-button-checklist d-flex'
                      )}
                      onClick={() => {
                        setConfirmationType(CONFIRMATION_TYPES?.UNARCHIVE);
                        setShowModal(true);
                      }}
                    >
                      Unarchive
                    </Button>
                  </div>
                </CanPerform>
              ) : (
                <div className="d-flex justify-end">
                  {isEditable ? (
                    <CanPerform
                      action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
                      type={ALLOWED_ACTION_TYPE.BOTH}
                    >
                      {RFIData?.projectRfi?.status && (
                        <Button
                          shape="round"
                          type=""
                          icon={<DeleteButton width={32} height={32} />}
                          className={clsx(
                            !isDesktopViewport &&
                              'width-percent-100 justify-center',
                            'delete-button-checklist delete-button d-flex'
                          )}
                          onClick={() => {
                            setConfirmationType(CONFIRMATION_TYPES?.DISCARD);
                            setShowModal(true);
                          }}
                        >
                          Discard
                        </Button>
                      )}
                      <Button
                        shape="round"
                        type="primary"
                        icon={<SaveOutline />}
                        className={clsx(
                          !isDesktopViewport &&
                            'width-percent-100 justify-center',
                          'edit-button-checklist d-flex'
                        )}
                        onClick={() => {
                          setShowPublishModal(true);
                        }}
                      >
                        {!RFIData?.projectRfi?.status ? 'Go Live' : 'Save'}
                      </Button>
                    </CanPerform>
                  ) : (
                    <CanPerform
                      action={ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS}
                      type={ALLOWED_ACTION_TYPE.BOTH}
                    >
                      <div className="form-buttons width-percent-100">
                        <Button
                          shape="round"
                          icon={<ArchiveOutline className="mr-5" />}
                          className={clsx(
                            'delete-button-checklist d-flex',
                            !isDesktopViewport &&
                              'width-percent-100 justify-center'
                          )}
                          onClick={() => {
                            setConfirmationType(CONFIRMATION_TYPES?.ARCHIVE);
                            setShowModal(true);
                          }}
                        >
                          Archive
                        </Button>
                        <Button
                          shape="round"
                          type="primary"
                          icon={<EditIcon />}
                          className={clsx(
                            'edit-button-checklist d-flex save-button',
                            !isDesktopViewport &&
                              'width-percent-100 justify-center'
                          )}
                          onClick={() => {
                            setConfirmationType(CONFIRMATION_TYPES?.EDIT);
                            setShowModal(true);
                          }}
                        >
                          Edit
                        </Button>
                      </div>
                    </CanPerform>
                  )}
                </div>
              )}
            </Col>
          )}
        </Row>
      </CommonCard>
      {includes(pathname?.split('/'), 'changelog') ? (
        <ChangeLog RFIData={RFIData} />
      ) : (
        <Row>
          <Col span={24} className="project-details">
            <Tabs
              onTabClick={setActiveTab}
              activeKey={activeTab}
              destroyInactiveTabPane
              className="checklist-item-tabs"
              key={tabKey}
            >
              <TabPane tab="RFI Points" key={CHECKLIST_ITEM_TAB_KEYS.RFI}>
                <div className="details">
                  <RfiPointsList RFIData={RFIData} isEditable={isEditable} />
                </div>
              </TabPane>
              {CanPerform({
                action: ALLOWED_ACTION_KEYS.EDIT_PROJECT_CHECKLISTS,
                type: ALLOWED_ACTION_TYPE.BOTH
              }) && (
                <>
                  <TabPane
                    tab="Settings"
                    key={CHECKLIST_ITEM_TAB_KEYS.SETTING}
                    className="checklist-setting-tab"
                  >
                    <RfiSettings
                      RFIData={RFIData}
                      refetch={refetch}
                      isEditable={isEditable}
                      isDisabled={!RFIData?.project?.isActive}
                    />
                  </TabPane>
                </>
              )}
            </Tabs>
          </Col>
        </Row>
      )}
      {showModal && (
        <ConfirmationModal
          type={confirmationType}
          showModal={showModal}
          setShowModal={setShowModal}
          handleConfirmation={handleAction}
          alreadyDraftedUserData={alreadyDraftedUserData}
          setConfirmationType={setConfirmationType}
          setIsActionLoading={setIsActionLoading}
          isActionLoading={isActionLoading}
          setAlreadyDraftedUserData={setAlreadyDraftedUserData}
        />
      )}
      {showPublishModal && (
        <RfiGoLiveModal
          showModal={showPublishModal}
          setShowModal={setShowPublishModal}
          RFIData={RFIData}
          handleGoBack={handleGoBack}
          setIsEditable={setIsEditable}
        />
      )}
    </div>
  );
};

export default RfiDetailContent;
