import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ROUTES } from '../../common/constants';
import Todo from './Todo';

const TodoWrapper = () => {
  return (
    <Switch>
      <Route path={ROUTES.TODO} component={Todo} />
    </Switch>
  );
};

export default TodoWrapper;
