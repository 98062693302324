import { Menu, Popover, Space } from 'antd';
import { includes } from 'lodash';
import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import alternateProfile from '../../../assets/images/alternateProfile.png';
import {
  ALLOWED_ACTION_KEYS,
  MODULES,
  ROUTES
} from '../../../common/constants';
import CanPerform from '../../../components/CanPerform';
import NotificationModal from '../../../modules/notification/NotificationModal';

const UserProfile = ({ userData }) => {
  const [visible, setVisible] = useState(false);
  const { pathname } = useLocation();
  const [showModal, setShowModal] = useState(false);

  const handleVisibleChange = (value) => {
    setVisible(value);
  };
  const userMenuOptions = (
    <Menu onClick={() => setVisible(false)}>
      <Menu.Item key={ROUTES.PROFILE}>
        <NavLink to={ROUTES.PROFILE}>Profile</NavLink>
      </Menu.Item>
      {CanPerform({
        action: ALLOWED_ACTION_KEYS.NOTIFICATION_SETTING
      }) && (
        <Menu.Item key={MODULES.NOTIFICATION}>
          <div onClick={() => setShowModal(true)}>Notification Setting</div>
        </Menu.Item>
      )}
      <Menu.Item key={ROUTES.CHANGE}>
        <NavLink to={ROUTES.CHANGE}>Change Password</NavLink>
      </Menu.Item>
      <Menu.Item key={ROUTES.LOGOUT}>
        <NavLink to={ROUTES.LOGOUT}>Logout</NavLink>
      </Menu.Item>
    </Menu>
  );

  const paths = [ROUTES.CHANGE, ROUTES.PROFILE];

  return (
    <div className="gx-avatar-row pointer header-right">
      <div className="header-right-menu" />
      <Popover
        className="d-flex flex-row user-profile"
        placement="bottomRight"
        content={userMenuOptions}
        trigger="click"
        visible={visible}
        onVisibleChange={handleVisibleChange}
        getTooltipContainer={(triggerNode) => triggerNode.parentNode}
        destroyTooltipOnHide={!includes(paths, pathname)}
      >
        <Space direction="horizontal">
          <img
            src={userData?.getLoggedInUser?.profileImage || alternateProfile}
            className="profileImage gx-pointer mr-5"
            alt="Avatar"
          />
        </Space>
      </Popover>
      {showModal && (
        <NotificationModal showModal={showModal} setShowModal={setShowModal} />
      )}
    </div>
  );
};

export default UserProfile;
