import { Tabs } from 'antd';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ACCESS_TYPE,
  ALLOWED_ACTION_KEYS,
  ROUTES,
  TAB_KEYS
} from '../../../../../../common/constants';
import CanPerform from '../../../../../../components/CanPerform';
import HasAccess from '../../../../../../components/HasAccess';
import Agencies from '../../../../../agencies/Agencies';
import EqcType from '../../../../../eqctype/EqcType';
import Users from '../../../../../users/Users';

const { TabPane } = Tabs;

function Setup() {
  const history = useHistory();
  const { tab } = useParams();
  const hasInspectionAccess = !!HasAccess({ type: ACCESS_TYPE.INSPECTION });

  return (
    <div className="project-details setup-module">
      <div className="mobile-card-wrapper">
        <div className="details-content">
          <Tabs
            onTabClick={(key) => history.push(`${ROUTES.SETUP}/${key}`)}
            activeKey={tab}
            destroyInactiveTabPane
          >
            {CanPerform({ action: ALLOWED_ACTION_KEYS.VIEW_USERS_PAGE }) && (
              <TabPane tab="Users" key={TAB_KEYS.USERS}>
                <div className="details">
                  <Users />
                </div>
              </TabPane>
            )}
            {hasInspectionAccess &&
              CanPerform({
                action: ALLOWED_ACTION_KEYS.VIEW_CHECKLISTS_PAGE
              }) && (
                <TabPane tab="Checklist" key={TAB_KEYS.CHECKLISTS}>
                  <div className="details">
                    <EqcType />
                  </div>
                </TabPane>
              )}
            {CanPerform({
              action: ALLOWED_ACTION_KEYS.VIEW_AGENCY_PAGE
            }) && (
              <TabPane tab="Agencies" key={TAB_KEYS.AGENCIES}>
                <div className="details">
                  <Agencies />
                </div>
              </TabPane>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Setup;
