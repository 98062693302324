import React, { useState } from 'react';
import CommonTable from '../../../components/CommonTable';

const AgencySummaryTable = ({ data, loading }) => {
  const initialEqcFilter = {
    skip: 0,
    limit: 10,
    sortBy: { field: 'createdAt', order: 'DESC' }
  };

  const initialPaginationValue = {
    total: 0,
    current: 1
  };

  const [paginationProp, setPaginationProp] = useState(initialPaginationValue);
  const [sortedInfo, setSortedInfo] = useState({});
  const [eqcFilter, setEqcFilter] = useState(initialEqcFilter);

  const handleTableChange = (pagination, filter, sorter) => {
    const { current } = pagination;
    const skip = (current - 1) * pagination.pageSize;
    setSortedInfo(sorter);
    setEqcFilter({ ...eqcFilter, skip: skip, limit: pagination.pageSize });
    setPaginationProp({ ...paginationProp, ...pagination });
  };

  const columns = [
    {
      title: 'Agency Name',
      dataIndex: 'agencyName',
      key: 'agencyName',
      sorter: (a, b) => a?.agencyName?.localeCompare(b?.agencyName),
      sortOrder: sortedInfo?.columnKey === 'agencyName' && sortedInfo?.order
    },
    {
      title: 'Total Inspection',
      dataIndex: 'totalCount',
      key: 'totalCount',
      sorter: (a, b) => a?.totalCount - b?.totalCount,
      sortOrder: sortedInfo?.columnKey === 'totalCount' && sortedInfo?.order
    },
    {
      title: 'Failed Inspection',
      dataIndex: 'failCount',
      key: 'failCount',
      sorter: (a, b) => a?.failCount - b?.failCount,
      sortOrder: sortedInfo?.columnKey === 'failCount' && sortedInfo?.order,
      render: (failCount, { totalCount }) =>
        `${failCount} (${parseInt((failCount / totalCount) * 100, 10) || 0}%)`
    }
  ];

  return (
    <>
      <CommonTable
        columns={columns}
        data={data || []}
        onChange={handleTableChange}
        paginationConfig={paginationProp}
        rowKey={(obj) => obj?.id}
        loading={loading}
      />
    </>
  );
};

export default AgencySummaryTable;
