import { Card } from 'antd';
import React from 'react';
import { useMedia } from 'react-use';
import { BREAKPOINTS } from '../../common/constants';
import EqcTable from './components/EqcTable';

const EqcType = () => {
  const isDesktopViewport = useMedia(`(min-width: ${BREAKPOINTS.desktop}px)`);

  return (
    <>
      {!isDesktopViewport && (
        <>
          <div className="page-header text-primary">Checklists</div>
        </>
      )}
      <div className="mobile-card-wrapper">
        <Card className="setup-module">
          <div className="eqc-header d-flex align-center justify-between">
            {isDesktopViewport && (
              <div className="eqc-header-title d-flex">
                <h1>Checklists</h1>
              </div>
            )}
            <div
              id="eqc-add-search-button"
              className={`eqc-header-buttons d-flex align-center ${
                !isDesktopViewport ? 'width-percent-100' : ''
              }`}
            />
          </div>
          <EqcTable />
        </Card>
      </div>
    </>
  );
};

export default EqcType;
