import { Button, Modal } from 'antd';
import React from 'react';

const ImportConfirmationModal = ({
  showModal,
  setShowModal,
  handleClickEvent
}) => {
  const handleCancel = () => {
    setShowModal(false);
  };

  const handleClick = () => {
    setShowModal(false);
    handleClickEvent();
  };
  return (
    <div id="delete-levels">
      <Modal
        maskClosable={false}
        centered
        visible={showModal}
        footer={null}
        closable={false}
        width={420}
        getContainer={() =>
          // eslint-disable-next-line no-undef
          document.getElementById('delete-levels')
        }
      >
        <div>
          <h2>Import file</h2>

          <p>
            All the previous nomenclature data will be deleted and replace with
            new data. This action is irreversible.
          </p>
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              onClick={handleClick}
            >
              Confirm
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ImportConfirmationModal;
