import { useMutation } from '@apollo/client';
import { Button, Form, Input, Modal, Radio } from 'antd';
import { keys, map } from 'lodash';
import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { AppContext } from '../../../../../../AppContext';
import {
  GA_EVENT,
  GA_LABEL,
  PICK_EQC_LABEL,
  UOMS
} from '../../../../../../common/constants';
import { Event } from '../../../../../../common/trackEvents';
import { formValidatorRules } from '../../../../../../common/utils';
import CommonDropdown from '../../../../../../components/CommonDropdown';
import CommonSelect from '../../../../../../components/CommonSelect';
import { TEMPLATE_LIST } from '../../../../../eqctype/graphql/Queries';
import { ADD_PROJECT_EQC_TYPE } from '../../../../graphql/Mutation';
import { GET_PROJECT_EQC_TYPE_DROPDOWN_LIST } from '../../../../graphql/Queries';

const { Option } = CommonSelect;
const AddEqcTypeModal = (props) => {
  const { showModal, setShowModal, refetchEqcTypeData } = props;
  const { getCurrentUser } = useContext(AppContext);
  const currentUser = getCurrentUser();
  const { projectId } = useParams();
  const [form] = Form.useForm();
  const { required, characterWithoutWhiteSpace } = formValidatorRules;
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const [pickFrom, setPickFrom] = useState(PICK_EQC_LABEL.GLOBAL);
  const handleCancel = () => {
    setShowModal(false);
    form.resetFields();
  };
  const [addProjectEqcType, { loading }] = useMutation(ADD_PROJECT_EQC_TYPE, {
    onError() {},
    onCompleted() {
      Event(GA_EVENT.ADD_PROJECT_CHECKLIST, {
        label: GA_LABEL.ADD_PROJECT_CHECKLIST,
        // eslint-disable-next-line no-undef
        pathname: window?.location?.href,
        project_id: projectId,
        user_id: currentUser?.id,
        user_name: currentUser?.name,
        tenant_id: currentUser?.tenantUser?.tenant?.id,
        tenant_name: currentUser?.tenantUser?.tenant?.organizationName
      });
      form.resetFields();
      setShowModal(false);
      refetchEqcTypeData();
    }
  });
  const onFormSubmit = async (formValues) => {
    let variables = {
      selectedAll: isSelectedAll,
      projectId: Number(projectId),
      isMasterTemplateImport: pickFrom === PICK_EQC_LABEL.TEMPLATE
    };
    if (formValues?.eqcTypeTemplateId) {
      variables.eqcTypeId = formValues?.eqcTypeTemplateId;
    } else {
      variables = {
        ...formValues,
        ...variables
      };
    }
    if (isSelectedAll) {
      variables.eqcTypeId = [];
    }

    try {
      await addProjectEqcType({
        variables: {
          data: variables
        }
      });
    } catch (error) {
      return error;
    }
  };
  const handleDeselect = () => {
    setIsSelectedAll(false);
    form.setFieldsValue({ eqcTypeId: [], eqcTypeTemplateId: [] });
  };
  const renderEqcTypeUi = (type) => {
    switch (type) {
      case PICK_EQC_LABEL.CREATE:
        return (
          <>
            <Form.Item
              rules={[
                required,
                characterWithoutWhiteSpace,
                {
                  max: 250,

                  message: 'Name cannot be more than 250 characters'
                }
              ]}
              name="name"
              label="Name"
            >
              <Input placeholder="Enter Name" allowClear />
            </Form.Item>
            <Form.Item
              rules={[{ required, message: 'Required' }]}
              name="uom"
              label="UOM"
            >
              <CommonSelect
                className="mr-3"
                placeholder="Select UOM"
                allowClear
                showSearch
                optionFilterProp="children"
              >
                {map(keys(UOMS), (uom) => {
                  return (
                    <Option key={uom} value={uom} label={UOMS[uom]}>
                      {UOMS[uom]}
                    </Option>
                  );
                })}
              </CommonSelect>
            </Form.Item>
            <Form.Item
              name="iso"
              label="Reference Number"
              rules={[
                characterWithoutWhiteSpace,
                {
                  max: 250,
                  message: 'Content cannot be more than 250 characters'
                }
              ]}
            >
              <Input allowClear placeholder="Reference Number" />
            </Form.Item>
          </>
        );
      case PICK_EQC_LABEL.GLOBAL:
        return (
          <Form.Item
            name="eqcTypeId"
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>Checklist</div>
                {isSelectedAll && (
                  <div
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={handleDeselect}
                  >
                    Deselect All
                  </div>
                )}
              </div>
            }
            rules={[required]}
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectedAll(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonDropdown
              mode="multiple"
              placeholder="Select Checklist"
              showSearch
              optionFilterProp="children"
              disabled={isSelectedAll}
              isSelectedAll={isSelectedAll}
              allowClear
              query={GET_PROJECT_EQC_TYPE_DROPDOWN_LIST}
              responsePath="projectEqcTypeDropdownList.data"
              valuePath="id"
              labelPath="name"
              optionKey="eqc-type"
              fetchPolicy="network-only"
              variables={{ filter: { projectId } }}
              hasSelectAll
              useEffectDeps={[type]}
            />
          </Form.Item>
        );
      case PICK_EQC_LABEL.TEMPLATE:
        return (
          <Form.Item
            name="eqcTypeTemplateId"
            label={
              <div className="d-flex justify-between width-percent-100">
                <div>Select Template</div>
                {isSelectedAll && (
                  <div
                    className="text-primary pointer"
                    role="button"
                    tabIndex="0"
                    onClick={handleDeselect}
                  >
                    Deselect All
                  </div>
                )}
              </div>
            }
            rules={[required]}
            normalize={(value) => {
              if (value.includes('all')) {
                setIsSelectedAll(true);
                return ['all'];
              }
              return value;
            }}
          >
            <CommonDropdown
              mode="multiple"
              placeholder="Select Template"
              showSearch
              optionFilterProp="children"
              disabled={isSelectedAll}
              isSelectedAll={isSelectedAll}
              allowClear
              query={TEMPLATE_LIST}
              responsePath="masterEqcTypeList.data"
              valuePath="id"
              labelPath="name"
              optionKey="eqc-type"
              fetchPolicy="network-only"
              hasSelectAll
              useEffectDeps={[type]}
            />
          </Form.Item>
        );
      default:
        break;
    }
  };

  return (
    <>
      <Modal
        maskClosable={false}
        centered
        form={form}
        visible={showModal}
        onCancel={handleCancel}
        closable={false}
        footer={null}
      >
        <h2>Add Checklist</h2>
        <div className="mb-5">Pick From :</div>
        <div className="mb-20">
          <Radio.Group
            defaultValue={PICK_EQC_LABEL.GLOBAL}
            value={pickFrom}
            onChange={(e) => {
              handleDeselect();
              setPickFrom(e?.target?.value);
            }}
            size="large"
          >
            <Radio value={PICK_EQC_LABEL.GLOBAL}>Global</Radio>
            <Radio value={PICK_EQC_LABEL.TEMPLATE}>digiQC Template</Radio>
            <Radio value={PICK_EQC_LABEL.CREATE}>Create New</Radio>
          </Radio.Group>
        </div>
        <Form layout="vertical" form={form} onFinish={onFormSubmit}>
          {renderEqcTypeUi(pickFrom)}
          <div className="form-buttons">
            <Button
              shape="round"
              className="cancel-button"
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              shape="round"
              type="primary"
              className="save-button"
              htmlType="submit"
              loading={loading}
            >
              Add
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default AddEqcTypeModal;
