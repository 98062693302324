import { Affix } from 'antd';
import moment from 'moment';
import React, { useContext } from 'react';
import { AppContext } from '../../../AppContext';
import { DEFAULTDATEFORMAT } from '../../../common/constants';

const OneMonthExpiryMessage = () => {
  const { getCurrentUser } = useContext(AppContext);
  const tenantUser = getCurrentUser()?.tenantUser;

  return (
    <Affix offsetTop="0">
      <dt className="grace-bar">
        <div className="grace-bar-text">
          Your subscription will expire on{' '}
          {moment(tenantUser?.tenant?.subscription?.validTill).format(
            DEFAULTDATEFORMAT
          )}
          .
        </div>
      </dt>
    </Affix>
  );
};

export default OneMonthExpiryMessage;
