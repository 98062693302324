import { useLazyQuery } from '@apollo/client';
import { Divider } from 'antd';
import { map } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { AppContext } from '../../../AppContext';
import { GlobeIcon } from '../../../assets/svg';
import { ROUTES } from '../../../common/constants';
import CommonSelect from '../../../components/CommonSelect';
import { GET_PROFILE } from '../../../modules/users/graphql/Queries';

const { Option } = CommonSelect;

const TenantSelect = () => {
  const {
    getTenantData,
    getCurrentTenant,
    changeTenant,
    initializeAuth
  } = useContext(AppContext);

  const [getUserProfile] = useLazyQuery(GET_PROFILE, {
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      initializeAuth('', '', res?.getLoggedInUser);
    },
    onError: () => {}
  });
  const history = useHistory();
  const currentTenant = getCurrentTenant();
  const tenantData = getTenantData();

  useMemo(() => {
    if (!currentTenant && tenantData?.length > 0) {
      changeTenant(tenantData?.[0]?.tenant?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenantData]);

  const handleChange = (value) => {
    changeTenant(value);
    getUserProfile();
    history.push(ROUTES?.MAIN);
  };
  return (
    <div className="gx-avatar-row width-percent-100 d-flex justify-end align-center tenant-select-row mt-25 mb-25">
      <div className="filter-wrapper">
        <GlobeIcon />
        <Divider type="vertical" />
        <CommonSelect
          className="width-200"
          onChange={handleChange}
          defaultValue={currentTenant || tenantData?.[0]?.tenant?.id}
        >
          {map(tenantData, (tenant) => (
            <Option value={tenant?.tenant?.id} key={tenant?.tenant?.id}>
              {tenant?.tenant?.organizationName}
            </Option>
          ))}
        </CommonSelect>
      </div>
    </div>
  );
};

export default TenantSelect;
