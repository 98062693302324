import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Error404 from '../../Error404';
import { ACCESS_TYPE, LOGS_TABS_KEYS, ROUTES } from '../../common/constants';
import HasAccess from '../../components/HasAccess';
import Logs from './Logs';

const LogsWrapper = () => {
  const RedirectToTab = () => {
    const redirectPath = HasAccess({ type: ACCESS_TYPE.INSPECTION })
      ? LOGS_TABS_KEYS.ACTIVITY
      : LOGS_TABS_KEYS.INSTRUCTION;
    return <Redirect to={`${ROUTES.LOGS}/${redirectPath}`} />;
  };
  return (
    <Switch>
      <Route exact path={`${ROUTES.LOGS}/:tab`} component={Logs} />
      <Route path={ROUTES.LOGS} component={RedirectToTab} />
      <Route path="*" exact component={Error404} />
    </Switch>
  );
};

export default LogsWrapper;
